import {getSelectedUnitSystem, UnitsHelperNew} from "../../../utils/unitsHelperNew";
import {IUnitsApi} from "@qandq/cloud-gcs-core";
import { UnitSystemEnum, UnitTypeEnum } from "@qandq/cloud-gcs-core";

export class UnitsApi implements IUnitsApi {

    getCurrentUnitSystem(): UnitSystemEnum {
        return getSelectedUnitSystem();
    }

    convertNumber(system: UnitSystemEnum, type: UnitTypeEnum, value: number): number {
        return UnitsHelperNew.convertNumber(system, type, value);
    }

    convertToSI(system: UnitSystemEnum, unit: UnitTypeEnum, value: number): number {
        return UnitsHelperNew.convertToSI(system, unit, value);
    }

    convertToString(system: UnitSystemEnum, type: UnitTypeEnum, value: number): string {
        return UnitsHelperNew.convertToString(system, type, value);
    }

    getUnitText(system: UnitSystemEnum, type: UnitTypeEnum): string {
        return UnitsHelperNew.getUnitText(system, type);
    }

    getUnitSystem(): UnitSystemEnum {
        return getSelectedUnitSystem();
    }

}
