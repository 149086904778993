import {IService} from "./interfaces/IService";
import {IAircraftLinkHealthService} from "./interfaces/IAircraftLinkHealthService";
import {publishEvent, removeEvent, subscribeEvent} from "../../notification-locators/PubSubService";
import {FlightEngineEvents} from "../flight-engine/FlightEngineEvents";
import {
    AircraftIdentifier,
    AircraftTelemetry,
    LinkHealthStatus
} from "@qandq/cloud-gcs-core";
import _ from "lodash";
import {ServiceEvent} from "./events/ServiceEvent";
import {ConnectionStatusData} from "./models/ConnectionStatusData";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import { AircraftHealthEnum, IndicatorStatusEnum } from "@qandq/cloud-gcs-core";

export class AircraftLinkHealthService implements IService, IAircraftLinkHealthService {

    private readonly flightEngine: IFlightEngineApi;
    private aircraftStatusList: Map<number, LinkHealthStatus> = new Map<number, LinkHealthStatus>()
    private connectionStatusList: Map<number, ConnectionStatusData> = new Map<number, ConnectionStatusData>();


    constructor(flightEngine: IFlightEngineApi) {
        this.flightEngine = flightEngine;
    }

    start(): void {
        subscribeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
        subscribeEvent(FlightEngineEvents.AircraftRemoved, this.onAircraftRemoved)
        subscribeEvent(FlightEngineEvents.TelemetryDataChanged, (data) => {
            const telemetry: AircraftTelemetry = data[0];
            const identifier: AircraftIdentifier = data[1];

            let connStatus = this.connectionStatusList.get(identifier.aircraftId)


            // if user controls autopilot, get latest package number from pilot station
            // if user observing autopilot, get latest package number from telemetry.
            // Bcs: this user can not publish user status to mission controller.
            //
            let lastUserStatusPackageNumber = 0;
            if(this.flightEngine.isControllingAircraft(identifier.aircraftId))
                lastUserStatusPackageNumber = this.flightEngine.getUserStatusPackage().packageNumber
            else
                lastUserStatusPackageNumber = telemetry.telemetryHeader.pilotUserStatus.lastPackageNumber

            if(!connStatus || connStatus.firstTelemetryPN > connStatus.lastTelemetryPN) {
                connStatus = {
                    downLinkDelay: 0,
                    upLinkDelay: 0,
                    firstTelemetryPN: telemetry.telemetryHeader.packageNumber,
                    lastTelemetryPN: telemetry.telemetryHeader.packageNumber,
                    receivedTelemetryMessage: 0,
                    downLinkPercent: 0,
                    upLinkPercent: 0,
                    sentUserStatusPN: 0,
                    firstUserStatusPN: 0,
                    lastUserStatusPN: lastUserStatusPackageNumber,
                    calculationReceivedUserStatusPN: lastUserStatusPackageNumber
                }
            }

            const now = new Date();
            connStatus.downLinkDelay = now.valueOf() - (telemetry as any).timestamp
            connStatus.upLinkDelay = telemetry.telemetryHeader.pilotUserStatus.delay;

            connStatus.lastTelemetryPN = telemetry.telemetryHeader.packageNumber;
            connStatus.receivedTelemetryMessage++
            connStatus.lastUserStatusPN = lastUserStatusPackageNumber;
            connStatus.sentUserStatusPN = telemetry.telemetryHeader.pilotUserStatus.receivedUserStatusMessage;


            // Update connection status after every 25 telemetry package
            if(connStatus.lastTelemetryPN - connStatus.firstTelemetryPN >= 25) {
                // downLink calculation
                connStatus.downLinkPercent = (
                    (
                        (connStatus.receivedTelemetryMessage+connStatus.firstTelemetryPN-1)
                        /connStatus.lastTelemetryPN
                    )
                    * 100
                );
                // reset downLink package counters
                connStatus.receivedTelemetryMessage = 0;
                connStatus.firstTelemetryPN = connStatus.lastTelemetryPN;

                // upLink calculation
                // User status message is published every second and calculation precision very low.
                // Therefore, min 5 message must be published to mission controller.
                // But packageDiff greater than 5,
                const packageDiff = connStatus.lastUserStatusPN - connStatus.firstUserStatusPN;
                if(packageDiff >= 5) {
                    connStatus.upLinkPercent = (
                        (
                            (connStatus.sentUserStatusPN-connStatus.calculationReceivedUserStatusPN)
                            /packageDiff
                        )
                        * 100
                    );

                    if(connStatus.upLinkPercent > 100)
                        connStatus.upLinkPercent = 100
                    else if(connStatus.upLinkPercent < 0)
                        connStatus.upLinkPercent = 0

                    // reset upLink package counters
                    connStatus.firstUserStatusPN = connStatus.lastUserStatusPN;
                    connStatus.calculationReceivedUserStatusPN = connStatus.sentUserStatusPN;
                }

                const linkHealth = this.aircraftStatusList.get(identifier.aircraftId)
                if(linkHealth) {
                    linkHealth.downLinkPercent = connStatus.downLinkPercent
                    linkHealth.upLinkPercent = connStatus.upLinkPercent
                    linkHealth.downLinkDelay = connStatus.downLinkDelay
                    linkHealth.upLinkDelay = connStatus?.upLinkDelay

                    this.setLinkHealth(identifier, this.aircraftStatusList.get(identifier.aircraftId)!)
                }
            }

            // update connection status
            this.connectionStatusList.set(identifier.aircraftId, connStatus)
        })
    }

    stop(): void {
        removeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
        removeEvent(FlightEngineEvents.AircraftRemoved, this.onAircraftRemoved)
    }

    public getAircraftLinkHealthStatus(aircraftIdentifier: AircraftIdentifier): LinkHealthStatus | undefined {
        return this.aircraftStatusList.get(aircraftIdentifier.aircraftId)
    }

    private onAircraftRemoved = (data: any[]) => {
        const aircraftIdentifier: AircraftIdentifier = data[0]
        this.aircraftStatusList.delete(aircraftIdentifier.aircraftId)
    }

    private onAircraftHealthChanged = (data: any[]) => {
        const aircraftIdentifier: AircraftIdentifier = data[0]
        const oldHealth: AircraftHealthEnum = data[1]
        const newHealth: AircraftHealthEnum = data[2]
        const hostStatus: IndicatorStatusEnum = data[3]

        let downLinkStatus: IndicatorStatusEnum = IndicatorStatusEnum.Healthy;
        let upLinkStatus: IndicatorStatusEnum = IndicatorStatusEnum.Healthy;

        if (newHealth === AircraftHealthEnum.DownLinkBroken) {
            downLinkStatus = IndicatorStatusEnum.Failed;
        } else {
            downLinkStatus = IndicatorStatusEnum.Healthy
        }

        if (newHealth === AircraftHealthEnum.Unattended) {
            upLinkStatus = IndicatorStatusEnum.Failed
        } else if (newHealth === AircraftHealthEnum.Unhealthy || newHealth === AircraftHealthEnum.Start) {
            upLinkStatus = IndicatorStatusEnum.Unhealthy
        } else if (newHealth === AircraftHealthEnum.Healthy) {
            upLinkStatus = IndicatorStatusEnum.Healthy
        }


        const linkHealthStatus: LinkHealthStatus = {
            downLinkDelay: this.connectionStatusList.get(aircraftIdentifier.aircraftId)?.upLinkPercent || 0,
            upLinkDelay: this.connectionStatusList.get(aircraftIdentifier.aircraftId)?.upLinkDelay || 0,
            downLinkStatus,
            upLinkStatus,
            hostStatus,
            upLinkPercent: this.connectionStatusList.get(aircraftIdentifier.aircraftId)?.upLinkPercent || 0,
            downLinkPercent: this.connectionStatusList.get(aircraftIdentifier.aircraftId)?.downLinkPercent || 0,
        };
        this.setLinkHealth(aircraftIdentifier, linkHealthStatus)

    }

    private setLinkHealth(aircraftIdentifier: AircraftIdentifier, linkHealth: LinkHealthStatus) {
        const key = aircraftIdentifier.aircraftId;
        if(!_.isEqual(linkHealth, this.aircraftStatusList.get(key)))
        {
            const oldStatus = this.aircraftStatusList.get(key)
            this.aircraftStatusList.set(key, linkHealth)
            publishEvent(ServiceEvent.AircraftHealthChanged, linkHealth, oldStatus, aircraftIdentifier)
        }
    }
}