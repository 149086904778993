import { publishEvent } from "../notification-locators/PubSubService";
import { EventForUI } from "../notification-locators/events/EventForUI";
import {UnitSystemEnum,UnitTypeEnum} from "@qandq/cloud-gcs-core"

export type UnitValue = {
    system: UnitSystemEnum;
    unit: UnitTypeEnum,
    value: number,
    unitString: string
}

export type UnitTypeSystemMap = {
    type: UnitTypeEnum;
    system: UnitSystemEnum;
};

export enum LengthUnitType {
    Meters,
    Feet,
    NauticalMiles,
    Kilometers,
}

export enum SpeedUnitType {
    MetersPerSecond,
    FeetPerSecond,
    Knots,
    KilometersPerHour
}

export class LengthHelper {
    private static toMeters = (value: number, from: LengthUnitType): number => {
        switch (from) {
            case LengthUnitType.Meters: {
                return value;
            }
            case LengthUnitType.Feet: {
                return value * 0.3048;
            }
            case LengthUnitType.NauticalMiles: {
                return value * 1852;
            }
            case LengthUnitType.Kilometers: {
                return value * 1000;
            }
            default: {
                return value;
            }
        }
    }

    private static toOthers = (value: number, type: LengthUnitType): number => {
        switch (type) {
            case LengthUnitType.Meters: {
                return value;
            }
            case LengthUnitType.Feet: {
                return value / 0.3048;
            }
            case LengthUnitType.NauticalMiles: {
                return value / 1852;
            }
            case LengthUnitType.Kilometers: {
                return value / 1000;
            }
            default: {
                return value;
            }
        }
    }

    public static convert = (value: number, from: LengthUnitType, to: LengthUnitType) => {
        let _value = LengthHelper.toMeters(value, from);
        return LengthHelper.toOthers(_value, to);
    }
}

export class SpeedHelper {
    private static fromOthersToMetersPerSecond = (value: number, from: SpeedUnitType): number => {
        switch (from) {
            case SpeedUnitType.MetersPerSecond: {
                return value;
            }
            case SpeedUnitType.FeetPerSecond: {
                return value * 0.3048;
            }
            case SpeedUnitType.Knots: {
                return value * 0.514444;
            }
            case SpeedUnitType.KilometersPerHour: {
                return value / 3.6;
            }
            default: {
                return value;
            }
        }
    }

    private static fromMetersPerSecondToOthers = (value: number, from: SpeedUnitType): number => {
        switch (from) {
            case SpeedUnitType.MetersPerSecond: {
                return value;
            }
            case SpeedUnitType.FeetPerSecond: {
                return value / 0.3048;
            }
            case SpeedUnitType.Knots: {
                return value / 0.514444;
            }
            case SpeedUnitType.KilometersPerHour: {
                return value * 3.6;
            }
            default: {
                return value;
            }
        }
    }

    public static convert = (value: number, from: SpeedUnitType, to: SpeedUnitType): number => {
        const _value = SpeedHelper.fromOthersToMetersPerSecond(value, from);
        return SpeedHelper.fromMetersPerSecondToOthers(_value, to)
    }
}

let mathRoundNew = function (value: number, digit: number = 0) {
    const multiplier = Math.pow(10, digit);
    return Math.round(value * multiplier) / multiplier;
};


export function getSelectedUnitSystem() {
    const unit = localStorage.getItem('unit-system');

    return parseInt(unit ?? UnitSystemEnum.SI.toString());
}

export function saveSelectedUnitSystem(measurement: UnitSystemEnum) {
    localStorage.setItem('unit-system', measurement.toString());
    publishEvent(EventForUI.ChangedUnitType, [measurement])
}


export class UnitsHelperNew {

    static convertToString = (system: UnitSystemEnum, type: UnitTypeEnum, value: number) => {
        const intValue = mathRoundNew(value);
        switch (type) {
            case UnitTypeEnum.LatLon:
                var minutes = (value - Math.floor(value)) * 60.0;
                var seconds = (minutes - Math.floor(minutes)) * 60.0;
                var tenths = (seconds - Math.floor(seconds)) * 10.0;
                // get rid of fractional part
                minutes = Math.floor(minutes);
                seconds = Math.floor(seconds);
                tenths = Math.floor(tenths);
                return `${intValue}° ${minutes}' ${seconds}.${tenths}`;
            case UnitTypeEnum.TimePrecise:
                const hour = mathRoundNew(intValue / 3600);
                const min = mathRoundNew(intValue / 60) % 60;
                const sec = intValue % 60;
                return `${hour}:${min}.${sec}`;
        }

        switch (system) {
            case UnitSystemEnum.SI:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return mathRoundNew(value) + " m";
                    case UnitTypeEnum.Distance:
                        return mathRoundNew(value) + " m";
                    case UnitTypeEnum.ShortDistance:
                        return mathRoundNew(value, 1).toFixed(1) + " m";
                    case UnitTypeEnum.LongDistance:
                        return `${mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Kilometers), 1)} km`
                    case UnitTypeEnum.HorizontalSpeed:
                        return mathRoundNew(value, 1).toFixed(0) + "m/s ";
                    case UnitTypeEnum.VerticalSpeed:
                        return mathRoundNew(value, 1).toFixed(0) + "m/s ";
                    case UnitTypeEnum.WindSpeed:
                        return `${mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.KilometersPerHour), 0)} kmph`
                    case UnitTypeEnum.Weigh:
                        return mathRoundNew(value, 1) + " kg";
                    case UnitTypeEnum.Volume:
                        return mathRoundNew(value, 1) + " lt";
                    case UnitTypeEnum.Temperature:
                        return mathRoundNew(value, 1) + "°C";
                }
                break;
            case UnitSystemEnum.US:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return `${mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet))} ft`
                    case UnitTypeEnum.Distance:
                        return `${mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet))} ft`
                    case UnitTypeEnum.ShortDistance:
                        return `${mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet), 1).toFixed(1)} ft`
                    case UnitTypeEnum.LongDistance:
                        return `${mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.NauticalMiles), 1)} NM`
                    case UnitTypeEnum.HorizontalSpeed:
                        return `${mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.Knots), 1).toFixed(1)} kt`
                    case UnitTypeEnum.VerticalSpeed:
                        return `${mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.FeetPerSecond) * 60)} ft/min`
                    case UnitTypeEnum.WindSpeed:
                        return `${mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.Knots), 1).toFixed(1)} kt`
                    case UnitTypeEnum.Weigh:
                        return mathRoundNew(value * 2.20462, 1) + " lb";
                    case UnitTypeEnum.Volume:
                        return mathRoundNew(value * 0.264172, 1) + " gal";
                    case UnitTypeEnum.Temperature:
                        return mathRoundNew(value * 1.8 + 32) + "°F";
                }
                break;
        }
        return "";
    };

    static convertNumber = (system: UnitSystemEnum, type: UnitTypeEnum, value: number) => {
        switch (system) {
            case UnitSystemEnum.SI:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return mathRoundNew(value);
                    case UnitTypeEnum.Distance:
                        return mathRoundNew(value);
                    case UnitTypeEnum.ShortDistance:
                        return mathRoundNew(value, 1);
                    case UnitTypeEnum.LongDistance:
                        return mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Kilometers))
                    case UnitTypeEnum.HorizontalSpeed:
                        return mathRoundNew(value, 1);
                    case UnitTypeEnum.VerticalSpeed:
                        return mathRoundNew(value, 1);
                    case UnitTypeEnum.WindSpeed:
                        return mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.KilometersPerHour), 0)
                    case UnitTypeEnum.Weigh:
                        return mathRoundNew(value, 1);
                    case UnitTypeEnum.Volume:
                        return mathRoundNew(value, 1);
                    case UnitTypeEnum.Temperature:
                        return mathRoundNew(value, 1);
                }
                break;
            case UnitSystemEnum.US:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet))
                    case UnitTypeEnum.Distance:
                        return mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet))
                    case UnitTypeEnum.ShortDistance:
                        return mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.Feet), 1)
                    case UnitTypeEnum.LongDistance:
                        return mathRoundNew(LengthHelper.convert(value, LengthUnitType.Meters, LengthUnitType.NauticalMiles))
                    case UnitTypeEnum.HorizontalSpeed:
                        return mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.Knots), 1)
                    case UnitTypeEnum.VerticalSpeed:
                        return mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.FeetPerSecond) * 60)
                    case UnitTypeEnum.WindSpeed:
                        return mathRoundNew(SpeedHelper.convert(value, SpeedUnitType.MetersPerSecond, SpeedUnitType.Knots), 1)
                    case UnitTypeEnum.Weigh:
                        return mathRoundNew(value * 2.20462, 1);
                    case UnitTypeEnum.Volume:
                        return mathRoundNew(value * 0.264172, 1);
                    case UnitTypeEnum.Temperature:
                        return mathRoundNew(value * 1.8 + 32);
                }
                break;
        }
        return 0;
    };

  static convertToSI = (system: UnitSystemEnum, unit: UnitTypeEnum, value: number) => {
        if(!value)
            return 0;

        if (system === UnitSystemEnum.SI){
          switch (unit) {
            case UnitTypeEnum.LongDistance: {
              return LengthHelper.convert(
                value,
                LengthUnitType.Kilometers,
                LengthUnitType.Meters
              );
            }
            case UnitTypeEnum.WindSpeed: {
              return SpeedHelper.convert(
                value,
                SpeedUnitType.KilometersPerHour,
                SpeedUnitType.MetersPerSecond
              );
            }
          }

          return value;
        }


        switch (unit) {
            case UnitTypeEnum.Altitude:
                return LengthHelper.convert(value, LengthUnitType.Feet, LengthUnitType.Meters)
            case UnitTypeEnum.Distance:
                return LengthHelper.convert(value, LengthUnitType.Feet, LengthUnitType.Meters)
            case UnitTypeEnum.ShortDistance:
                return LengthHelper.convert(value, LengthUnitType.Feet, LengthUnitType.Meters)
            case UnitTypeEnum.LongDistance:
                return LengthHelper.convert(value, LengthUnitType.NauticalMiles, LengthUnitType.Kilometers)
            case UnitTypeEnum.HorizontalSpeed:
                return SpeedHelper.convert(value, SpeedUnitType.Knots, SpeedUnitType.MetersPerSecond)
            case UnitTypeEnum.VerticalSpeed:
                return SpeedHelper.convert(value / 60, SpeedUnitType.FeetPerSecond, SpeedUnitType.MetersPerSecond)
            case UnitTypeEnum.WindSpeed:
                return SpeedHelper.convert(value, SpeedUnitType.Knots, SpeedUnitType.MetersPerSecond)
            case UnitTypeEnum.Weigh:
                return (value / 2.20462);
            case UnitTypeEnum.Volume:
                return (value / 0.264172);
            case UnitTypeEnum.Temperature:
                return ((value - 32) / 1.8);
        }
        return 0;
    };

    static getUnitText = (system: UnitSystemEnum, type: UnitTypeEnum) => {
        switch (system) {
            case UnitSystemEnum.SI:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return "m";
                    case UnitTypeEnum.Distance:
                        return "m";
                    case UnitTypeEnum.ShortDistance:
                        return "m";
                    case UnitTypeEnum.LongDistance:
                        return "km";
                    case UnitTypeEnum.HorizontalSpeed:
                        return "m/s";
                    case UnitTypeEnum.VerticalSpeed:
                        return "m/s";
                    case UnitTypeEnum.WindSpeed:
                        return "kmph";
                    case UnitTypeEnum.Weigh:
                        return "kg";
                    case UnitTypeEnum.Volume:
                        return "lt";
                    case UnitTypeEnum.Temperature:
                        return "°C";
                }
                break;
            case UnitSystemEnum.US:
                switch (type) {
                    case UnitTypeEnum.Altitude:
                        return "ft";
                    case UnitTypeEnum.Distance:
                        return "ft";
                    case UnitTypeEnum.ShortDistance:
                        return "ft";
                    case UnitTypeEnum.LongDistance:
                        return "NM";
                    case UnitTypeEnum.HorizontalSpeed:
                        return "kt";
                    case UnitTypeEnum.VerticalSpeed:
                        return "ft/min";
                    case UnitTypeEnum.WindSpeed:
                        return "kt";
                    case UnitTypeEnum.Weigh:
                        return "lb";
                    case UnitTypeEnum.Volume:
                        return "gal";
                    case UnitTypeEnum.Temperature:
                        return "°F";
                }
                break;
        }
        return "";
    };
}
