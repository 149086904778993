import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import Login from "../pages/login";
import Main from "../pages/main";
import PluginPage from "../pages/plugin";

export interface RouteType {
  name: String;
  path: string;
  element: JSX.Element;
  isPrivate: boolean;
}

const routes: RouteType[] = [
  {
    name: "Map",
    path: "/",
    element: <Main />,
    isPrivate: true,
  },

  {
    name: "login",
    path: "/login",
    element: <Login />,
    isPrivate: false,
  },
  {
    name: "Forgot Password",
    path: "/users/forgotPassword",
    element: <ForgotPassword />,
    isPrivate: false,
  },
  {
    name: "Reset Password",
    path: "/users/resetPassword",
    element: <ResetPassword />,
    isPrivate: false,
  },
  {
    name: "New Page",
    path: "/newPage",
    element: <ForgotPassword />,
    isPrivate: false,
  },
  {
    name: "pluginPage",
    path: "/plugin",
    element: <PluginPage />,
    isPrivate: true,
  },

  {
    name: "Not Found",
    path: "*",
    element: <div></div>,
    isPrivate: false,
  },
];

export const getRoutes = (): Array<RouteType> => {
  return routes;
};

export default routes;
