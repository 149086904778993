import { IJsonRowNode, IJsonTabNode } from "flexlayout-react";
import { PluginOutputDto } from "../UI/model/IPlugin";
import { ILayoutModel } from "../UI/model/LayoutModel";

const convertPluginDto = (
  plugins: IJsonTabNode[] | any,
  isBackgroundPlugin = false
) => {
  return plugins?.length > 0
    ? plugins.map((plugin: any) => {
        return {
          id: Number(plugin?.id),
          config: "",
          description: plugin?.config?.description,
          dllFileUrl: "",
          jsFileUrl: "",
          libraryName: "",
          name: plugin?.name,
          status: "",
          version: plugin?.config?.version,
          isBackgroundPlugin: isBackgroundPlugin,
          isSystemPlugin: "",
          publisherUser: "",
          publisherOrganization: "",
          subscribers: null,
        };
      })
    : [];
};

const getBordersPlugins = (layoutModels: ILayoutModel) => {
  let plugins: PluginOutputDto[] = [];
  layoutModels?.model.borders?.forEach((border) => {
    if (border.children.length > 0) {
      const plugin = convertPluginDto(border.children);
      if (plugin) {
        plugins = plugins.concat(plugin);
      }
    }
  });
  return plugins;
};

const getLayoutPluginsRecursive = (layout: any) => {
  const children = layout.children;
  if (!children) {
    return layout;
  }
  if (children.length > 0) {
    const plugins = children?.map((child: any) =>
      getLayoutPluginsRecursive(child)
    );

    return plugins;
  }
};

type InfinityArray = Array<InfinityArray>;
const flatten = (array: InfinityArray) => {
  const result: Array<object> = [];
  array?.forEach((arr) => {
    if (Array.isArray(arr)) {
      const flattenArray = flatten(arr);
      result.push(...flattenArray);
    } else {
      result.push(arr);
    }
  });
  return result;
};

const removeDuplicateObjects = (arr: any) => {
  let uniqueArray: Array<PluginOutputDto> = [];
  let idList: Array<number> = [];

  arr?.forEach((obj: any) => {
    if (!idList.includes(obj?.id)) {
      idList.push(obj?.id);
      uniqueArray.push(obj);
    }
  });

  return uniqueArray;
};
const getSubPagePlugins = (layoutModels: ILayoutModel) => {
  const pageList = layoutModels?.pages?.map((pageJSON) => typeof pageJSON === 'string' ? JSON.parse(pageJSON) : pageJSON);
  if (!pageList) return [];
  let pluginList: PluginOutputDto[] = [];
  pageList?.forEach((page) => {
    const backgroundPlugins = page.backgroundPlugins;
    const backgroundPluginsDto = convertPluginDto(backgroundPlugins, true);
    pluginList = pluginList.concat(backgroundPluginsDto);
    const borderPlugins = page?.model?.borders?.forEach((border: any) => {
      if (border.children.length > 0) {
        return border.children;
      }
    });
    pluginList = pluginList.concat(convertPluginDto(borderPlugins, false));
    const layoutPlugins = convertPluginDto(
      flatten(getLayoutPluginsRecursive(page.model.layout)),
      false
    );
    pluginList = pluginList.concat(layoutPlugins);
  });

  return removeDuplicateObjects(pluginList);
};

const getMainPageLayoutPLugins = (layout: IJsonRowNode) => {
  if (layout?.children?.length > 0) {
    const layoutPlugins = convertPluginDto(
      flatten(getLayoutPluginsRecursive(layout)),
      false
    );
    return layoutPlugins;
  }
  return [];
};

const getMainPagePlugins = (layoutModels: ILayoutModel) => {
  let mainPagePlugins: PluginOutputDto[] = [];
  const backgroundPlugins = convertPluginDto(
    layoutModels?.backgroundPlugins,
    true
  );
  const mainPageLayoutPlugins = getMainPageLayoutPLugins(
    layoutModels.model.layout
  );
  const mainPageBorderPlugins = getBordersPlugins(layoutModels);
  mainPagePlugins = mainPagePlugins.concat(
    mainPageBorderPlugins,
    backgroundPlugins,
    mainPageLayoutPlugins
  );
  return mainPagePlugins || [];
};

export const getPluginFromJson = (layoutModels: ILayoutModel | null) => {
  if (!layoutModels) return [];
  const mainPagePlugins = getMainPagePlugins(layoutModels);
  const subPagePlugins = getSubPagePlugins(layoutModels);
  const plugins = removeDuplicateObjects(
    mainPagePlugins.concat(subPagePlugins)
  );

  return plugins.filter((plugin) => !Number.isNaN(plugin.id));
};

export const getVersionOfPlugin = (version: string) => {
  const [tempVersion] = version.split("-");

  return tempVersion;
};


