export enum EventForUI {
    AircraftPilotChanged = 'ui-aircraft-pilot-changed',
    ReceivedHandOverCommand = 'ui-hand-over-request-received',
    ReceivedHandOverResponse = 'ui-hand-over-response-received',
    ReceivedHandOverPilotMessage = 'ui-hand-over-pilot-message',
    AnyAircraftStatusMessageReceived = 'ui-any-aircraft-status-message-received',
    ConnectionLoss = "ui-connection-loss",
    AnyUserStatusMessageReceived = 'ui-any-user-status-message-received',
    InsertSummaryLog = 'ui-insert-summary-log',
    InsertFlightLog = 'ui-flight-log',
    ActiveAircraftPilotageStateChanged = 'ui-active-aircraft-pilotage-state-changed',
    FlyByWireControlsChanged = "ui-fly-by-wire-controls-changed",
    StatusIndicatorChanged = "ui-status-indicator-changed",
    ActiveAircraftChanged = 'ui-active-aircraft-changed',
    ManageAircraftsEvent = 'ui-manage-aircraft-events',
    MissionStarted = 'ui-mission-started',
    TelemetryDataChanged = 'ui-telemetry-data-changed',
    AircraftLocationChanged = 'ui-aircraft-location-changed',
    OnSelectedAircraftStateChanged = 'on-selected-aircraft-telemetry-changed',
    AircraftRemoved = 'ui-aircraft-removed',
    AircraftHealthChanged = 'ui-aircraft-health-changed',
    AircraftLinkHealthChanged = 'ui-aircraft-health-changed',
    MissionReceived = 'ui-aircraft-mission-received',
    AircraftPilotageStateChanged = 'ui-aircraft-pilotage-state-changed',
    ActiveAircraftChanged2 = "ui-active-aircraft-changed-2",
    OnlineUsers = "ui-online-users",
    ChangedUnitType = "ui-unit-type",
    ReceivedPluginCommand = 'received-plugin-command'
}
