import { IUserAuth } from "@qandq/cloud-gcs-core";

const tokenKey = "accessToken";

export const getToken = (): string | null => {
  const token = localStorage.getItem(tokenKey);
  return token;
};

export const getOrganizationName = (): string => {
  const tenantCode = getUserData()?.tenantCode;
  return tenantCode || "";
};

export const getUserData = (): IUserAuth | null => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(tokenKey, token);
};

export const removeAccessToken = () => {
  localStorage.removeItem(tokenKey);
};

export const setUserData = (userData: any) => {
  localStorage.setItem("userData", JSON.stringify(userData));
};
