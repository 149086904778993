import { Button, Modal } from "antd";
import React from "react";
import { FC, useEffect, useState, useCallback } from "react";
import { isMobile, MobileView } from "react-device-detect";
import { IPagesModel } from "../../model/LayoutModel";
import { IPluginHelper } from "../../../pages/main";

interface ILayoutProps {
  pages: IPagesModel[];
  pluginHelper: IPluginHelper | null;
}

const PluginPages: FC<ILayoutProps> = ({ pages, pluginHelper }) => {
  const [openIndex, setOpenIndex] = useState<number>(-1);
  const [menuId, setMenuId] = useState<string>("");
  const openedWindows: Window[] = [];

  const handleOpenNewWindow = useCallback(
    (page: IPagesModel, index: number, pluginHelper: any) => {
      if (isMobile) {
        setOpenIndex(index);
        return;
      }

      if (openedWindows[index]) {
        openedWindows[index].close();
      }

      const tempWindow = window.open(
        `./plugin`,
        page.name,
        `width=${window.screen.width},height=${window.screen.height}`
      ) as any;

      tempWindow.addEventListener("load", (e: any) => {
        setTimeout(() => {
          tempWindow.setModel(page);
          tempWindow.setPluginHelper(pluginHelper);
        }, 200);
      });

      openedWindows[index] = tempWindow;

      // tempWindow?.addEventListener("load", () => {
      //   setTimeout(() => {
      //     tempWindow?.postMessage({ page: page }, "*");
      //   }, 1000);

      //   setTimeout(() => {
      //     tempWindow?.postMessage({ pluginHelper: "asd" }, "*");
      //   }, 5000);
      // });
    },
    []
  );

  useEffect(() => {
    if (!menuId && pluginHelper && pluginHelper.isPluginLoading) {
      pluginHelper.layoutManager.removeMenuItem(menuId);
      const id = pluginHelper.layoutManager.addMenuItem({
        label: "Pages",
        key: "pages",
        children: pages.map((page, index) => {
          const newpage = page;
          return {
            label: newpage.name,
            key: newpage.name,
            onClick: () => {
              handleOpenNewWindow(newpage, index, pluginHelper);
            },
          };
        }),
      });
      setMenuId(id);
    }
  }, [pages, pluginHelper]);

  // const getPages = () => {
  //   return pages.map((page, index) => {
  //     return (
  //       <Button
  //         type={"dashed"}
  //         className={"newPageButton"}
  //         size="large"
  //         onClick={() => handleOpenNewWindow(page, index)}
  //       >
  //         {page.name}
  //       </Button>
  //     );
  //   });
  // };

  const getIframes = () => {
    return pages.map((page, index) => {
      const newpage = page;
      return (
        <Modal
          key={index}
          open={openIndex === index}
          width={"100%"}
          style={{ top: "7px", height: "100%" }}
          footer={null}
          onCancel={() => setOpenIndex(-1)}
        >
          <iframe
            src={`./plugin`}
            title={newpage.name}
            width="100%"
            style={{ height: "calc(100vh - 74px)" }}
            height="100%"
            onLoad={(e) => {
              const tempWindow = (e.target as any).contentWindow;

              setTimeout(() => {
                tempWindow.setModel(newpage);
                tempWindow.setPluginHelper(pluginHelper);
              }, 1000);
            }}
          ></iframe>
        </Modal>
      );
    });
  };

  return <>{<MobileView>{getIframes()}</MobileView>}</>;
};

// function isEqual(prev: ILayoutProps, next: ILayoutProps) {
//   return !(
//     prev.pages !== next.pages || prev.pluginHelper !== next.pluginHelper
//   );
// }
export default PluginPages;
