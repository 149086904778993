import { FC, useEffect } from "react";
import { Button, notification } from "antd";
import { getPlugin, getPluginData } from "../../services/pluginServices";
import { IPluginHelper } from "../../../pages/main";
import { PluginOutputDto } from "../../model/IPlugin";
import { ILayoutPluginDto } from "../../model/LayoutModel";
import { useAuth } from "../../context/authContext";

interface ILayoutProps {
  plugins: ILayoutPluginDto[];
  pluginHelper: IPluginHelper | null;
}

const BgPluginComponent: FC<ILayoutProps> = ({ plugins, pluginHelper }) => {
  let { isAuth } = useAuth();

  const loadPlugin = async (plugin: PluginOutputDto) => {
    if (plugin.jsFileUrl) {
      await getPlugin(plugin.jsFileUrl)
        .then((res) => {
          if (!res || !plugin.name) return;
          eval(res);
          var creator = eval(window[plugin!.name as any] as any) as any;
          if (creator && creator.default) {
            const tempCreator = new creator.default(
              pluginHelper?.createPluginApi(
                document.getElementById(plugin.id.toString()) ??
                  document.createElement("div"),
                plugin
              )
            );
            tempCreator.init();
          }
        })
        .catch((err) => {
          const btn = (
            <Button
              danger
              type="link"
              size="small"
              onClick={() => {
                // loadPlugin(plugin);
              }}
            >
              Reload
            </Button>
          );
          if (isAuth) {
            notification.open({
              message: `Error loading ${plugin.name}`,
              description: err.message,
              btn,
              key: plugin.id.toString(),
            });
          }
        });
    }
  };

  const fetchPlugin = async (plugin: ILayoutPluginDto) => {
    await getPluginData(plugin.id)
      .then((res) => {
        if (res) {
          loadPlugin(res);
        } else {
          notification.destroy();
          notification.error({
            message:
              "Some of your plugins cannot be found. Please reset the Pilot UI.",
            key: "BackgrounPluginError",
          });
        }
      })
      .catch((err) => {
        // const btn = (
        //   <Button
        //     danger
        //     type="link"
        //     size="small"
        //     onClick={() => {
        //       // loadPlugin(plugin);
        //     }}
        //   >
        //     Reload
        //   </Button>
        // );
       if (isAuth) {
        notification.open({
          message: `Error loading ${plugin.id}`,
          description: err.message,
        });
       }
      });
  };

  useEffect(() => {
    if (pluginHelper && pluginHelper.isPluginLoading) {
      plugins.forEach((plugin) => {
        fetchPlugin(plugin);
      });
    }
  }, [pluginHelper]);

  return <></>;
};

export default BgPluginComponent;
