import { FC, useEffect, useState } from "react";
import { Button, Result, Spin, notification } from "antd";
import { getPlugin, getPluginData } from "../../services/pluginServices";
import { IPluginHelper } from "../../../pages/main";
import * as FlexLayout from "flexlayout-react";
import { PluginOutputDto } from "../../model/IPlugin";
import Bugsnag from "@bugsnag/js";
interface ILayoutProps {
  pluginHelper: IPluginHelper | null;
  pluginId: string;
  layoutRef: any;
}

const PluginComponent: FC<ILayoutProps> = ({
  pluginHelper,
  pluginId,
  layoutRef,
}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState<string | null>(null);

  const loadPlugin = async (pluginData: PluginOutputDto) => {
    setIsError(null);
    setIsLoading(true);
    await getPlugin(pluginData?.jsFileUrl)
      .then((res) => {
        eval(res);
        var creator = eval(window[pluginData!.name as any] as any) as any;
        if (creator && creator.default) {
          const a = pluginHelper!.createPluginApi(
            document.getElementById(pluginId) ?? document.createElement("div"),
            pluginData
          );

          const tempCreator = new creator.default(a);
          tempCreator.init();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setIsLoading(false);
        setIsError(err);
      });
  };

  const fetchPlugin = async () => {
    await getPluginData(pluginId)
      .then((res) => {
        if (res) loadPlugin(res);
        else{
          notification.destroy();
          notification.error({
            message:
              "Some of your plugins cannot be found. Please reset the Pilot UI.",
            key: "pluginError",
          });
        }
      })
      .catch((err) => {
        layoutRef.current.doAction(FlexLayout.Actions.deleteTab(pluginId));
        setIsError("Plugin not found");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (pluginHelper && pluginHelper.isPluginLoading) {
      fetchPlugin();
    }
  }, [pluginHelper]);

  return (
    <>
      <div
        id={pluginId}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
      {isLoading && (
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
          }}
        >
          <Spin tip="Plugin Uploading..." />
        </div>
      )}

      {isError && (
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
          }}
        >
          <Result
            status="error"
            title="Loading Plugin Failed"
            subTitle="Please contact the administrator."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => fetchPlugin()}
              >
                Reload
              </Button>,
            ]}
          ></Result>
        </div>
      )}
    </>
  );
};

export default PluginComponent;
