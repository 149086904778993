export enum ServiceEvent {
    AnyAircraftStatusMessageReceived = 'any-aircraft-status-message-received',
    AnyUserStatusMessageReceived = 'any-user-status-message-received',
    InsertSummaryLog = 'insert-summary-log',
    ResponseReceivedStatus = 'response-received-status',
    PluginCommandReceived = 'plugin-command-received',
    ReceivedHandOverCommand = 'hand-over-request-received',
    ReceivedHandOverResponse = 'hand-over-response-received',
    HandOverCommandRejected = 'hand-over-command-rejected',
    ReceivedHandOverPilotMessage = 'hand-over-pilot-message',
    AircraftHealthChanged = 'aircraft-health-changed',
    OnIoTConnectionLoss = 'on-iot-connection-loss',
    OnlineUsers = "online-users",
}
