import React, { createContext, useContext, useEffect, useRef } from "react";
import NotificationServiceLocator from "../../ps-infra/services/NotificationServiceLocator";
import { PSInfra } from "../../ps-infra/PSInfra";
import { getUserCredentials } from "../../utils/userHelper";
import { PluginOutputDto } from "../model/IPlugin";
import { IEventListener, IPluginApi, IPluginEventManager, IPluginServiceManager, } from "@qandq/cloud-gcs-core";
import { PluginApi } from "../../plugin-system/PluginApi";
import { PluginUIManager } from "../../plugin-system/PluginUIManager";
import { SystemApi } from "../../plugin-system/plugin-api/system-api/SystemApi";
import { useLayout } from "./LayoutContext";
import { IPluginHelper } from "../../pages/main";
import { useAuth } from "./authContext";
import { notification } from "antd";
import { PluginEventManager } from "../../plugin-system/PluginEventManager";
import { PluginServiceManager } from "../../plugin-system/PluginServiceManager";
import { EventListener } from "../../plugin-system/EventListener";
import { ICallbackService } from "../../ps-infra/services/interfaces/ICallbackService";
import { finalizeEvents } from "../../notification-locators/PubSubService";

export type PSInfraContextProps = {
  initPSInfraContext: () => void;
  finalizePSInfraContext: (isTriggeredButton?: boolean) => void;
  pluginHelper: IPluginHelper | null;
  eventListener: IEventListener;
};

let PSInfraContext = createContext<any>({});
let logoutAnswer: string = "ANSWER";

function PSInfraProvider({ children }: { children: React.ReactNode }) {
  const { layoutManager } = useLayout();
  const { logOut } = useAuth();
  const pluginHelper = useRef<IPluginHelper | null>(null);
  const psInfra = useRef<PSInfra | null>();
  const notificationServiceLocator = useRef<NotificationServiceLocator | null>(
    null
  );
  const pluginEventManager = useRef<IPluginEventManager>(
    new PluginEventManager()
  );
  const pluginServiceManager = useRef<IPluginServiceManager>(
    new PluginServiceManager()
  );
  const eventListener = useRef<IEventListener | ICallbackService | null>(null);

  function createPluginApi(
    pluginRootElement: HTMLElement,
    plugin: PluginOutputDto
  ): IPluginApi | undefined {
    if (psInfra.current) {
      if (!eventListener.current) {
        eventListener.current = new EventListener();
      }
      return new PluginApi(
        new PluginUIManager(layoutManager, pluginRootElement),
        new SystemApi(
          psInfra.current.getFlightEngineApi(),
          plugin,
          psInfra.current.getInterPilotService(),
          psInfra.current.getAirTextService(),
          psInfra.current.getAircraftLinkHealthService(),
          psInfra.current?.getUserStatusService(),
          getUserCredentials(),
          psInfra.current?.getFlightLogService(),
          psInfra.current?.getAircraftPluginCommandService()
        ),
        pluginEventManager.current,
        pluginServiceManager.current,
        eventListener.current as IEventListener
      ) as IPluginApi;
    }
  }

  const initPSInfraContext = () => {
    if (!psInfra.current) {
      const userCredentials = getUserCredentials();
      psInfra.current = new PSInfra(userCredentials, handleConnectionLoss);
      psInfra.current?.start();

      pluginHelper.current = {
        layoutManager: layoutManager,
        createPluginApi,
        isPluginLoading: true,
      };
      notificationServiceLocator.current = new NotificationServiceLocator();
    }
  };

  useEffect(() => {
    const handleUnload = async (e: any) => {

      let message = handleLogouts()
        ?.map((x) => x.message)
        .join("\n");

      if (message) {
        e.preventDefault();
        e.returnValue = '';
        return true;
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const handleConnectionLoss = async (e: any[]) => {
    const res = await finalizePSInfraContext();
    if (res) {
      notification.error({
        message: "These credentials are used to login. Redirecting to the login page!",
      });
      return;
    } else {
      notification.error({
        message: "No IoT connection!",
      });
    }
  };

  const finalize = () => {
    notificationServiceLocator.current?.finalize();
    psInfra.current?.finalize();
    finalizeEvents();

    notificationServiceLocator.current = null;
    psInfra.current = null;
    pluginHelper.current = null;

    logOut();
  };

  const handleLogouts = () => {
    const callbackService = eventListener.current as ICallbackService;
    return callbackService?.getLogoutCallbacks().filter((x) => !x.callback());
  };

  const finalizePSInfraContext = async (isTriggeredButton?: boolean) => {
    if (!isTriggeredButton) {
      finalize();
      return true;
    }

    let message = handleLogouts()
      ?.map((x) => x.message)
      .join("\n");
    if (message) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(message)) {
        finalize();
        logoutAnswer = "YES";
        return true;
      } else {
        logoutAnswer = "NO";
      }
    } else {
      finalize();
      return true;
    }
  };

  useEffect(() => {
    return () => {
      finalize();
    };
  }, []);

  let value: PSInfraContextProps = {
    initPSInfraContext,
    finalizePSInfraContext,
    pluginHelper: pluginHelper.current,
    eventListener: eventListener.current as IEventListener,
  };

  return (
    <PSInfraContext.Provider value={value}>{children}</PSInfraContext.Provider>
  );
}

const usePSInfraContext = (): PSInfraContextProps => {
  return useContext<PSInfraContextProps>(PSInfraContext);
};

export { usePSInfraContext, PSInfraProvider };
