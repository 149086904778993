import { FC, useEffect, useState } from "react";
import { Button, Col, Row, Table, Upload, notification } from "antd";
import { Tabs } from "antd";
import { PluginStatus } from "../../model/IPlugin";
import { usePSInfraContext } from "../../context/PSInfraContext";
import {
  DraggableModal,
  DraggableModalProvider,
} from "ant-design-draggable-modal";
import "ant-design-draggable-modal/dist/index.css";
import { usePlugin } from "../../context/PluginContext";
import PluginTestComponent from "../PluginTestComponent";
import {
  getVersionOfPlugin
} from "../../../utils/pluginsHelper";
import { isDevBuild, shouldDetectPlugin } from "../../../utils/environmentHelper";

interface ILayoutProps {
  onCancel: () => void;
  isOpen: boolean;
}
const PluginsModal: FC<ILayoutProps> = ({ onCancel, isOpen }) => {
  const { plugins, handleSetPlugin } = usePlugin();
  const { pluginHelper } = usePSInfraContext();
  const [openedIndexes, setOpenedIndexex] = useState<number[]>([]);

  const [devPlugin, setDevPlugin] = useState<any>(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      render: (text: string) => getVersionOfPlugin(text),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      hidden: !isDevBuild(),
      render: (text: any, record: any) => {
        if (record.config && JSON.parse(record.config).type === "local") {
          return (
            <Button
              onClick={async () => {
                setOpenedIndexex((prev) => [...prev, record.id]);
              }}
            >
              Run
            </Button>
          );
        }
      },
    },
  ].filter((item) => !item.hidden);

  const items = [
    {
      key: "1",
      label: `UI`,
      children: (
        <Table
          size="small"
          dataSource={plugins.filter((plugins) => !plugins.isBackgroundPlugin)}
          columns={columns}
        />
      ),
    },
    {
      key: "2",
      label: `Background`,
      children: (
        <Table
          size="small"
          dataSource={plugins.filter((plugins) => plugins.isBackgroundPlugin)}
          columns={columns}
        />
      ),
    },
  ];

  const onChangeBGPluginFile = (e: any) => {
    const file = e;
    const reader = new FileReader();
    reader.onload = async (t: any) => {
      handleSetPlugin({
        name: file.name.split("@")[0],
        libraryName: file.name.split("@")[0],
        config: `{"type":"local"}`,
        description: "",
        isBackgroundPlugin: true,
        jsFileUrl: t.target.result,
        isSystemPlugin: false,
        id: file.name.split("@")[0],
        status: PluginStatus.Published,
        version: file.name.split("@")[1],
        publisherOrganization: "",
      });
    };
    reader.readAsText(file);
    return false;
  };

  const onChangeUIPluginFile = (e: any) => {
    const file = e;
    const reader = new FileReader();
    reader.onload = async (t: any) => {
      handleSetPlugin({
        name: file.name.split("@")[0],
        libraryName: file.name.split("@")[0],
        config: `{"type":"local"}`,
        description: "",
        isBackgroundPlugin: false,
        jsFileUrl: t.target.result,
        isSystemPlugin: false,
        id: file.name.split("@")[0],
        status: PluginStatus.Published,
        version: file.name.split("@")[1],
        publisherOrganization: "",
      });
    };
    reader.readAsText(file);
    return false;
  };

  useEffect(() => {
    const shouldDetect = shouldDetectPlugin();
    if (shouldDetect) {
      detectDevPlugin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectDevPlugin = async () => {
    let devPlugin: any = null;
    const path = "../../../test-plugin";
    try {
      devPlugin = await import(
        /* webpackIgnore: true */
        path
      );
    } catch (error) {
      console.error("Module not found:", error);
    }

    if (devPlugin?.default?.length > 0) {
      setDevPlugin(true);
      const attr = devPlugin.getAttributes() as {
        name: string;
        libraryName: string;
        version: string;
        config: any;
      };

      handleSetPlugin({
        name: attr.name,
        libraryName: attr.libraryName,
        config: `{"type":"local"}`,
        description: "",
        isBackgroundPlugin: true,
        jsFileUrl: devPlugin as any,
        isSystemPlugin: false,
        id: 9999,
        status: PluginStatus.Published,
        version: attr.version,
        publisherOrganization: "",
      });

      setTimeout(() => {
        if (openedIndexes.includes(9999)) {
          // FIX: if the plugin is already loaded and open, it duplicates the plugin. Need to fix this
          const plugin = plugins.find((x) => x.id === 9999);
          if (!plugin) {
            notification.error({
              message: `Dev plugin not foun1111d`,
            });
            return;
          }
          if (devPlugin && devPlugin.default) {
            const b = pluginHelper!.createPluginApi(
              document.getElementById("9999" as string) ??
                document.createElement("div"),
              plugin
            );

            const tempCreator = new devPlugin.default(b);
            tempCreator.init();
            notification.success({
              message: `${attr.name} plugin loaded successfully`,
            });
          } else {
            notification.error({
              message: `${attr.name} plugin could not be loaded`,
            });
          }
        } else {
          setOpenedIndexex((prev) => [...prev, 9999]);
        }
      }, 1000);
    } else {
      notification.error({
        message: `Dev plugin not found`,
      });
    }
  };

  const footer = (
    <Row justify="end" gutter={[12, 0]}>
      {shouldDetectPlugin() && (
        <Col>
          <Button
            onClick={() => {
              detectDevPlugin();
            }}
          >
            Detect Dev Plugin
          </Button>
        </Col>
      )}
      <Col>
        <Upload
          beforeUpload={(file) => {
            onChangeUIPluginFile(file);
          }}
          fileList={[]}
        >
          <Button>Upload UI Plugin</Button>
        </Upload>
      </Col>
      <Col>
        <Upload
          beforeUpload={(file) => {
            onChangeBGPluginFile(file);
          }}
          fileList={[]}
        >
          <Button>Upload BG Plugin</Button>
        </Upload>
      </Col>
    </Row>
  );

  return (
    <DraggableModalProvider>
      <DraggableModal
        open={isOpen}
        onCancel={onCancel}
        footer={isDevBuild() ? footer : null}
        title={"Plugins"}
        initialHeight={500}
        initialWidth={500}
        className="small-draggable"
        zIndex={1000}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </DraggableModal>
      {plugins
        .filter((x) => x.config && JSON.parse(x.config).type === "local")
        .map((plugin) => {
          return (
            <PluginTestComponent
              isDev={devPlugin}
              open={openedIndexes.includes(plugin.id)}
              plugin={plugin}
              pluginHelper={pluginHelper}
              onCancel={() => {
                setOpenedIndexex(openedIndexes.filter((x) => x !== plugin.id));
              }}
            ></PluginTestComponent>
          );
        })}
    </DraggableModalProvider>
  );
};

export default PluginsModal;
