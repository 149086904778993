import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Typography, Button, Alert, notification } from "antd";
import { KeyOutlined, CopyrightOutlined } from "@ant-design/icons";

import { hideLoading, showLoading } from "../../UI/components/Loading";
import {
  isResetPasswordTokenValid,
  resetPassword,
} from "../../UI/services/tokenAuthServices";
import { IResetPasswordReq } from "../../UI/model/TokenAuth";
import {
  passwordValidation,
  passwordconfirmValidation,
} from "../../utils/formValidationHelper";
const { Title, Text } = Typography;

const ResetPassword = () => {
  const [form] = Form.useForm<IResetPasswordReq>();
  const [isError, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const navigate = useNavigate();

  const getTokenIsValid = useCallback(
    (token: string) => {
      isResetPasswordTokenValid({ token: token.replace(/\s/g, "+") })
        .then()
        .catch(() => {
          navigate("/login");
        });
    },
    [navigate]
  );

  useEffect(() => {
    getTokenIsValid(token);
  }, [getTokenIsValid, token]);

  const onFinish = async (values: IResetPasswordReq) => {
    showLoading();
    setLoading(true);
    if (token) {
      const data = {
        newPassword: values.newPassword,
        token: token.replace(/\s/g, "+"),
      };
      resetPassword(data)
        .then(() => {
          notification.success({
            message: "Success",
            description: "The password is changed.",
          });
          setLoading(false);
          hideLoading();
          navigate("/login");
        })
        .catch((err) => {
          setError("Password change failed");
          hideLoading();
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div
        className="loginPageContainer"
        onClick={() => {
          setError(null);
        }}
      >
        <div className="loginForm">
          <Form
            name="basic"
            layout="horizontal"
            labelCol={{ span: 22, offset: 2 }}
            wrapperCol={{ span: 20, offset: 2 }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
            className={"loginForm"}
            colon={false}
            form={form}
          >
            <Title style={{ textAlign: "center" }} level={2}>
              Ground Control <br />
              Pilot Station
            </Title>
            <>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password",
                  },
                  {
                    min: 8,
                    message: "Password must be minimum 8 characters.",
                  },
                  () => ({
                    validator(_, value) {
                      return passwordValidation(value);
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<KeyOutlined />}
                  placeholder="Type your new Password"
                />
              </Form.Item>
              <Form.Item
                label="Confirm New Password"
                name="confirmPassword"
                dependencies={["newPassword"]}
                rules={[
                  () => ({
                    validator(_, value) {
                      return passwordconfirmValidation(
                        form.getFieldValue("newPassword"),
                        value
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<KeyOutlined />}
                  placeholder="Confirm your new password "
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "0.5rem",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Reset
                </Button>
              </Form.Item>
              <Form.Item>
                <Link to="/login">
                  <a style={{ float: "right" }}>Login</a>
                </Link>
              </Form.Item>
            </>

            {isError && (
              <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                <Alert message={isError} type="error" banner />
              </Form.Item>
            )}
            <Text
              className="copyright"
              style={{ color: "#000", textAlign: "center" }}
            >
              <CopyrightOutlined /> {new Date().getFullYear()} CloudGCS
            </Text>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
