// import { getMockPlugin } from "../../mock/plugins/mockPluginhelper";
import { isAppRunningInBox } from "../../utils/environmentHelper";
import http, { pluginHttp } from "../../utils/request";
import { PluginOutputDto } from "../model/IPlugin";
import { getPluginInfo } from "./frappe/frappeService";

export async function getPlugin(url: string): Promise<string> {
  const data = await pluginHttp.get(url, {
    headers: { "Content-Type": "application/json" },
  });
  return data.data;
}

export const getPluginData = async (
  pluginId: string
): Promise<PluginOutputDto> => {
  const isInBox = isAppRunningInBox();
  if (isInBox) {
    // return await getMockPlugin(pluginId);
    return await getPluginInfo(pluginId);
  }
  const data = await http.get<any, PluginOutputDto>(`/plugins/${pluginId}`);
  return data;
};
