import {IMissionApi} from "@qandq/cloud-gcs-core";
import {AircraftIdentifier, AircraftMission} from "@qandq/cloud-gcs-core";
import {IFlightEngineForPluginApi} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";

export class MissionApi implements IMissionApi {
    private flightEngine: IFlightEngineForPluginApi;

    constructor(flightEngine: IFlightEngineForPluginApi) {
        this.flightEngine = flightEngine;
    }

    getMission(identifier: AircraftIdentifier): AircraftMission | undefined {
        return this.flightEngine.getAircraftMission(identifier)
    }

    uploadMission(identifier: AircraftIdentifier, data: AircraftMission): void {
        this.flightEngine.uploadAircraftMission(identifier, data)
    }

}