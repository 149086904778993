import { IIoT } from "./IIoT";
import { ZenObservable } from "zen-observable-ts/lib/types";
import { IoTProviderOptions } from "./models/IoTProviderOptions";
import { IoTSubscriber } from "./models/IoTSubscriber";
import { v4 } from "uuid";
import Bugsnag, { NotifiableError } from "@bugsnag/js";
import mqtt from "mqtt";
import { getMqttCredentials } from "../../../utils/userHelper";
import { getEMQXUrl } from "../../../utils/environmentHelper";

export class IoTEmqx implements IIoT {
  private subscribers: IoTSubscriber[] = [];
  private client: mqtt.MqttClient | undefined;
  private clientId: string;
  private isOpenToPublish: any;
  private onErrorCallback: (err: any) => void = () => {};

  constructor(clientId: string) {
    this.clientId = clientId;
  }

  connect(): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      try {
        const mqttCredentials = getMqttCredentials();
        
        var host = getEMQXUrl();

        this.client = mqtt.connect(host, {
          clientId: this.clientId,
          username: mqttCredentials.mqttUsername,
          password: mqttCredentials.mqttPassword,
          keepalive: 60,
        });

        this.client?.on("connect", () => {
          resolve("Connected" as any);
        });

        this.client?.on("error", (err) => {
          if (
            this.client?.disconnecting ||
            err.message.includes("Not authorized")
          ) {
            this.client?.end();
            this.onErrorCallback(err);
            reject(err as any);
          }
          Bugsnag.notify(err);
        });

        this.client?.on("reconnect", () => {
          this.setOnError(() => {
            new Error("Reconnecting");
          });
        });

        this.client?.on("close", () => {
          this.client?.end(true);
        });

        this.client?.on("message", (topic, message) => {
          const regexs: RegExp[] = [
            new RegExp(/^.+\/G\/.+\/.+\/M$/),
            new RegExp(/^.+\/G\/.+\/S$/),
            new RegExp(/^.+\/U\/.+\/S$/),
          ];

          let isTopicValid: boolean = false;
          for (const regex of regexs) {
            if (regex.test(topic)) {
              isTopicValid = true;
              break;
            }
          }

          if (!isTopicValid) {
            const foundSubscriber = this.subscribers.find(
              (x) => x.topic === topic
            ) as any;

            if (foundSubscriber) {
              const { subscriber } = foundSubscriber;
              subscriber.next?.({
                value: JSON.parse(message.toString()),
              });
            }
          } else {
            this.subscribers
              .filter((x) => this.topicComparer(x.topic, topic))
              .forEach((x) => {
                const subscriber = x.subscriber as ZenObservable.Observer<any>;
                if (subscriber) {
                  subscriber.next?.({
                    value: JSON.parse(message.toString()),
                  });
                }
              });
          }
        });
      } catch (exp) {
        reject(exp);
        Bugsnag.notify(exp as NotifiableError);
      }
    });
  }

  finalize(): void {
    this.subscribers = [];
    this.client?.end(true);
  }

  publish(
    topic: string,
    data: any,
    options: IoTProviderOptions
  ): Promise<void> {
    if (this.isOpenToPublish) return Promise.resolve();

    this.isOpenToPublish =
      this.client?.disconnecting === true || this.client?.disconnected === true;
    this.client?.publish(topic, JSON.stringify(data), options);
    return Promise.resolve();
  }

  subscribe(topic: string, callback: ZenObservable.Observer<any>): string {
    const subscriber: IoTSubscriber = {
      topic: topic,
      subscriber: callback,
      id: v4(),
      callback,
    };
    this.subscribers.push(subscriber);
    this.client?.subscribe(topic);

    return subscriber.id;
  }

  unsubscribe(subscriptionId: string): void {
    this.subscribers = this.subscribers.filter((x) => x.id !== subscriptionId);
  }

  private topicComparer(topic1: string, topic2: string): boolean {
    const t1 = topic1.split("/");
    const t2 = topic2.split("/");

    if (t1.length !== t2.length) {
      return false;
    }

    let res = true;
    t1.forEach((t, idx) => {
      if (t === "+") return;

      if (t === t2[idx]) return;

      res = false;
    });

    if (!res) {
    }

    return res;
  }

  setOnError(callback: (err: any) => void): void {
    this.onErrorCallback = callback;
  }
}
