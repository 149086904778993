import {
  AircraftConfiguration,
  AircraftIdentifier,
  AircraftPilotageStatus,
  CommandTypeEnum,
  IAircraftApi,
  LinkHealthStatus,
  LogLevel
} from "@qandq/cloud-gcs-core";
import {
  IFlightEngineForPluginApi
} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {IAircraftLinkHealthService} from "../../../ps-infra/services/interfaces/IAircraftLinkHealthService";
import {IAirTextService} from "../../../ps-infra/services/interfaces/IAirTextService";
import {PluginOutputDto} from "../../../UI/model/IPlugin";
import {FlightLogService} from "../../../ps-infra/flight-log/flight-log";
import {AircraftPlugin, PluginData} from "@qandq/cloud-gcs-core";
import {IAircraftPluginCommandService} from "../../../ps-infra/services/interfaces/IAircraftPluginCommandService";

export class AircraftApi implements IAircraftApi {
  private flightEngine: IFlightEngineForPluginApi;
  private aircraftLinkHealthService: IAircraftLinkHealthService;
  private aircraftPluginCommandService: IAircraftPluginCommandService;
  private plugin: PluginOutputDto;


  private readonly airTextService: IAirTextService;


  constructor(
      flightEngine: IFlightEngineForPluginApi,
      aircraftLinkHealthService: IAircraftLinkHealthService,
      plugin: PluginOutputDto,
      airTextService: IAirTextService,
      aircraftPluginCommandService: IAircraftPluginCommandService,
  ) {
    this.flightEngine = flightEngine;
    this.aircraftLinkHealthService = aircraftLinkHealthService;
    this.plugin = plugin;
    this.airTextService = airTextService;
    this.aircraftPluginCommandService = aircraftPluginCommandService;
  }

  sendPluginCommandWithResponse(identifier: AircraftIdentifier, input: PluginData): Promise<any> {
        return this.aircraftPluginCommandService.sendPluginCommandWithResponse(identifier, input);
    }

    getAircraftPlugins(aircraftIdentifier: AircraftIdentifier): AircraftPlugin[] {
        return this.flightEngine.getAircraftPlugins(aircraftIdentifier);
    }

    async sendAircraftFlightLog(
        aircraftIdentifier: AircraftIdentifier,
        data: object,
        level: LogLevel,
        message: string,
        details: string
    ): Promise<void> {
        return await FlightLogService.sendFlightLog({
            message,
            level,
            data,
            details,
            source: this.plugin.name,
            aircraftIdentifier,
        });
    }

    getAircraftLinkHealthStatus(
        aircraftIdentifier: AircraftIdentifier
    ): LinkHealthStatus | undefined {
        return this.aircraftLinkHealthService.getAircraftLinkHealthStatus(
            aircraftIdentifier
        );
    }

    //TODO: move to a commmon service
    getAircraft3dModel = (aircraftId: number): string => {
        const type = this.flightEngine.getAircraftType(aircraftId);

        let defModel = window.location.href + "models/default.glb";

        if (!type) return defModel;

        if (type?.includes("CGT")) {
            return window.location.href + `models/CGT.glb`;
        } else if (type?.includes("CNG")) {
            return window.location.href + `models/CGT.glb`;
        } else if (type?.includes("SIMULATOR")) {
            return `models/SIMULATOR.glb`;
        } else {
            return defModel;
        }
    };

    isSimulator = (aircraftIdentifier: AircraftIdentifier): boolean => {
        const isSimulator =
            this.flightEngine.getActiveAircraftFlightIdentifier()?.isSimulator;

        if (!isSimulator) return false;

        return isSimulator;
    };

    getControllingAircraft(): AircraftIdentifier[] {
        return this.flightEngine.getControlledAircrafts();
    }

    getObservingAircraft(): AircraftIdentifier[] {
        return this.flightEngine.getObservedAircrafts();
    }

    setPilotageStatuses = (
        aircraftPilotageStatuses: AircraftPilotageStatus[]
    ) => {
        this.flightEngine.setPilotageStatuses(aircraftPilotageStatuses);
    };

    subscribeToAircraftStatuses = (initiate: boolean) => {
        // this.flightEngine.subscribeToAircraftStatuses(initiate);
    };

    changeActiveAircraft = (aircraftId: number) => {
        this.flightEngine.changeActiveAircraft(aircraftId);
    };

    isBeingControlled(identifier: AircraftIdentifier): boolean {
        const aircrafts = this.getControllingAircraft();

        if (!aircrafts) return false;

        return !!aircrafts.find((x) => x.aircraftId == identifier.aircraftId);
    }

    isBeingObserved(identifier: AircraftIdentifier): boolean {
        const aircrafts = this.getObservingAircraft();
        if (!aircrafts) return false;

        return !!aircrafts.find((x) => x.aircraftId == identifier.aircraftId);
    }

    getAircraftConfiguration(
        identifier: AircraftIdentifier
    ): AircraftConfiguration | null {
        return this.flightEngine.getAircraftConfiguration(identifier) ?? null;
    }

    postAirText(identifier: AircraftIdentifier, data: any): void {
        this.airTextService.postAirText(
            this.plugin.libraryName,
            identifier.aircraftId,
            data
        );
    }

    sendPluginCommand(
        identifier: AircraftIdentifier,
        command: string,
        data: any
    ): void {
        return this.flightEngine.sendPluginCommand(identifier.aircraftId, data);
    }

    executeCommand(
        identifier: AircraftIdentifier,
        command: CommandTypeEnum,
        data: any
    ) {
        this.flightEngine.executeAircraftCommandWithData(
            identifier.aircraftId,
            command,
            data
        );
    }
}
