import {HandOverInitData} from "../../../iot/iot-service/models/HandOverInitData";
import {HandOverResponseData} from "../../../iot/iot-service/models/HandOverResponseData";
import {HandOverAircraftData} from "../../../iot/iot-service/models/HandOverAircraftData";
import {DeployParachuteData} from "./DeployParachuteData";
import {XGeoFenceData} from "./XGeoFenceData";
import {JumpToWayPoint} from "./JumpToWayPoint";
import {InstantLoiterData} from "./InstantLoiterData";
import {AckFlightMessage} from "./AckFlightMessage";
import {TopicName} from "./TopicName";
import {UserRequestMessageWithFlight} from "./UserRequestMessageWithFlight";
import {AircraftMission} from "@qandq/cloud-gcs-core";
import {CommandTypeEnum} from "@qandq/cloud-gcs-core";
import { PluginData } from "@qandq/cloud-gcs-core";

export interface AircraftCommandTopicMessage extends UserRequestMessageWithFlight {
    data?: AircraftCommandData;
}

export type AircraftCommandData =
    | Record<any, never>
    | XGeoFenceData
    | JumpToWayPoint
    | AircraftMission
    | HandOverInitData
    | HandOverResponseData
    | HandOverAircraftData
    | PluginData
    | DeployParachuteData
    | InstantLoiterData;

export function getFlightAck(
    topicName: TopicName,
    userRequestMessage: UserRequestMessageWithFlight
): AckFlightMessage {
    return {
        ...userRequestMessage,
        requestId: userRequestMessage.requestId!!,
        commandType: CommandTypeEnum.Acknowledgement,
        data: {
            forCommand: userRequestMessage.commandType,
        },
    };
}
