import { useState } from "react";
import DockLayout from "../../UI/components/DockLayout";
import { IPagesModel } from "../../UI/model/LayoutModel";
import React from "react";
declare global {
  var setModel: (e: IPagesModel) => void;
  var setPluginHelper: any;
}

const PluginPage = () => {
  const [layoutData, setLayoutData] = useState<IPagesModel | null>(null);
  const [pluginHelper, setPluginHelper] = useState(null);

  globalThis.setModel = (e: IPagesModel) => {
    setLayoutData(e);
  };

  globalThis.setPluginHelper = (e: any) => {
    setPluginHelper(e);
  };


  // window?.addEventListener("message", (event) => {
  //   if (event.data?.page) {
  //     setLayoutData(event.data.page);
  //   }

  //   if (event.data?.pluginHelper) {
  //     setPluginHelper(event.data?.pluginHelper);
  //   }
  // });

  return layoutData && pluginHelper ? (
    <DockLayout
      layoutData={layoutData.model}
      pages={[]}
      backgroundPlugins={layoutData.backgroundPlugins}
      pluginHelper={pluginHelper}
      className="flexlayout__layout_newPage"
      pageStyle={layoutData.pageStyle}
    />
  ) : (
    <div>Loading</div>
  );
};

export default PluginPage;
