import { createContext, useState, useContext, useEffect } from "react";
import { ILayoutModel } from "../model/LayoutModel";
import { v1 as uuidv1 } from "uuid";
import { LayoutManager } from "../../ps-infra/flight-engine/models/LayoutManager";
import { useAuth } from "./authContext";
import { PluginOutputDto } from "../model/IPlugin";
import { usePlugin } from "./PluginContext";
import { getPluginFromJson } from "../../utils/pluginsHelper";
import { MenuItem } from "@qandq/cloud-gcs-core";
import { getGrantedLayout } from "../services/layoutServices";
import { isMobile } from "react-device-detect";
export interface LayoutContextType {
  layoutModels: ILayoutModel | null;
  menuItems?: any;
  layoutManager: LayoutManager;
  addBottomStackItem: (item: any) => void;
}

let LayoutContext = createContext<LayoutContextType>(null!);

function LayoutProvider({ children }: { children: React.ReactNode }) {
  let [layoutModels, setLayoutModels] = useState<ILayoutModel | null>(null);
  let [menuItems, setMenuItems] = useState<any[]>([]);
  let [bottomStackItems, setBottomStackItems] = useState<any[]>([]);
  const { handleSetPlugin, plugins } = usePlugin();
  const { userInfo } = useAuth();

  useEffect(() => {
    const allPlugins = getPluginFromJson(layoutModels);
    allPlugins?.forEach((pl: any) => {
      const index = plugins.findIndex(
        (p) => p.id === (pl as PluginOutputDto).id
      );
      if (index < 0) {
        handleSetPlugin(pl as PluginOutputDto);
      }
    });
  }, [handleSetPlugin, layoutModels, plugins]);

  useEffect(() => {
    async function getLayoutAPI() {
      await getGrantedLayout(isMobile).then((res) => {
        const layout = JSON.parse(res) as ILayoutModel;
        if (layout) {
          setLayoutModels(layout);
        }
      });
    }
    if (userInfo) {
      getLayoutAPI();
    }
  }, [userInfo]);

  const addMenuItem = (item: any) => {
    const tempData = {
      ...item,
      id: uuidv1(),
    };
    setMenuItems((prev) => [...prev, tempData]);
    return tempData.id;
  };

  const addSubMenuItem = (menuId: string, items: any) => {
    setMenuItems((prev) => {
      return prev.map((item: any) => {
        if (item.id === menuId) {
          item.children = [...item.children, ...items];
        }
        return item;
      });
    });
  };

  const removeMenuItem = (menuId: string) => {
    setMenuItems((prev) => {
      return menuItems.filter((x) => x.id !== menuId);
    });
  };

  const clearSubMenuItems = (menuId: string) => {
    setMenuItems((prev) => {
      return prev.map((item: any) => {
        if (item.id === menuId) {
          item.children = [];
        }
        return item;
      });
    });
  };

  const setDisabledMenuItem = (menuId: string, disabled: boolean) => {
    setMenuItems((prev) => {
      return prev.map((item: any) => {
        if (item.id === menuId) {
          item.disabled = disabled;
        }
        return item;
      });
    });
  };

  const setDisabledSubMenuItem = (
    menuId: string,
    itemKey: string,
    disabled: boolean
  ) => {
    setMenuItems((prev) => {
      const menu = prev.map((item: any) => {
        if (item.id === menuId) {
          item.children = item.children.map((x: MenuItem) => {
            if (x.key === itemKey) x.disabled = disabled;

            return x;
          });
        }
        return item;
      });

      return menu;
    });
  };

  const addBottomStackItem = (item: any) => {
    setBottomStackItems([...bottomStackItems, item]);
  };

  let value = {
    layoutModels: layoutModels,
    menuItems,
    layoutManager: {
      addMenuItem,
      addSubMenuItem,
      removeMenuItem,
      clearSubMenuItems,
      setDisabledMenuItem,
      setDisabledSubMenuItem,
    },
    addBottomStackItem,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

const useLayout = (): LayoutContextType => {
  return useContext(LayoutContext);
};

export { useLayout, LayoutProvider };
