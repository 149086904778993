import {IIoTService} from "../iot/iot-service/IIoTService";
import {IService} from "./interfaces/IService";
import {IUserStatusService} from "./interfaces/IUserStatusService";
import {UserStatus} from "@qandq/cloud-gcs-core";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import _ from 'lodash'
import { ServiceEvent } from "./events/ServiceEvent";
import { publishEvent } from "../../notification-locators/PubSubService";

export class UserStatusService implements IService, IUserStatusService {
    private iotService: IIoTService;
    private flightEngine: IFlightEngineApi;
    private subscriptionId: string = '';
    private onlineUserInterval: any = null;
    private activeUsers: Map<string, UserStatus> = new Map<string, UserStatus>();

    constructor(iotService: IIoTService, flightEngine: IFlightEngineApi) {
        this.iotService = iotService;
        this.flightEngine = flightEngine;
    }

    start = () => {
        this.subscriptionId = this.iotService.subscribeControlStationStatus(data => {
            const userCode = data.value.userCode;


            // const currentUser = this.flightEngine.getUserInfo();
            // if(userCode === currentUser.userCode)
            //     return
            const status: UserStatus = {
                userCode,
                isPilot: data.value.isPilot,
                listOfControllingAircrafts: data.value.listOfControllingAircrafts,
                listOfObservingAircrafts: data.value.listOfObservingAircrafts,
                lastMessageTimestamp: new Date().getTime()
            }
            publishEvent(ServiceEvent.AnyUserStatusMessageReceived, status);

            if(
                !this.activeUsers.has(userCode)
                || !_.isEqual(this.activeUsers.get(userCode)?.listOfControllingAircrafts, status.listOfControllingAircrafts)
                || !_.isEqual(this.activeUsers.get(userCode)?.listOfObservingAircrafts, status.listOfObservingAircrafts)
            )
            {
                this.activeUsers.set(userCode, status)
                publishEvent(ServiceEvent.OnlineUsers, Array.from(this.activeUsers.values()))
            }
            else {
                this.activeUsers.set(userCode, status)
                
            }
        })

        // clear offline users if user does not publish status message in 3 seconds.
        this.onlineUserInterval = setInterval(() => {
            this.getActiveUsers().forEach((val, key) => {
                if(new Date().getTime() - 3000 > val.lastMessageTimestamp) {
                    this.activeUsers.delete(val.userCode)
                    publishEvent(ServiceEvent.OnlineUsers, Array.from(this.activeUsers.values()))
                }
            })
        }, 1000)
    }

    stop = () => {
        this.iotService.unsubscribe(this.subscriptionId)
        clearInterval(this.onlineUserInterval)
    }

    getActiveUsers(): UserStatus[] {
        return Array.from(this.activeUsers.values())
    }
}
