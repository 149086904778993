import React, { FC, useEffect, useState } from 'react'
import { Modal, Menu, message } from 'antd';
import type { MenuProps } from 'antd';
import { ITenantOutputRes, ITokenAuthReq } from '../../model/TokenAuth';

type MenuItem = Required<MenuProps>['items'][number];

interface IProps {
    isOpen: boolean;
    tokenAuthModel: ITokenAuthReq;
    tenants: ITenantOutputRes[];
    onClose: () => void;
    handleLogin: (req: ITokenAuthReq) => void;
}

const TenantSelect: FC<IProps> = ({ isOpen, onClose, tokenAuthModel, tenants, handleLogin }) => {
    const [items, setItems] = useState<MenuProps['items']>([])
    const [tokenAuthReq, setTokenAuthReq] = useState<ITokenAuthReq>(tokenAuthModel)
    const [tenancyName, setTenancyName] = useState<string>()
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const list = tenants.map((tenant) => getItem(tenant.name, tenant.tenancyName, null))
        setItems(list)
    }, [tenants])

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const onClick: MenuProps['onClick'] = (e) => {
        const data = { ...tokenAuthModel, tenancyName: e.key }
        setTokenAuthReq(data)
        setTenancyName(e.key)
    };

    return (
        <>
            {contextHolder}
            <Modal
                open={isOpen}
                onCancel={onClose}
                title={'Select Tenant'}
                okText={'Select'}
                onOk={() => {
                    handleLogin(tokenAuthReq)
                    tenancyName ?
                        onClose() :
                        messageApi.open({
                            type: 'error',
                            content: 'Choose a tenant',
                        });
                }}
            >
                <Menu
                    onClick={onClick}
                    style={{ minWidth: 0, flex: "auto" }}
                    mode="inline"
                    items={items}
                />
            </Modal>
        </>
    )
}

export default TenantSelect