import {IPluginServiceManager} from "@qandq/cloud-gcs-core";
import {PluginService} from "@qandq/cloud-gcs-core";
import {PluginServiceInformation} from "@qandq/cloud-gcs-core";

export class PluginServiceManager implements IPluginServiceManager {
    private services: PluginService<any>[];

    constructor() {
        this.services = [];
    }

    getName(plugin: PluginServiceInformation): string {
        return `${plugin.pluginName}_${plugin.serviceName}`;
    }

    registerService<T>(pluginService: PluginService<T>): void {
        this.services.push(pluginService);
    }

    getService<T>(
        pluginServiceInformation: PluginServiceInformation
    ): PluginService<T> {
        const pluginService = this.services.find(
            (x) => this.getName(x) === this.getName(pluginServiceInformation)
        );

        if (pluginService) {
            return pluginService;
        }

        throw new Error(`${pluginServiceInformation.pluginName} can't find!`);
    }

    async getServiceAsync<T>(
        pluginServiceInformation: PluginServiceInformation
    ): Promise<PluginService<T>> {
        return await new Promise((resolve, reject) => {
            const intervalTimeOut = 1000 * 60 * 1; // 5 min
            let intervalTime = 0;
            const interval = setInterval(() => {
                const pluginService = this.services.find(
                    (x) => this.getName(x) === this.getName(pluginServiceInformation)
                );

                if (pluginService) {
                    clearInterval(interval);
                    resolve(pluginService);
                }
                if (intervalTime >= intervalTimeOut) {
                    clearInterval(interval);
                    
                    reject(`${pluginServiceInformation.pluginName} can't find!`);
                }
                intervalTime += 200;
            }, 200);
        });
    }
}
