import { AircraftStatusTopicMessage } from "../flight-engine/models/broker-models/AircraftStatusTopicMessage";
import {
  publishEvent,
} from "../../notification-locators/PubSubService";
import { ServiceEvent } from "./events/ServiceEvent";
import { IIoTService } from "../iot/iot-service/IIoTService";
import { IService } from "./interfaces/IService";
import { IFlightEngineApi } from "../flight-engine/models/manager-models/IFlightEngineApi";
import { IndicatorStatusEnum } from "@qandq/cloud-gcs-core";

export class AircraftStatusListenerService implements IService {
  private flightEngineApi: IFlightEngineApi;
  private iotService: IIoTService;
  private subscriptionId: string = "";
  private aircraftHealts: Map<string, any> = 
    new Map<string, any>();
  private healthCheckInterval: NodeJS.Timeout;

  constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService) {
    this.flightEngineApi = flightEngineApi;
    this.iotService = iotService;
    this.healthCheckInterval = setInterval(() => {
      this.aircraftHealts.forEach((value, key) => {
        const { lastMessageTime, ...data } = value;

        if (new Date().getTime() - value.lastMessageTime > 2000) {    
          const aircraftStatusMsg = new AircraftStatusTopicMessage();

          aircraftStatusMsg.aircraftId = data.value.aircraftId;
          aircraftStatusMsg.aircraftName = data.value.aircraftName;
          aircraftStatusMsg.aircraftCertificateName =
            data.value.aircraftCertificateName;
          aircraftStatusMsg.isSimulator = data.value.isSimulator;
          aircraftStatusMsg.gcsController = {
            userCode: data.value.gcsController.userCode,
          };
          aircraftStatusMsg.healthStatus = IndicatorStatusEnum.Unhealthy;
            
          publishEvent(
            ServiceEvent.AnyAircraftStatusMessageReceived,
            aircraftStatusMsg
          );
          this.aircraftHealts.delete(key);
        }
      });
    }, 1000)
  }

  public start = () => {
    this.subscriptionId = this.iotService.subscribeAllAircraftStatuses(
      (data) => {
        const aircraftStatusMsg = new AircraftStatusTopicMessage();

        aircraftStatusMsg.aircraftId = data.value.aircraftId;
        aircraftStatusMsg.aircraftName = data.value.aircraftName;
        aircraftStatusMsg.aircraftCertificateName =
          data.value.aircraftCertificateName;
        aircraftStatusMsg.isSimulator = data.value.isSimulator;
        aircraftStatusMsg.gcsController = {
          userCode: data.value.gcsController.userCode,
        };
        aircraftStatusMsg.healthStatus = data.value.healthStatus as IndicatorStatusEnum;
        
        this.aircraftHealts.set(data.value.aircraftCertificateName, {...data, lastMessageTime: new Date().getTime()});
        
        this.flightEngineApi
          .getAircraftById(data.value.aircraftId)
          ?.setFlightId(data.value.flightId);

        publishEvent(
          ServiceEvent.AnyAircraftStatusMessageReceived,
          aircraftStatusMsg
        );
      }
    );
  };

  public stop = () => {
    this.iotService.unsubscribe(this.subscriptionId);
    clearInterval(this.healthCheckInterval);
  };
}
