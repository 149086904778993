import { publishEvent } from "../../../notification-locators/PubSubService";
import { EventForUI } from "../../../notification-locators/events/EventForUI";
import { IFlightEngineForPluginApi } from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import { PilotLogService } from "../../../ps-infra/services/PilotLogService";
import { IMessageLoggerApi } from "@qandq/cloud-gcs-core";
import { MessageLog } from "@qandq/cloud-gcs-core";
import {
  PluginAircraftLogMessage,
  PluginLogMessage,
} from "@qandq/cloud-gcs-core";
import { FlightIdentifier } from "@qandq/cloud-gcs-core";
import { MessageAircraftLog } from "../../../ps-infra/flight-engine/models/MessageAircraftLog";
import { PluginOutputDto } from "../../../UI/model/IPlugin";
import { IFlightLogService } from "../../../ps-infra/flight-log/models/IFlightLogService";
import { FlightLogService } from "../../../ps-infra/flight-log/flight-log";

export class MessageLoggerApi implements IMessageLoggerApi {
  private pluginData: PluginOutputDto;
  private flightEngine: IFlightEngineForPluginApi;
  private flightLogService: IFlightLogService;

  constructor(
    pluginData: PluginOutputDto,
    flightEngine: IFlightEngineForPluginApi,
    flightLogService: IFlightLogService
  ) {
    this.pluginData = pluginData;
    this.flightEngine = flightEngine;
    this.flightLogService = flightLogService;
  }
  publishFlightLogMessage(log: PluginAircraftLogMessage) {
    FlightLogService.sendFlightLog({
      data: log.data,
      level: log.logLevel,
      message: log.msg,
      details: log.details ?? '',
      source: this.pluginData.libraryName,
    });
  }

  publishLogMessage(log: PluginLogMessage): void {
    const msg = new MessageLog(
      log.msg,
      log.logLevel,
      log.details ?? '',
      log.data,
      this.pluginData.libraryName
    );
    publishEvent(EventForUI.InsertSummaryLog, msg);
  }

  publishPilotLogMessage(
    log: PluginAircraftLogMessage,
    isSendOtherPlugins: boolean = false
  ): void {
    if (isSendOtherPlugins) {
      this.publishAircraftLogMessage(log);
    }
    const flightIdentifier =
      this.flightEngine.getActiveAircraftFlightIdentifier();

    const identifier: FlightIdentifier = {
      flightId: flightIdentifier?.flightId ?? "",
      aircraftName: log.aircraftIdentifier.aircraftName,
      aircraftId: log.aircraftIdentifier.aircraftId,
      aircraftCertificateName: log.aircraftIdentifier?.aircraftCertificateName,
      isSimulator: log.aircraftIdentifier.isSimulator,
    };
    if (flightIdentifier) {
      PilotLogService.sendPilotLogMessage(identifier, log.logLevel, log.msg);
    }
  }

  publishAircraftLogMessage(log: PluginAircraftLogMessage): void {
    const msg = new MessageAircraftLog(
      this.pluginData.libraryName,
      log.msg,
      log.logLevel,
      log.details ?? '',
      log.data,
    );

    publishEvent(EventForUI.InsertSummaryLog, msg);
  }
}
