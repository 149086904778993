import { User } from "@qandq/cloud-gcs-core";
import { UserStatus} from "@qandq/cloud-gcs-core";
import {IUserApi} from "@qandq/cloud-gcs-core";
import {IFlightEngineForPluginApi} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {IUserStatusService} from "../../../ps-infra/services/interfaces/IUserStatusService";

export class UserApi implements IUserApi {
    //user api shouldn't use flight engine
    private flightEngine: IFlightEngineForPluginApi;
    private userStatusService: IUserStatusService;

    constructor(flightEngine: IFlightEngineForPluginApi, userStatusService: IUserStatusService) {
        this.flightEngine = flightEngine;
        this.userStatusService = userStatusService;
    }

    getUserInfo = (): User => {
        return this.flightEngine.getUserInfo();
    };

    getActiveUsers(): UserStatus[] {
        return this.userStatusService.getActiveUsers();
    }
}
