import { FC, useCallback, useRef } from "react";
import { IJsonModel, TabNode } from "flexlayout-react";
import PluginComponent from "../PluginComponent";
import FlexLayoutOverride from "../FlexLayout";
import BgPluginComponent from "../BgPluginComponent";
import PluginPages from "../PluginPages";
import { IPluginHelper } from "../../../pages/main";
import { ILayoutPluginDto, IPagesModel } from "../../model/LayoutModel";

interface ILayoutProps {
  layoutData: IJsonModel;
  pages: IPagesModel[];
  backgroundPlugins: ILayoutPluginDto[];
  pluginHelper: IPluginHelper | null;
  className?: string;
  pageStyle?: string;
}

const DockLayout: FC<ILayoutProps> = ({
  layoutData,
  pluginHelper,
  className,
  backgroundPlugins,
  pages,
  pageStyle,
}) => {
  const layoutRef = useRef<any>(null);

  const factory = useCallback(
    (node: TabNode): any => {
      const id = node.getId() as any;

      return (
        <PluginComponent
          pluginHelper={pluginHelper}
          pluginId={id}
          layoutRef={layoutRef}
        />
      );
    },
    [pluginHelper]
  );

  return (
    <>
      {pages.length > 0 && (
        <PluginPages pages={pages} pluginHelper={pluginHelper} />
      )}

      <BgPluginComponent
        plugins={backgroundPlugins}
        pluginHelper={pluginHelper}
      />

      <FlexLayoutOverride
        model={layoutData}
        factory={factory}
        className={className}
        layoutRef={layoutRef}
      />
      {pageStyle && <style>{pageStyle}</style>}
    </>
  );
};

export default DockLayout;
