import { IPluginApi, ISystemApi } from "@qandq/cloud-gcs-core";
import { IPluginUIManager } from "@qandq/cloud-gcs-core";
import { IEventListener } from "@qandq/cloud-gcs-core";
import { IPluginEventManager } from "@qandq/cloud-gcs-core";
import { IPluginServiceManager } from "@qandq/cloud-gcs-core";

export class PluginApi implements IPluginApi {
  systemApi: ISystemApi;
  pluginUIManager: IPluginUIManager;
  pluginEventManager: IPluginEventManager;
  pluginServiceManager: IPluginServiceManager;
  eventListener: IEventListener;

  constructor(
    pluginUIManager: IPluginUIManager,
    systemApi: ISystemApi,
    pluginEventManager: IPluginEventManager,
    pluginServiceManager: IPluginServiceManager,
    eventListener: IEventListener
  ) {
    this.pluginUIManager = pluginUIManager;
    this.systemApi = systemApi;
    this.pluginEventManager = pluginEventManager;
    this.pluginServiceManager = pluginServiceManager;
    this.eventListener = eventListener;
  }
}
