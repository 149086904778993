import {LogLevel} from "@qandq/cloud-gcs-core";

export interface PilotLogMessage {
    aircraftName: string,
    aircraftId: number,
    flightId: string,
    message: string,
    creationTimeUtc: string,
    source: SourceType,
    level: LogLevel,
}

export enum SourceType {
    PS = "PS",
    MC = "MC",
}

