import { IIoTService } from "./IIoTService";
import { IoTService } from "./IoTService";
import { IoTEmqx } from "../iot-core/IoTEmqx";
import { User } from "@qandq/cloud-gcs-core";

export class IoTServiceFactory {
  private static iotService?: IIoTService;

  public static createIoTService(userCredentials: User): IIoTService {
    if (!this.iotService) {
      let iot = new IoTEmqx(
        `${userCredentials.tenantCode}-${userCredentials.userCode}`
      );
      this.iotService = new IoTService(iot, userCredentials);
    }

    return this.iotService;
  }

  public static finalizeIotService() {
    this.iotService = undefined;
  }
}
