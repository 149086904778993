import { FC, useEffect } from "react";
import {
  Actions,
  IJsonModel,
  IJsonRowNode,
  IJsonTabNode,
  IJsonTabSetNode,
  Model,
  TabNode,
} from "flexlayout-react";
import * as FlexLayout from "flexlayout-react";

interface ILayoutProps {
  model: IJsonModel;
  factory: (node: TabNode) => React.ReactNode;
  className?: string;
  layoutRef?: any;
}

const FlexLayoutOverride: FC<ILayoutProps> = ({
  factory,
  model,
  className = "",
  layoutRef,
}) => {
  model.global = {
    splitterSize:0,
  };

  model.borders?.forEach((x: FlexLayout.IJsonBorderNode) => {
    if (x.children.length === 0) {
      x.barSize = 0.1;
    }
    x.children.forEach((element) => {
      element.enableClose = false;
      element.enableDrag = false;
      element.enableFloat = false;
      element.enableRename = false;
    });
  });

  const recursive = (node: IJsonRowNode | IJsonTabSetNode) => {
    if (node.type === "tabset") {
      node.enableMaximize = false;
      node.children.forEach((tab:  IJsonTabNode) => {
        tab.enableClose = false;
        tab.enableRename = false;
        tab.enableFloat = false;
        tab.enableDrag = false;
      });
      if (node.children.length === 1) {
        node.tabStripHeight = 0.1
        node.headerHeight = 0.1;
      }
    } else if (node.type === "row") {
      node.children.forEach((element: any) => {
        recursive(element);
      });
    }
  };

  model.layout.children.forEach((x: IJsonRowNode | IJsonTabSetNode) => {
    recursive(x);
  });

  return (
    <FlexLayout.Layout
      ref={layoutRef}
      model={Model.fromJson(model)}
      classNameMapper={(e: string) => {
        if (e.includes('flexlayout__layout')) {
          return `${e} ${className}`;
        }
        return `${e}`;
      }}
      factory={(e) => {
        return factory(e);
      }}
      onAction={(e) => {
        // if (
        //   e.type === "FlexLayout_AdjustSplit" ||
        //   e.type === "FlexLayout_AdjustBorderSplit"
        // ) {
        //   return;
        // }
        return e;
      }}
    />
  );
};

export default FlexLayoutOverride;
