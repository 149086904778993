import {publishEvent, removeEvent, subscribeEvent} from "./PubSubService";
import {ServiceEvent} from "../ps-infra/services/events/ServiceEvent";
import {EventForUI} from "./events/EventForUI";

export class ServiceNotification {

    private serviceToUi = new Map<ServiceEvent, EventForUI>([
            [ServiceEvent.ReceivedHandOverCommand, EventForUI.ReceivedHandOverCommand],
            [ServiceEvent.ReceivedHandOverResponse, EventForUI.ReceivedHandOverResponse],
            [ServiceEvent.AnyAircraftStatusMessageReceived, EventForUI.AnyAircraftStatusMessageReceived],
            [ServiceEvent.AnyUserStatusMessageReceived, EventForUI.AnyUserStatusMessageReceived],
            [ServiceEvent.InsertSummaryLog, EventForUI.InsertSummaryLog],
            [ServiceEvent.ReceivedHandOverPilotMessage, EventForUI.ReceivedHandOverPilotMessage],
            [ServiceEvent.AircraftHealthChanged, EventForUI.AircraftLinkHealthChanged],
            [ServiceEvent.OnIoTConnectionLoss, EventForUI.ConnectionLoss],
            [ServiceEvent.OnlineUsers, EventForUI.OnlineUsers],
        ]
    )

    constructor() {
        this.serviceToUi.forEach((e, s) => {
            subscribeEvent(s, this.getEventCallBack(e))
        })


    }

    getEventCallBack = (eventForUI: EventForUI) => (data: any[]) => {
        publishEvent(eventForUI, ...data)
    };

    finalize() {
        this.serviceToUi.forEach((e, s) => {
            removeEvent(s, this.getEventCallBack(e))
        })
    }
}
