import { FC, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  Dropdown,
  Menu,
  Layout,
  Avatar,
  Typography,
  Space,
  Select,
  Popover,
} from "antd";
import { useLayout } from "../../context/LayoutContext";
import { useAuth } from "../../context/authContext";
import TenantTypes from "../TenantTypes";
import { usePSInfraContext } from "../../context/PSInfraContext";
import PluginsModal from "../PluginsModal";
import { isPilot } from "../../../utils/userHelper";
import { getEnumKeys, getEnumValueByEnumKey } from "../../../utils/enumHelpers";
import {
  getSelectedUnitSystem,
  saveSelectedUnitSystem,
} from "../../../utils/unitsHelperNew";
import { isMobile } from "react-device-detect";
import { UnitSystemEnum } from "@qandq/cloud-gcs-core";

const { Text } = Typography;

interface IHeaderProps {}

const Header: FC<IHeaderProps> = () => {
  const { userInfo, getUserDisplayName } = useAuth();
  const { menuItems } = useLayout();
  const [isOpenPluginsModal, setIsOpenPluginsModal] = useState(false);
  const { finalizePSInfraContext, eventListener } = usePSInfraContext();
  const [measurementUnit, setMeasurementUnit] = useState<UnitSystemEnum>(
    getSelectedUnitSystem()
  );
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setIsOpenPluginsModal(true);
        }}
      >
        Plugins
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          window.open(
            "https://cloudgcs.gitbook.io/cloudcgs-user-manual",
            "_blank"
          );
        }}
      >
        Help
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          finalizePSInfraContext(true);
          eventListener.getLogoutCallbacks().forEach((item) => {
            item.callback();
          });
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Layout.Header className="site-layout-sub-header-background">
        <div>
          <Text className="logo">{isMobile ? "GCS" : "GCS Pilot Station"}</Text>
        </div>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={["mail"]}
          items={menuItems}
        ></Menu>
        <Space className="rightSide">
          {isMobile ? (
            <Popover
              content={
                <>
                  <p>
                    <b>Tenant Name:</b> {userInfo!.tenantName}
                  </p>
                  <p>
                    <b>User</b>: {getUserDisplayName()}
                  </p>
                  <p>
                    <b>{isPilot() ? "Pilot" : "Observer"}</b>
                  </p>
                </>
              }
            >
              <BsThreeDots />
            </Popover>
          ) : (
            <Text>
              {`Tenant Name: ${userInfo!.tenantName}; User: ${ getUserDisplayName()}; ${isPilot() ? "Pilot" : "Observer"}`}
            </Text>
          )}
          <TenantTypes tenantTypes={userInfo!.tenantTypes}></TenantTypes>

          <Select
            value={measurementUnit}
            defaultValue={measurementUnit}
            onChange={(e) => {
              setMeasurementUnit(e);
              saveSelectedUnitSystem(e);
            }}
            options={getEnumKeys(UnitSystemEnum).map((item) => {
              return {
                value: getEnumValueByEnumKey(UnitSystemEnum, item),
                label: item,
              };
            })}
          />

          <Dropdown overlay={menu} trigger={"click" as any}>
            <Avatar style={{ color: "white", backgroundColor: "#1a73e8" }}>
              {userInfo!.name.charAt(0).toUpperCase()}
            </Avatar>
          </Dropdown>
        </Space>
      </Layout.Header>
      <PluginsModal
        isOpen={isOpenPluginsModal}
        onCancel={() => setIsOpenPluginsModal(false)}
      />
    </>
  );
};

export default Header;
