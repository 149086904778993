import { createContext, useState, useContext } from "react";
import { PluginOutputDto } from "../model/IPlugin";
export interface PluginContextType {
  handleSetPlugin: (param: PluginOutputDto) => void;
  plugins: PluginOutputDto[];
}

let PluginContext = createContext<PluginContextType>(null!);

function PluginProvider({ children }: { children: React.ReactNode }) {
  let [plugins, setPlugins] = useState<PluginOutputDto[]>([]);

  const handleSetPlugin = (param: PluginOutputDto) => {
    const index = plugins.findIndex((plugin) => plugin.id === param.id);
    if (index < 0) {
      setPlugins((prev) => [...prev, param]);
    } else {
      setPlugins((prev) => {
        prev[index] = param;
        return prev;
      });
    }
  };

  let value = { handleSetPlugin, plugins };

  return (
    <PluginContext.Provider value={value}>{children}</PluginContext.Provider>
  );
}

const usePlugin = (): PluginContextType => {
  return useContext(PluginContext);
};

export { usePlugin, PluginProvider };
