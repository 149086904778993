import {IService} from "./interfaces/IService";
import {IAircraftPluginCommandService} from "./interfaces/IAircraftPluginCommandService";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {AircraftIdentifier, PluginCommandResponseData, PluginData} from "@qandq/cloud-gcs-core";
import {v4} from "uuid";
import {PluginCommandInput} from "../flight-engine/models/broker-models/PluginCommandInput";

interface PluginSubscriptions {
    aircraft: AircraftIdentifier;
    subscriptionId: string
}

export class AircraftPluginCommandService implements IService, IAircraftPluginCommandService{
    private readonly flightEngineApi: IFlightEngineApi
    private readonly iotService: IIoTService;
    private interval?: NodeJS.Timeout;
    private subscriptions: PluginSubscriptions[] = [];
    private commandResponse: any  = {}

    constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService) {
        this.flightEngineApi = flightEngineApi
        this.iotService = iotService;
    }

    start(): void {
        this.interval = setInterval(async () => {
            const aircrafts = this.flightEngineApi.getControlledAircrafts()
            for (const aircraft of aircrafts) {
                if(!this.subscriptions.find(x => x.aircraft.aircraftCertificateName === aircraft.aircraftCertificateName)) {
                    // subscribe
                    this.subscriptions.push({
                        aircraft,
                        subscriptionId: this.iotService.subscribePluginCommandResponse(aircraft.aircraftName, (data) => {
                            console.log(data)
                            if(data?.value?.commandId) {
                                this.commandResponse[data.value.commandId] = data.value;
                            }
                        })
                    })
                }
            }

            const subscriptions = [...this.subscriptions]
            for (const subscription of subscriptions) {
                if(!aircrafts.find(x => x.aircraftCertificateName === subscription.aircraft.aircraftCertificateName)) {
                    // unsubscribe
                    this.iotService.unsubscribe(subscription.subscriptionId)
                    this.subscriptions = this.subscriptions.filter(x => x.subscriptionId !== subscription.subscriptionId)
                }
            }
        }, 500)
    }

    stop(): void {
        clearInterval(this.interval)
        this.subscriptions.forEach(x => {
            this.iotService.unsubscribe(x.subscriptionId)
        })
        this.subscriptions = []
    }

    public async sendPluginCommandWithResponse(identifier: AircraftIdentifier, input: PluginData): Promise<PluginCommandResponseData | null> {
        const commandInput: PluginCommandInput = {
            command: input,
            sentDate: new Date(),
            commandId: v4()
        }

        await this.iotService.publishPluginCommandWithResponse(identifier.aircraftCertificateName, commandInput)

        return await new Promise<PluginCommandResponseData | null>((resolve, reject) => {
            let timer = 0;
            const interval = setInterval(() => {
                if (this.commandResponse[commandInput.commandId]) {
                    clearInterval(interval)
                    const data = JSON.parse(JSON.stringify(this.commandResponse[commandInput.commandId]));
                    delete this.commandResponse[commandInput.commandId];
                    return resolve(data?.response);
                }

                timer++;
                if (timer === (30 * 1000 / 500)) {
                    clearInterval(interval)
                    resolve(null)
                }
            }, 500)
        })
    }
}