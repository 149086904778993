// Import the JSON file directly
import appSettingsJson from '../appsettings.json';

// Define an interface to describe the expected structure of appsettings.json
interface AppSettings {
  frappePort: number;
}

// Assert the type of the imported JSON to match the AppSettings interface
const appSettings: AppSettings = appSettingsJson as AppSettings;

// Function to read the frappePort from appsettings.json
export function readFrappePort(): number {
  
  // Parse the app settings
  // const appSettings = parseAppSettings();
  
  // Return the frappePort
  return appSettings.frappePort;
}

