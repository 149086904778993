import { AircraftIdentifier } from "@qandq/cloud-gcs-core";

export class AircraftState {
    constructor(identifier: AircraftIdentifier, isControlling: boolean = false) {
        this.aircraftId = identifier.aircraftId;
        this.aircraftName = identifier.aircraftName;
        this.aircraftCertificateName = identifier.aircraftCertificateName;
        this.isControlling = isControlling;
        this.isSimulator = identifier.isSimulator;
    }

    aircraftId: number;
    aircraftName: string;
    aircraftCertificateName: string;
    isControlling: boolean;
    isSimulator: boolean ;

    public getIdentifier(): AircraftIdentifier {
        return {
            aircraftCertificateName: this.aircraftCertificateName,
            aircraftId: this.aircraftId,
            aircraftName: this.aircraftName,
            isSimulator: this.isSimulator,
        };
    }
}

