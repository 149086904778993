import { AircraftHealthEnum, IndicatorStatusEnum } from "@qandq/cloud-gcs-core";

export type GCSControllerModel = {
    userCode: string
}

export type PilotUserHealth = {
    healthStatus: AircraftHealthEnum,
    remainingFailsafeTimeInSec: number,
    lastUserCode: string,
    lastUserName: string
}

export class AircraftStatusTopicMessage {
    healthStatus: IndicatorStatusEnum = IndicatorStatusEnum.None;
    aircraftName: string = '';
    aircraftCertificateName: string = '';
    aircraftId: number = 0;
    gcsController: GCSControllerModel = {userCode: ''};
    pilotUserHealth: PilotUserHealth | undefined
    flightId: string = ""
    isSimulator: boolean = false

    load(data: any): void {
        this.aircraftId = data.value.aircraftId;
        this.aircraftName = data.value.aircraftName;
        this.flightId = data.value.flightId;
        this.healthStatus = data.value.healthStatus as IndicatorStatusEnum;
        this.aircraftCertificateName = data.value.aircraftCertificateName;
        this.isSimulator = data.value.isSimulator;
        this.gcsController = {
            userCode: data.value.gcsController.userCode,
        };
        let dataHealthStatus = data.value.pilotUserHealthStatus;
        if (dataHealthStatus != null) {
            this.pilotUserHealth = {
                healthStatus: dataHealthStatus.healthStatus,
                remainingFailsafeTimeInSec: dataHealthStatus.remainingFailsafeTimeInSec,
                lastUserCode: dataHealthStatus.lastUserCode,
                lastUserName: dataHealthStatus.lastUserName,
            }
        }
    }
}
