import http from "../../utils/request";
import {
  ITenantOutputRes,
  ITenantsByUserReq,
  ITokenAuthReq,
  ITokenAuthRes,
  IForgetPasswordReq,
  IResetPasswordReq,
  IsTokenValidReq,
} from "../model/TokenAuth";

export const Authenticate = async (
  param: ITokenAuthReq
): Promise<ITokenAuthRes> => {
  return await http.post<any, ITokenAuthRes>("TokenAuth/Authenticate", param);
};

export const getTenantsByUser = (
  input: ITenantsByUserReq
): Promise<ITenantOutputRes[]> => {
  return http.post<null, ITenantOutputRes[]>("TokenAuth/GetTenants", input);
};
//TODO:"ps" means pilot-station, If you use this service with "{ url: 'ps' }". BE will send the mail which has an url which is same url with pilot office URL address.
export const forgetPassword = async (
  param: IForgetPasswordReq
): Promise<any> => {
  return http.post(`/users/resetPassword/sendEmail`, param, {
    headers: { url: "ps" },
  });
};

export const resetPassword = async (param: IResetPasswordReq): Promise<any> => {
  return http.post(`/users/resetPassword`, param);
};

export const isResetPasswordTokenValid = async (param: IsTokenValidReq) => {
  return http.post(`/users/token/isValid`, param);
};
