import {publishEvent, removeEvent, subscribeEvent} from "./PubSubService";
import {GCSControllerModel} from "../ps-infra/flight-engine/models/broker-models/AircraftStatusTopicMessage";
import {getAircraftName} from "../utils/aircraftHelper";
import {FlightEngineEvents} from "../ps-infra/flight-engine/FlightEngineEvents";
import {ServiceEvent} from "../ps-infra/services/events/ServiceEvent";
import {EventForUI} from "./events/EventForUI";
import {LogLevel} from "@qandq/cloud-gcs-core";
import {MessageAircraftLog} from "../ps-infra/flight-engine/models/MessageAircraftLog";
import {HandOverInit} from "../ps-infra/iot/iot-service/models/HandOverInit";
import {HandOverResponse} from "../ps-infra/iot/iot-service/models/HandOverResponse";
import { AircraftIdentifier} from "@qandq/cloud-gcs-core";
import {AircraftCommandResponse} from "../ps-infra/iot/iot-service/models/AircraftCommandResponse";
import {UIPluginCommand} from "@qandq/cloud-gcs-core";
import { AircraftHealthEnum, CommandTypeEnum, PilotageStatus } from "@qandq/cloud-gcs-core";
import { getGeofenceDetails, getLogDetailsForCommand } from "../utils/LogDetailsHelper";

export class MessageLoggerNotification {

    source: string = 'System';

    constructor() {
        subscribeEvent(FlightEngineEvents.AircraftPilotageStateChanged, this.onAircraftPilotageStateChanged)
        subscribeEvent(FlightEngineEvents.AircraftPilotChanged, this.onAircraftPilotChanged)
        subscribeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
        subscribeEvent(ServiceEvent.ResponseReceivedStatus, this.onResponseReceived)
        subscribeEvent(ServiceEvent.PluginCommandReceived, this.pluginCommandReceived)
        subscribeEvent(ServiceEvent.ReceivedHandOverCommand, this.onReceiveHandOverRequest)
        subscribeEvent(ServiceEvent.ReceivedHandOverResponse, this.onReceiveHandOverResponse)
        subscribeEvent(ServiceEvent.InsertSummaryLog, this.onInsertedSummaryLog)
    }

    private onInsertedSummaryLog = (data: any[]) => {
        // publishEvent(EventForUI.InsertSummaryLog, data[0])
    }

    private pluginCommandReceived = (data: AircraftCommandResponse<UIPluginCommand>[]) => {
        publishEvent(EventForUI.ReceivedPluginCommand, data[0])
    }


    private onResponseReceived = (data: any[]) => {
        const ackWithFlightData: any = data[0]

        if (ackWithFlightData.ackReceived) {
            const command = ackWithFlightData.commandType
            if (ackWithFlightData.hasError) {
              this.handleErrorCommand(ackWithFlightData)
            } else {
              if (command === CommandTypeEnum.Jump) this.handleJumpCommand(ackWithFlightData)

              if(
                command === CommandTypeEnum.UploadMission &&
                ackWithFlightData.data.geoFence &&
                ackWithFlightData.data.geoFence.points.length > 0
              ) {
                this.handleGeofenceUpload(ackWithFlightData)
              }

              if (
                command === CommandTypeEnum.FAST_ReturnToLaunch ||
                command === CommandTypeEnum.FAST_TakeOff ||
                command === CommandTypeEnum.FAST_Sit
              ) {
                this.handleInstantCommand(ackWithFlightData)
              }
            }
        } else {
            this.handleFailedCommand(ackWithFlightData)
        }
    }

    private handleJumpCommand = (ackWithFlightData: any) => {

        const details = getLogDetailsForCommand(ackWithFlightData.commandType, ackWithFlightData);
        const data = JSON.parse(details)
        const msg = `Command: ${data.command} ${data.coordinates.latitude} ${data.coordinates.longitude} ${data.coordinates.altitude}`
        const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    }

    private handleInstantCommand = (ackWithFlightData: any) => {
        const msg = `Instant Command: ${ackWithFlightData.commandType}`
        const details = getLogDetailsForCommand(ackWithFlightData.commandType, ackWithFlightData);
        const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    }

    private handleGeofenceUpload = (ackWithFlightData: any) => {
        const msg = "The geofence is uploaded"
        const details = getGeofenceDetails(ackWithFlightData.data.geoFence)
        const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    }

    private handleFailedCommand = (ackWithFlightData: any) => {
        const details = `Response not received for request id# ${ackWithFlightData.requestId}, for command ${ackWithFlightData.commandType}`;
        const log = new MessageAircraftLog(this.source, `Command Failed: ${ackWithFlightData.commandType}`, LogLevel.Error, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    }

    private handleErrorCommand = (ackWithFlightData: any) => {
        const errorLog = new MessageAircraftLog(
          'Mission Controller',
          `Command Failed: ${ackWithFlightData.commandType}`,
          LogLevel.Error,
          `${ackWithFlightData.errorMessage}`,
          ackWithFlightData,
        )
        publishEvent(EventForUI.InsertSummaryLog, errorLog)
    }

    private onAircraftPilotChanged(data: any[]) {
        const aircraftIdentifier: AircraftIdentifier = data[0]
        const oldPilot: GCSControllerModel = data[1]
        const newPilot: GCSControllerModel = data[2]

        if (newPilot.userCode !== null) {
            const message = `Controlled by ${newPilot.userCode}`;
            const details = `${aircraftIdentifier.aircraftName} is now controlled by ${newPilot.userCode}`;
            const log = new MessageAircraftLog(this.source, message, LogLevel.Warning, details,  {},)
            publishEvent(EventForUI.InsertSummaryLog, log);
        }
    }


    private onAircraftHealthChanged = (data: any[]) => {
        const aircraftIdentifier: AircraftIdentifier = data[0]
        const oldHealth: AircraftHealthEnum = data[1]
        const newHealth: AircraftHealthEnum = data[2]

        const problems = [AircraftHealthEnum.Unhealthy, AircraftHealthEnum.DownLinkBroken, AircraftHealthEnum.Unattended]
        const isNewHealthProblematic = problems.indexOf(newHealth) > -1
        const isOldHealthProblematic = problems.indexOf(oldHealth) > -1

        if (isNewHealthProblematic) {
            const msg = `Status: ${AircraftHealthEnum[newHealth]}`;
            const details = `Aircraft status changed from ${AircraftHealthEnum[oldHealth]} to ${AircraftHealthEnum[newHealth]}`
            const log = new MessageAircraftLog(this.source, msg, LogLevel.Warning, details,{})
            publishEvent(EventForUI.InsertSummaryLog, log)
        }
        if (isOldHealthProblematic && !isNewHealthProblematic) {
            const msg = `Status: ${AircraftHealthEnum[newHealth]}`;
            const details = `Aircraft status changed from ${AircraftHealthEnum[oldHealth]} to ${AircraftHealthEnum[newHealth]}`
            const log = new MessageAircraftLog(this.source, msg, LogLevel.Warning, details,{})
            publishEvent(EventForUI.InsertSummaryLog, log)
        }
    }


    private onAircraftPilotageStateChanged = (data: any[]) => {
        const aircraftIdentifier = data[0]
        const oldStatus = data[1]
        const newStatus = data[2]
        if (newStatus === PilotageStatus.Observing) {
            const msg = "Started observing " + getAircraftName(aircraftIdentifier.aircraftName);
            const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, "", {})
            publishEvent(EventForUI.InsertSummaryLog, log)
        }
    }

    private onReceiveHandOverRequest = (data: any) => {
        const request: HandOverInit = data[0]

        const msg = "Handover request received from " + request.userCode;
        const details = `Handover request for command ${request.commandType} received from ${request.userCode}`
        const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    };

    private onReceiveHandOverResponse = (data: any) => {
        const response: HandOverResponse = data[0]

        const msg = "Handover response " + response.data.answer + " received from " + response.userCode;
        const details = `Handover response ${response.data.answer} for command ${response.commandType} received from ${response.userCode}`;
        const log = new MessageAircraftLog(this.source, msg, LogLevel.Info, details, {})
        publishEvent(EventForUI.InsertSummaryLog, log)
    };

    finalize() {
        removeEvent(FlightEngineEvents.AircraftPilotageStateChanged, this.onAircraftPilotageStateChanged)
        removeEvent(FlightEngineEvents.AircraftPilotChanged, this.onAircraftPilotChanged)
        removeEvent(FlightEngineEvents.AircraftHealthChanged, this.onAircraftHealthChanged)
        removeEvent(ServiceEvent.ResponseReceivedStatus, this.onResponseReceived)
        removeEvent(ServiceEvent.ReceivedHandOverCommand, this.onReceiveHandOverRequest)
        removeEvent(ServiceEvent.ReceivedHandOverResponse, this.onReceiveHandOverResponse)
        removeEvent(ServiceEvent.InsertSummaryLog, this.onInsertedSummaryLog)
    }
}
