import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loading = () => {
  const [isLoading, setLoading] = useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const handler = (e:any) => {
    setLoading(e.detail.show);
  };

  useEffect(() => {
    window.addEventListener("loading", handler);
    return () => window.removeEventListener("loading", handler);
  }, []);

  return ReactDOM.createPortal(
    <div className={`loading-container ${isLoading && "active"}`}>
      <Spin indicator={antIcon} />
    </div>,
    document.body
  );
};

Loading.defaultProps = {};

Loading.propTypes = {};

export default Loading;

export const showLoading = () => {
  if (typeof window !== "undefined") {
    const event = new CustomEvent("loading", {
      detail: {
        show: true,
      },
    });
    window.dispatchEvent(event);
  }
};
export const hideLoading = () => {
  if (typeof window !== "undefined") {
    const event = new CustomEvent("loading", {
      detail: {
        show: false,
      },
    });
    window.dispatchEvent(event);
  }
};
