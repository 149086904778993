
import {ICurrentUser} from "@qandq/cloud-gcs-core";
import httpFrappe from "../../../utils/requestFrappe";
import { IFrappeBaseResponse } from "./model/IFrappeBaseResponse";
import { PluginOutputDto } from "../../model/IPlugin";
// import IServiceExtensionOutput from "./model/IServiceExtension";

// export const getServiceExtensions = async (): Promise<any> => {
//   return httpFrappe.get(
//     `/api/resource/Service Extension?fields=["name","title"]`
//   );
// };

// export const getServiceExtension = async (
//   extensionName: string
// ): Promise<IServiceExtensionOutput> => {

//   // get service from list

//   console.log("extensionName", extensionName);
//   const list = (await getServiceExtensions()) as {
//     data: {
//       name: "string";
//       title: "string";
//     }[];
//   };
//   console.log("list", list);

//   const record = list.data.find(
//     (item) => item.title === extensionName.replace(" Plugin", "")
//   );

//   if (record) {
//     return httpFrappe.get(`/api/resource/Service Extension/${record.name}`);
//   } else {
//     console.log(
//       "cannot find extensionName",
//       extensionName.replace(" Plugin", ""),
//       list
//     );
//     throw new Error("cannot find extensionName");
//   }
// };

export const getTenantSetting = (): Promise<IFrappeBaseResponse<ICurrentUser>> => {
  var res = httpFrappe.get<null, IFrappeBaseResponse<ICurrentUser>>(
    `/api/method/cloud_base_app.cloud_base_app.services.auth_service.get_user_details`
  );
  return res;
};

export const loginFrappe = async (usr: string, pwd: string): Promise<any> => {
  return httpFrappe.post(`/api/method/login`, {
    usr,
    pwd,
  });
};

export const getBoxLayout = async (isMobile: boolean): Promise<string> => {
  var res = await httpFrappe.post<null, IFrappeBaseResponse<string>>(
    `/api/method/pilot_station.pilot_station.doctype.layout_data.layout_data.get_granted_layout`,
    {
      isMobile,
    }
  );
  return res.message;
};

export const getPluginInfo = async (pluginId: string): Promise<PluginOutputDto> => {
  const pluginName = pluginId.replace(" ", "%20");
  var res = await httpFrappe.post<null, IFrappeBaseResponse<PluginOutputDto>>(
    `/api/method/cloud_base_app.service_packages.services.plugin_api.get_plugin_data/`+pluginName
);
  return res.message;
};
