import {IIoT} from "../iot-core/IIoT";
import {IIoTService} from "./IIoTService";
import {UserStatusMessageModel} from "../../services/models/UserStatusTopicMessage";
import {TopicHelper} from "./TopicHelper";
import {HandOverInit} from "./models/HandOverInit";
import {HandOverResponse} from "./models/HandOverResponse";
import {AckFlightMessage} from "../../flight-engine/models/broker-models/AckFlightMessage";
import {PilotLogMessage} from "../../flight-engine/models/broker-models/PilotLogTopicMessage";
import {AirTextMessage} from "../../flight-engine/models/broker-models/AirTextMessage";
import {AircraftCommandTopicMessage} from "../../flight-engine/models/broker-models/AircraftCommandTopicMessage";
import {ZenObservable} from "zen-observable-ts/lib/types";
import {SubscriberData} from "./models/SubscriberData";
import {AircraftStatusData} from "./models/AircraftStatusData";
import {AircraftMissionData} from "./models/AircraftMissionData";
import {UserInfoData} from "./models/UserInfoData";
import {AckReceivedStatus} from "../../../utils/PubSubTimeOut";
import {UserRequestMessage} from "../../flight-engine/models/broker-models/UserRequestMessage";
import {publishEvent} from "../../../notification-locators/PubSubService";
import {ServiceEvent} from "../../services/events/ServiceEvent";
import {AircraftPluginData} from "./models/AircraftPluginData";
import {TopicName} from "../../flight-engine/models/broker-models/TopicName";
import {AircraftConfiguration, AircraftTelemetry, CommandTypeEnum,} from "@qandq/cloud-gcs-core";
import {IoTProviderOptions} from "../iot-core/models/IoTProviderOptions";
import Bugsnag from "@bugsnag/js";
import {AircraftCommandResponse} from "./models/AircraftCommandResponse";
import {FlightLog} from "../../flight-log/models/FlightLog";
import {IPluginDataAnalysis, User} from "@qandq/cloud-gcs-core";
import {PluginCommandInput} from "../../flight-engine/models/broker-models/PluginCommandInput";
import {PluginCommandResponse} from "../../services/models/PluginCommandResponse";

export class IoTService implements IIoTService {
    private readonly iot: IIoT;
    private readonly userCredentials: User;
    private static timeout = 5000;
    private static nextRequestId: number = 5;
    private static idToReceived = new Map<string, AckReceivedStatus>();
    private lastTelemetryPN = new Map<string, number>();

    constructor(iot: IIoT, userCredentials: User) {
        this.iot = iot;
        this.iot.setOnError((err: any) => {
            this.onError(err);
        });
        this.userCredentials = userCredentials;
    }

    async publishPluginCommandWithResponse(
        certificateName: string,
        data: PluginCommandInput
    ): Promise<void> {
        return this.publish(
            TopicHelper.getPluginCommandPublishTopic(
                this.userCredentials,
                certificateName
            ),
            this.insertHeaderToData(data, TopicName.PluginCommand),
            {}
        );
    }

    publishFlightLog(data: FlightLog): Promise<void> {
        return this.publish(
            TopicHelper.getFlightLogPublishTopic(
                data.aircraftCertificateName,
                this.userCredentials
            ),
            data,
            {}
        );
    }

    async start(): Promise<object> {
        return this.iot.connect();
    }

    onError(err: any) {
        publishEvent(ServiceEvent.OnIoTConnectionLoss, err);
    }

    finalize(): void {
        this.iot.finalize();
    }

    async publishUserStatus(data: UserStatusMessageModel): Promise<void> {
        return this.publish(
            TopicHelper.getUserStatusPublishTopic(this.userCredentials),
            this.insertHeaderToData(data, TopicName.UserStatus),
            {}
        );
    }

    // TODO: same topic(InterPilotSenderPublishTopic), three different models
    async publishHandOverResponse(
        toUserCode: string,
        data: HandOverResponse
    ): Promise<void> {
        return this.publishTimeout(
            TopicHelper.getInterPilotSenderPublishTopic(
                this.userCredentials,
                toUserCode
            ),
            this.insertHeaderToData(data, TopicName.InterPilot)
        );
    }

    async publishHandOverInit(
        toUserCode: string,
        data: HandOverInit
    ): Promise<void> {
        return this.publishTimeout(
            TopicHelper.getInterPilotSenderPublishTopic(
                this.userCredentials,
                toUserCode
            ),
            this.insertHeaderToData(data, TopicName.InterPilot)
        );
    }

    async publishHandOverAck(
        toUserCode: string,
        data: AckFlightMessage
    ): Promise<void> {
        return this.publish(
            TopicHelper.getInterPilotSenderPublishTopic(
                this.userCredentials,
                toUserCode
            ),
            this.insertHeaderToData(data, TopicName.InterPilot),
            {}
        );
    }

    async publishAircraftAirTextData(
        certificateName: string,
        data: AirTextMessage
    ): Promise<void> {
        return this.publish(
            TopicHelper.getAircraftAirTextPublishTopic(
                this.userCredentials,
                certificateName
            ),
            this.insertHeaderToData(data, TopicName.AirText),
            {}
        );
    }

    async publishPilotLog(
        certificateName: string,
        data: PilotLogMessage
    ): Promise<void> {
        return this.publish(
            TopicHelper.getPilotLogPublishTopic(
                this.userCredentials,
                certificateName
            ),
            this.insertHeaderToData(data, TopicName.PilotLog),
            {}
        );
    }

    async publishAircraftCommand(
        certificateName: string,
        data: AircraftCommandTopicMessage
    ): Promise<void> {
        return this.publishTimeout(
            TopicHelper.getAircraftCommandPublishTopic(
                this.userCredentials,
                certificateName
            ),
            this.insertHeaderToData(data, TopicName.UserToAircraftCommand)
        );
    }

    subscribeInterPilotListener(
        callback: (data: SubscriberData<UserRequestMessage>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getInterPilotListenerSubscribeTopic(
                this.userCredentials
            ),
            this.createCallback(callback)
        );
    }

    subscribeAllAircraftStatuses(
        callback: (data: SubscriberData<AircraftStatusData>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAllAircraftStatusesSubscribeTopic(
                this.userCredentials
            ),
            this.createCallback(callback)
        );
    }

    subscribeAircraftStatus(
        certificateName: string,
        callback: (data: SubscriberData<AircraftStatusData>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftStatusSubscribeTopic(
                this.userCredentials,
                certificateName
            ),
            this.createCallback(callback)
        );
    }

    // TODO: Check UserInfo Model
    subscribeControlStationStatus(
        callback: (data: SubscriberData<UserInfoData>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getControlStationStatusSubscribeTopic(
                this.userCredentials
            ),
            this.createCallback(callback)
        );
    }

    subscribeAircraftResponse<T>(
        certificateName: string,
        callback: (data: SubscriberData<AircraftCommandResponse<T>>) => void
    ): string {
        return this.subscribeTimeout(certificateName, callback);
    }

    subscribeAircraftTelemetry(
        certificateName: string,
        callback: (data: SubscriberData<AircraftTelemetry>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftTelemetrySubscribeTopic(
                this.userCredentials,
                certificateName
            ),

            this.createCallback((data: SubscriberData<AircraftTelemetry>) => {
                const getLastAircraftTelemetryPN = this.lastTelemetryPN.get(
                    certificateName
                );

                if (
                    getLastAircraftTelemetryPN ||
                    0 <= data.value.telemetryHeader.packageNumber
                ) {
                    this.lastTelemetryPN.set(
                        certificateName,
                        data.value.telemetryHeader.packageNumber
                    );
                    callback(data);
                }
            })
        );
    }

    subscribeAircraftMission(
        certificateName: string,
        callback: (data: SubscriberData<AircraftMissionData>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftMissionSubscribeTopic(
                this.userCredentials,
                certificateName
            ),
            this.createCallback(callback)
        );
    }

    subscribeAircraftPlugins(
        certificateName: string,
        callback: (data: SubscriberData<AircraftPluginData>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftPluginsSubscribeTopic(
                this.userCredentials,
                certificateName
            ),
            this.createCallback(callback)
        );
    }

    subscribePluginDataAnalysis(
        certificateName: string,
        callback: (data: SubscriberData<IPluginDataAnalysis>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getPluginDataAnalysisTopic(certificateName, this.userCredentials),
            this.createCallback(callback)
        );
    }

    subscribePluginCommandResponse(
        certificateName: string,
        callback: (data: SubscriberData<PluginCommandResponse>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getPluginCommandResponseTopic(this.userCredentials, certificateName),
            this.createCallback(callback)
        );
    }

    subscribeAircraftParameters(
        certificateName: string,
        callback: (data: SubscriberData<AircraftConfiguration>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftParametersSubscribeTopic(
                this.userCredentials,
                certificateName
            ),
            this.createCallback(callback)
        );
    }

    unsubscribe(subscriptionId: string): void {
        this.iot.unsubscribe(subscriptionId);
    }

    private insertHeaderToData(data: any, topicName: TopicName) {
        const header = this.userCredentials;
        return {
            ...header,
            topicName: topicName,
            ...data,
        };
    }

    private async publish(
        topic: string,
        data: any,
        options: IoTProviderOptions
    ): Promise<void> {
        try {
            await this.iot.publish(
                topic,
                {
                    ...data,
                    timestamp: new Date().valueOf(),
                },
                options
            );
            return Promise.resolve();
        } catch (exp) {
            // try re-connect
            // await this.iot.connect()
            this.onError(exp);
            return Promise.reject(exp);
        }
    }

    private createCallback<T>(
        callback: (data: SubscriberData<T>) => void
    ): ZenObservable.Observer<any> {
        return {
            next(data: any) {
                const _data: SubscriberData<T> = {
                    value: data.value as T,
                };
                callback && callback(_data);
            },
            complete() {
            },
            error(errorValue: any) {
                Bugsnag.notify(errorValue);
            },
            start(subscription: ZenObservable.Subscription): any {
            },
        } as ZenObservable.Observer<any>;
    }

    private subscribeTimeout<T>(
        certificateName: string,
        callback: (data: SubscriberData<AircraftCommandResponse<T>>) => void
    ): string {
        return this.iot.subscribe(
            TopicHelper.getAircraftResponseSubscribeTopic(
                this.userCredentials,
                certificateName
            ),
            this.createCallback(
                (data: SubscriberData<AircraftCommandResponse<T>>) => {
                    const val = data.value;

                    // pluginCommand sent from mission controller. not response message of any request
                    if (val?.command === CommandTypeEnum.PluginCommand) {
                        publishEvent(ServiceEvent.PluginCommandReceived, data.value);
                        return;
                    }

                    const requestId: string = val?.requestId;
                    if (IoTService.idToReceived.has(requestId)) {
                        let responseReceivedStatus: any = IoTService.idToReceived.get(
                            requestId
                        );
                        if (!responseReceivedStatus) return;

                        // QoS 1 may send a response more than once. Ignore the second message:
                        if (responseReceivedStatus.ackReceived) return;

                        responseReceivedStatus.ackReceived = true;
                        responseReceivedStatus.hasError = val.hasError;
                        responseReceivedStatus.errorMessage = val.errorMessage;

                        publishEvent(
                            ServiceEvent.ResponseReceivedStatus,
                            responseReceivedStatus
                        );

                        callback && callback(data);
                    }
                }
            )
        );
    }

    private publishTimeout(topic: string, msg: any): Promise<void> {
        const currentId = this.embedRequestId(msg);
        const responseResult: AckReceivedStatus = {
            ...(msg as UserRequestMessage),
            ackReceived: false,
        };
        IoTService.idToReceived.set(currentId.toString(), responseResult);
        setTimeout(() => {
            const responseReceivedStatus = IoTService.idToReceived.get(
                currentId.toString()
            );
            if (
                responseReceivedStatus &&
                !responseReceivedStatus.ackReceived
            ) {
                publishEvent(
                    ServiceEvent.ResponseReceivedStatus,
                    responseReceivedStatus
                );
            }
            IoTService.idToReceived.delete(currentId.toString());
        }, IoTService.timeout);
        return this.iot.publish(topic, msg, {});
    }

    private embedRequestId(msg: any): number {
        const currentId = IoTService.nextRequestId;
        IoTService.nextRequestId++;
        msg.requestId = currentId.toString();
        return currentId;
    }
}
