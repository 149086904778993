import { LayoutManager } from "../ps-infra/flight-engine/models/LayoutManager";
import { IPluginUIManager } from "@qandq/cloud-gcs-core";
import {v4} from "uuid";
import { MenuItem } from "@qandq/cloud-gcs-core";

export class PluginUIManager implements IPluginUIManager {
  pluginRootElement: HTMLElement;
  layoutManager: LayoutManager;
  intervalTimeOut = 60 * 1000 ; 


  constructor(layoutManager: LayoutManager, pluginRootElement: HTMLElement) {
    this.pluginRootElement = pluginRootElement;
    this.layoutManager = layoutManager;
  }

  async getBottomStack(): Promise<HTMLElement> {
    return await new Promise((resolve, reject) => {
      let intervalTime = 0;
      const interval = setInterval(() => {
        let bottomStack = document.getElementById("mapBottomStack");

        if (bottomStack) {

          const pluginDiv = document.createElement('div')
          pluginDiv.id = v4()
          bottomStack.appendChild(pluginDiv)

          clearInterval(interval);
          resolve(pluginDiv);
        }
        if (intervalTime >= this.intervalTimeOut) {
          clearInterval(interval);
          reject(`Bottom stack can't find!`);
        }
        intervalTime += 200;
      }, 200);
    });
  }

  async getTopStack(): Promise<HTMLElement> {
    return await new Promise((resolve, reject) => {
      let intervalTime = 0;
      const interval = setInterval(() => {
        const topStack = document.getElementById("mapTopStack");

        if (topStack) {
          clearInterval(interval);
          resolve(topStack);
        }
        if (intervalTime >= this.intervalTimeOut) {
          clearInterval(interval);
          reject(`Bottom stack can't find!`);
        }
        intervalTime += 200;
      }, 200);
    });
  }

  addMenuItem(item: MenuItem): string {
    return this.layoutManager.addMenuItem(item);
  }

  addSubMenuItem(menuId: string, items: MenuItem[]): void {
    this.layoutManager.addSubMenuItem(menuId, items);
  }

  getRootElement(): HTMLElement {
    return this.pluginRootElement;
  }

  removeMenuItem(menuId: string): void {
    this.layoutManager.removeMenuItem(menuId);
  }

  clearSubMenuItems(menuId: string): void {
    this.layoutManager.clearSubMenuItems(menuId);
  }

  setDisabledMenuItem(menuId: string, disabled: boolean): void {
    this.layoutManager.setDisabledMenuItem(menuId, disabled);
  }

  setDisabledSubMenuItem(
      menuId: string,
      itemKey: string,
      disabled: boolean
  ): void {
    this.layoutManager.setDisabledSubMenuItem(menuId, itemKey, disabled);
  }
}
