import { LogLevel } from "@qandq/cloud-gcs-core";
import { MessageLog } from "@qandq/cloud-gcs-core";

export class MessageAircraftLog extends MessageLog {
  aircraftName?: string;

  public constructor(
    source: string,
    message: string,
    level: LogLevel,
    details: string,
    data: object,
    aircraftName?: string,
  ) {
    super(message, level, details, data, source);
    this.aircraftName = aircraftName;
  }
}
