import {ServiceNotification} from "../../notification-locators/ServiceNotification";
import {FlightEngineNotification} from "../../notification-locators/FlightEngineNotification";
import {INotificationServiceLocator} from "../../notification-locators/INotificationServiceLocator";
import {MessageLoggerNotification} from "../../notification-locators/MessageLoggerNotification";

export default class NotificationServiceLocator implements INotificationServiceLocator {
    private serviceNotification: ServiceNotification;
    private dataChangedNotification: FlightEngineNotification;
    private consoleNotification: MessageLoggerNotification;

    constructor() {
        this.serviceNotification = new ServiceNotification();
        this.dataChangedNotification = new FlightEngineNotification();
        this.consoleNotification = new MessageLoggerNotification();
    }

    getServiceNotification() {
        return this.serviceNotification
    }

    getDataChangedNotification() {
        return this.dataChangedNotification;
    }

    getMessageNotificationLogger() {
        return this.consoleNotification;
    }

    finalize() {
        this.serviceNotification.finalize();
        this.dataChangedNotification.finalize();
        this.consoleNotification.finalize();
    }
}
