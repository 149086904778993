import { IFlightEngineForPluginApi } from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import { IDataAnalyzeApi, IPluginDataAnalysis } from "@qandq/cloud-gcs-core";

export class DataAnalyzeApi implements IDataAnalyzeApi {
  private flightEngine: IFlightEngineForPluginApi;
  constructor(flightEngine: IFlightEngineForPluginApi) {
    this.flightEngine = flightEngine;
  }

  getMessage(aircraftId: number): IPluginDataAnalysis | null {
    return this.flightEngine.getLastPluginDataAnalysis(aircraftId);
  }
}
