export function getUTCDateText(date: Date): string {
    return [
            date.getUTCFullYear(),
            ('0' + (date.getUTCMonth() + 1)).slice(-2),
            ('0' + date.getUTCDay()).slice(-2)
        ].join('-')
        + "T" +
        [
            ('0' + date.getUTCHours()).slice(-2),
            ('0' + date.getUTCMinutes()).slice(-2),
            ('0' + date.getUTCSeconds()).slice(-2),
        ].join(':')
        + "Z"
}

export function getUTCDateTextNow(): string {
    return getUTCDateText(new Date())
}