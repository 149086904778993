export const passwordValidation = async (
  value: string,
  errorMessage = "Please enter valid password"
) => {
  if (
    !value ||
    value.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|\\])(?=.{6,})(^\S+$)/
    )
  ) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(errorMessage));
};
export const passwordconfirmValidation = async (
  value: string,
  secondValue: string,
  errorMessage = "The passwords do not match!"
) => {
  if (value === secondValue) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(errorMessage));
};
