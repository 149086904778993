import { AircraftModel } from "./models/manager-models/AircraftModel";
import FlightData from "./models/FlightData";
import { IFlightEngineApi } from "./models/manager-models/IFlightEngineApi";
import { AircraftCommandData } from "./models/broker-models/AircraftCommandTopicMessage";
import { AircraftStatusTopicMessage } from "./models/broker-models/AircraftStatusTopicMessage";
import {
  AircraftConfiguration,
  AircraftIdentifier,
  AircraftMission,
  AircraftPilotageStatus,
  AircraftTelemetry,
} from "@qandq/cloud-gcs-core";
import { publishServiceMessageLog } from "./models/helper-models/MessageLoggerHelper";
import { LogLevel } from "@qandq/cloud-gcs-core";
import { SavedLocalStorage } from "./models/SavedLocalStorage";
import { ProcessingFunctions } from "./models/manager-models/ProcessingFunctions";
import { MessageAircraftLog } from "./models/MessageAircraftLog";
import { IIoTService } from "../iot/iot-service/IIoTService";
import { publishEvent } from "../../notification-locators/PubSubService";
import { FlightEngineEvents } from "./FlightEngineEvents";
import { CommandTypeEnum } from "@qandq/cloud-gcs-core";
import { FlightIdentifier } from "@qandq/cloud-gcs-core";
import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { UserStatusPackage } from "./models/UserStatusPackage";
import {
  AircraftHealthEnum,
  AircraftLocation,
  AircraftPlugin,
  IPluginDataAnalysis,
  PilotageStateEnum,
  PluginData,
  User,
} from "@qandq/cloud-gcs-core";

export default class FlightEngine implements IFlightEngineApi {
  private readonly iotService: IIoTService;
  private readonly flightData: FlightData;
  private readonly userCredentials: User;
  private userStatusPackage: UserStatusPackage;
  private isActive: boolean;

  constructor(user: User, iotService: IIoTService) {
    // remember not to subscribe to topics here. Do it in initializeMQTT
    this.userCredentials = user;
    this.iotService = iotService;
    this.userStatusPackage = {
      packageNumber: 0,
      timestamp: 0,
    };

    this.flightData = new FlightData(user, this.iotService);
    this.isActive = false;
  }
  getAircraftPlugins(identifier: AircraftIdentifier) {
    return (
      this.getAircraftById(identifier.aircraftId)?.getAircraftPlugins() ?? []
    );
  }

  getUserStatusPackage = () => {
    return this.userStatusPackage;
  };

  setUserStatusPackage = (userStatusPackage: UserStatusPackage) => {
    this.userStatusPackage = userStatusPackage;
  };

  getAircraftConfiguration(
    identifier: AircraftIdentifier
  ): AircraftConfiguration | undefined | null {
    return this.getAircraftById(identifier.aircraftId)?.aircraftParameters;
  }

  getAircraftMission(
    identifier: AircraftIdentifier
  ): AircraftMission | undefined {
    return this.getAircraftById(identifier.aircraftId)?.aircraftMission;
  }

  uploadAircraftMission(
    identifier: AircraftIdentifier,
    mission: AircraftMission
  ) {
    this.getAircraftById(identifier.aircraftId)?.executeCommandTypeWithData(
      CommandTypeEnum.UploadMission,
      mission
    );
  }

  getSelectedAircraftConfiguration = (): AircraftConfiguration | null => {
    const aircraft = this.getActiveAircraftModel();

    if (aircraft && aircraft.aircraftParameters) {
      return aircraft.aircraftParameters;
    }

    return null;
  };

  getSelectedAircraftType = (): string | null => {
    const aircraft = this.getActiveAircraftModel();

    if (aircraft && aircraft.aircraftParameters) {
      return aircraft.aircraftParameters.aircraftType;
    }

    return null;
  };

  getAircraftType = (aircraftId: number): string | null => {
    const aircraft = this.flightData.getAircraftById(aircraftId);

    if (aircraft && aircraft.aircraftParameters) {
      return aircraft.aircraftParameters.aircraftType;
    }

    return null;
  };

  getSelectedAircraftLocation = (): AircraftLocation | null => {
    const aircraft = this.getActiveAircraftModel();

    if (aircraft) {
      return this.getLastAircraftLocation(
        aircraft.aircraftIdentifier.aircraftId
      );
    }

    return null;
  };
  getSelectedAircraftTelemetry = (): AircraftTelemetry | null => {
    const aircraft = this.getActiveAircraftModel();

    if (aircraft) {
      return aircraft.getLastAircraftTelemetry();
    }

    return null;
  };

  insertSummaryLog = (input: { detail: any }) => {
    this.flightData.insertSummaryLog(input);
  };

  changeActiveAircraft = (aircraftId: number) => {
    this.flightData.changeActiveAircraft(aircraftId);
  };

  initializeMQTT = () => {
    try {
      this.isActive = true;
      this.loadFromPreviousFlightData();
    } catch (exp) {
      Bugsnag.notify(exp as NotifiableError);
    }
  };

  private getActiveAircraftModel(): AircraftModel | null {
    return this.flightData.getActiveAircraftModel();
  }

  subscribeAircrafts = (pilotage: AircraftPilotageStatus[]) => {
    pilotage.forEach((x) => {
      let aircraft = this.flightData.getAircraftByCertificateName(
        x.aircraftIdentifier.aircraftCertificateName
      );

      if (aircraft === null) {
        if (x.state !== PilotageStateEnum.None) {
          this.flightData.insertAircraft(x.aircraftIdentifier);
          const shouldRequestClaim = x.state === PilotageStateEnum.Controlling;
          this.registerAircraft(x.aircraftIdentifier.aircraftCertificateName);
          if (shouldRequestClaim) {
            aircraft = this.flightData.getAircraftByCertificateName(
              x.aircraftIdentifier.aircraftCertificateName
            );
            aircraft?.subscribePluginDataAnalysis();
            aircraft?.requestClaim();
          }
        }
      } else {
        aircraft.subscribePluginDataAnalysis();

        if (x.state === PilotageStateEnum.None) {
          this.unregisterAircraft(x.aircraftIdentifier.aircraftCertificateName);
          this.flightData.removeAircraft(
            x.aircraftIdentifier.aircraftCertificateName
          );

          publishEvent(
            FlightEngineEvents.AircraftPilotageStateChanged,
            x.aircraftIdentifier
          );

          this.flightData.checkActiveAircraftPilotageState(
            x.aircraftIdentifier,
            x.state
          );
        } else if (
          x.state === PilotageStateEnum.Controlling &&
          aircraft.isObservingButNotControlling()
        ) {
          aircraft.requestClaim();
          this.flightData.checkActiveAircraftPilotageState(
            x.aircraftIdentifier,
            x.state
          );

          const log = new MessageAircraftLog(
            "System",
            "Requesting control of " + x.aircraftIdentifier.aircraftName,
            LogLevel.Info,
            `${this.userCredentials.userName} is requesting control of ${x.aircraftIdentifier.aircraftName}`,
            {}
          );
          publishServiceMessageLog(log);
        }
      }
    });
  };

  setPilotageStatuses = (
    AircraftPilotageStatuses: AircraftPilotageStatus[]
  ) => {
    this.subscribeAircrafts(AircraftPilotageStatuses);
  };

  getAircraftById = (aircraftId: number) => {
    return this.flightData.getAircraftById(aircraftId);
  };

  registerAircraft = (aircraftCertificateName: string) => {
    const aircraft = this.flightData.getAircraftByCertificateName(
      aircraftCertificateName
    );
    if (aircraft === null) return;

    const processMessages: ProcessingFunctions = {
      processTelemetry: (data) => {},
      processMission: (data) => {},
      processStatus: (data) => {},
      processParameters: (data) => {},
    };
    aircraft.startObserving(processMessages);
  };

  unregisterAircraft = (aircraftCertificateName: string) => {
    let aircraft = this.flightData.getAircraftByCertificateName(
      aircraftCertificateName
    );
    if (aircraft === null) return;

    aircraft.unregister();
  };

  isActiveAircraftBeingControlled(): boolean {
    return this.flightData.isActiveAircraftBeingControlled();
  }

  getActiveAircraftIdentifier(): AircraftIdentifier | null {
    return this.getActiveAircraftModel()?.aircraftIdentifier ?? null;
  }

  getActiveAircraftPlugins(): AircraftPlugin[] {
    return this.getActiveAircraftModel()?.getAircraftPlugins() ?? [];
  }

  getActiveAircraftFlightIdentifier(): FlightIdentifier | undefined {
    return this.getActiveAircraftModel()?.getFlightIdentifier();
  }

  getControlledAircrafts(): AircraftIdentifier[] {
    return this.flightData.getControlledAircrafts();
  }

  getObservedAircrafts(): AircraftIdentifier[] {
    return this.flightData.getObservedAircrafts();
  }

  sendPluginCommand(aircraftId: number, pluginData: PluginData): void {
    const aircraft = this.flightData.getAircraftById(aircraftId);
    aircraft?.executeCommandTypeWithData(CommandTypeEnum.Plugin, pluginData);
  }

  executeCommandOnActiveAircraft(command: CommandTypeEnum): void {
    this.flightData.executeCommandType(command);
  }

  getUserCredentials(): User {
    return this.userCredentials;
  }

  getUserInfo(): User {
    return this.userCredentials;
  }

  hasAnyRegisteredAircraft(): boolean {
    return this.flightData.hasAnyRegisteredAircraft();
  }

  sendLogoutSignal(): void {
    this.flightData.sendLogoutSignal();
    this.finalize();
  }

  startMission(): void {
    this.flightData.startMission();
  }

  saveToLocalStorage(): void {
    this.flightData.saveToLocalStorage();
  }

  getActiveAircraftPilotUserCode(): string | null {
    return this.getActiveAircraftModel()?.getPilot()?.userCode ?? null;
  }

  getFlightIdentifier(aircraftId: number): FlightIdentifier | null {
    return (
      this.flightData.getAircraftById(aircraftId)?.getFlightIdentifier() ?? null
    );
  }

  loadFromPreviousFlightData = () => {
    const savedLocalStorage = this.flightData.getPreviousFlightData();

    if (savedLocalStorage === null) return;

    if (
      savedLocalStorage.controlledAircrafts.length === 0 &&
      savedLocalStorage.observedAircrafts.length === 0
    ) {
      return;
    }

    const id = this.iotService.subscribeAllAircraftStatuses((data) => {
      if (savedLocalStorage === null) return;
      const aircraftStatusMsg = new AircraftStatusTopicMessage();

      this.getAircraftById(aircraftStatusMsg.aircraftId)?.setFlightId(
        data.value.flightId
      );

      aircraftStatusMsg.load(data);
      let index = savedLocalStorage.observedAircrafts.indexOf(
        aircraftStatusMsg.aircraftCertificateName
      );
      let aircraftIdentifier: AircraftIdentifier = {
        aircraftCertificateName: aircraftStatusMsg.aircraftCertificateName,
        aircraftId: aircraftStatusMsg.aircraftId,
        aircraftName: aircraftStatusMsg.aircraftName,
        isSimulator: aircraftStatusMsg.isSimulator,
      };
      if (index > -1) {
        this.subscribeAircrafts([
          {
            aircraftIdentifier: aircraftIdentifier,
            state: PilotageStateEnum.Observing,
          } as AircraftPilotageStatus,
        ]);
        savedLocalStorage.observedAircrafts.splice(index, 1);
      } else {
        index = savedLocalStorage.controlledAircrafts.indexOf(
          aircraftStatusMsg.aircraftCertificateName
        );
        if (index > -1) {
          // if somebody else is not piloting my old plane:
          if (
            !(
              aircraftStatusMsg.pilotUserHealth?.healthStatus ===
                AircraftHealthEnum.Healthy &&
              aircraftStatusMsg.gcsController.userCode !==
                this.userCredentials.userCode
            )
          ) {
            this.subscribeAircrafts([
              {
                aircraftIdentifier: aircraftIdentifier,
                state: PilotageStateEnum.Controlling,
              } as AircraftPilotageStatus,
            ]);
          }
          savedLocalStorage.controlledAircrafts.splice(index, 1);
        }
      }

      if (
        index > -1 &&
        savedLocalStorage.activeAircraftId === aircraftStatusMsg.aircraftId
      ) {
        this.flightData.changeActiveAircraft(aircraftStatusMsg.aircraftId);
      }
      if (
        savedLocalStorage.controlledAircrafts.length === 0 &&
        savedLocalStorage.observedAircrafts.length === 0
      ) {
        this.iotService.unsubscribe(id);
      }
    });

    setTimeout(() => {
      this.iotService.unsubscribe(id);
    }, 5000);
  };

  finalize = () => {
    this.flightData.finalize();
  };

  executeAircraftCommandWithData(
    aircraftId: number,
    commandType: CommandTypeEnum,
    data: AircraftCommandData
  ): void {
    const aircraft = this.flightData.getAircraftById(aircraftId);
    aircraft?.executeCommandWithData(commandType, data);
  }

  isControllingAircraft(aircraftId: number): boolean {
    const aircraft = this.flightData.getAircraftById(aircraftId);
    return aircraft?.isControlling() ?? false;
  }

  executeCommandOnActiveAircraftWithData(
    commandType: CommandTypeEnum,
    data: AircraftCommandData
  ): void {
    this.flightData.executeCommandWithData(commandType, data);
  }

  getPreviousFlightData(): SavedLocalStorage | null {
    return this.flightData.getPreviousFlightData();
  }

  getLastAircraftTelemetry(aircraftId: number): AircraftTelemetry | null {
    return this.flightData.getLastAircraftTelemetry(aircraftId);
  }

  getLastPluginDataAnalysis(aircraftId: number): IPluginDataAnalysis | null {
    return this.flightData.getLastPluginDataAnalysis(aircraftId);
  }

  getLastAircraftLocation(aircraftId: number): AircraftLocation | null {
    return this.flightData.getLastAircraftLocation(aircraftId);
  }
}
