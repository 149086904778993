import {IFlightEngineForPluginApi} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {ITelemetryApi} from "@qandq/cloud-gcs-core";
import {AircraftTelemetry} from "@qandq/cloud-gcs-core";

export class TelemetryApi implements ITelemetryApi {
    private flightEngine: IFlightEngineForPluginApi;

    constructor(flightEngine: IFlightEngineForPluginApi) {
        this.flightEngine = flightEngine;
    }

    getTelemetry(aircraftId: number): AircraftTelemetry | null {
        return this.flightEngine.getLastAircraftTelemetry(aircraftId);
    }
}
