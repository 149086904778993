import {publishEvent} from "../../notification-locators/PubSubService";
import {getFlightAck} from "../flight-engine/models/broker-models/AircraftCommandTopicMessage";
import {ServiceEvent} from "./events/ServiceEvent";
import {UserType} from "../flight-engine/models/user-models/UserType";
import {HandOverInit} from "../iot/iot-service/models/HandOverInit";
import {HandOverResponse} from "../iot/iot-service/models/HandOverResponse";
import {UserInfo} from "../iot/iot-service/models/UserInfo";
import {TopicName} from "../flight-engine/models/broker-models/TopicName";
import {UserRequestMessage} from "../flight-engine/models/broker-models/UserRequestMessage";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {
    AircraftIdentifier,
    AircraftPilotageStatus,
    CommandTypeEnum,
    HandOverResponseData,
} from "@qandq/cloud-gcs-core";
import {IService} from "./interfaces/IService";
import {IInterPilotService} from "./interfaces/IInterPilotService";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";
import { HandOverCommandType, PilotageStateEnum } from "@qandq/cloud-gcs-core";

export class InterPilotService implements IService, IInterPilotService {
    public flightEngineApi: IFlightEngineApi
    private iotService: IIoTService;
    private interval: any;

    private controlStationId: string = ''
    private interPilotListenerId: string = ''


    constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService) {
        this.flightEngineApi = flightEngineApi;
        this.iotService = iotService;
    }

    start(): void {
        this.interPilotListenerId = this.iotService.subscribeInterPilotListener(data => {
            const message: UserRequestMessage = data.value
            if (message.commandType === CommandTypeEnum.HandOverInit) {
                this.sendAck(message)
                publishEvent(ServiceEvent.ReceivedHandOverCommand, message as HandOverInit)
            } else if (message.commandType === CommandTypeEnum.HandOverResponse) {
                this.sendAck(message)
                this.onReceiveHandOverResponse(message as HandOverResponse)
            } else if(message.commandType === CommandTypeEnum.HandOverMessage) {
                publishEvent(ServiceEvent.ReceivedHandOverPilotMessage, message)
            }
        })
    }

    stop(): void {
        this.iotService.unsubscribe(this.controlStationId)
        this.iotService.unsubscribe(this.interPilotListenerId)
        clearInterval(this.interval)
    }

    handOver(aircraftId: number | undefined, toUserCode: string): void {
        if (toUserCode === this.flightEngineApi.getUserInfo().userCode)
            return

        if (this.flightEngineApi.getActiveAircraftPilotUserCode() === toUserCode) {
            this.requestHandOver(aircraftId);
        } else if (this.flightEngineApi.isActiveAircraftBeingControlled()) {
            this.offerHandOver(aircraftId, toUserCode);
        }
    }

    // request the control of the aircraft from the pilot of the aircraft
    requestHandOver(aircraftId: number | undefined): void {
        const pilotCode = this.flightEngineApi.getActiveAircraftPilotUserCode();
        if (aircraftId && pilotCode) {
            this.sendHandOverInit(pilotCode, aircraftId, HandOverCommandType.Request)
        }
    }

    public sendHandOverResponse = async (answer: boolean, handOverResponseData: HandOverResponseData) => {

        if (answer) {
            this.onHandOverCommandAccepted(handOverResponseData)
        }

        const aircraftId = handOverResponseData.aircraftId
        // @ts-ignore
        const response: HandOverResponse = {
            data: {
                answer: answer,
                handOverCommandType: handOverResponseData.data.handOverCommandType,
            },
            ...this.getHandOverMessageDefaults(aircraftId),
            commandType: CommandTypeEnum.HandOverResponse
        }

        await this.iotService.publishHandOverResponse(handOverResponseData.userCode, response)
    }

    // initiate a protocol to transfer the control of the current active aircraft this user is currently piloting
    // to another user
    offerHandOver(aircraftId: number | undefined, toUserCode: string): void {
        if (aircraftId && this.flightEngineApi.isActiveAircraftBeingControlled()) {
            this.sendHandOverInit(toUserCode, aircraftId, HandOverCommandType.Offer)
        }
    }

    private sendAck = (message: any) => {
        if (message.requestId) { // send a response acknowledging that the request has been received:
            if (message.aircraftId && message.flightId !== undefined) {
                const ack = getFlightAck(TopicName.InterPilot, message)
                this.iotService.publishHandOverAck(message.userCode, ack);
            } // implement else part if needed in the future
        }
    }

    sendPilotMessage = (toUserCode: string, data: any) => {
        const request: HandOverInit = {
            commandType: CommandTypeEnum.HandOverMessage,
            data: data
        } as any

        this.iotService.publishHandOverInit(toUserCode, request)
    }


    private sendHandOverInit = async (toUserCode: string, aircraftId: number, handOverCommandType: HandOverCommandType) => {
        const message = this.getHandOverMessageDefaults(aircraftId);
        // @ts-ignore
        const request: HandOverInit = {
            ...message,
            commandType: CommandTypeEnum.HandOverInit,
            data: {
                handOverCommandType: handOverCommandType,
            }
        }

        await this.iotService.publishHandOverInit(toUserCode, request)
    }

    private onReceiveHandOverResponse = (response: HandOverResponse) => {
        publishEvent(ServiceEvent.ReceivedHandOverResponse, response)
        if (response.data.answer) {
            if (response.data.handOverCommandType === HandOverCommandType.Offer) {
                if (this.flightEngineApi.isControllingAircraft(response.aircraftId)) {
                    this.flightEngineApi.executeAircraftCommandWithData(response.aircraftId, CommandTypeEnum.HandOver, {
                        toUserId: response.userCode,
                    });
                }
            } else if (
                response.data.handOverCommandType === HandOverCommandType.Request
            ) {
                const aircraftIdentifier: AircraftIdentifier = {
                    aircraftCertificateName: response.aircraftCertificateName,
                    aircraftId: response.aircraftId,
                    aircraftName: response.aircraftName,
                    isSimulator: response.isSimulator,
                };
                this.flightEngineApi.subscribeAircrafts([
                    {
                        aircraftIdentifier: aircraftIdentifier,
                        state: PilotageStateEnum.Observing
                    } as AircraftPilotageStatus
                ]);
            }
        } else {
            publishEvent(ServiceEvent.HandOverCommandRejected, null);
        }
    }

    private getHandOverMessageDefaults(aircraftId: number) {
        return {
            ...this.flightEngineApi.getFlightIdentifier(aircraftId),
            topicName: TopicName.InterPilot,
        }
    }


    private onHandOverCommandAccepted = (handOverResponseData: HandOverResponseData) => {
        const aircraftIdentifier: AircraftIdentifier = {
            aircraftCertificateName: handOverResponseData.aircraftCertificateName,
            aircraftId: handOverResponseData.aircraftId,
            aircraftName: handOverResponseData.aircraftName,
            isSimulator: handOverResponseData.isSimulator,
        };
        if (handOverResponseData.data.handOverCommandType === HandOverCommandType.Offer) {
            this.flightEngineApi.subscribeAircrafts([
                {aircraftIdentifier, state: PilotageStateEnum.Observing} as AircraftPilotageStatus
            ]);
        } else if (handOverResponseData.data.handOverCommandType === HandOverCommandType.Request) {
            if (this.flightEngineApi.isControllingAircraft(handOverResponseData.aircraftId)) {
                this.flightEngineApi.executeAircraftCommandWithData(handOverResponseData.aircraftId, CommandTypeEnum.HandOver, {
                    toUserId: handOverResponseData.userCode,
                });
            }
        }
    };
}
