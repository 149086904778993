import { DraggableModal } from "ant-design-draggable-modal";
import { notification } from "antd";
import { FC, useEffect } from "react";
import { PluginOutputDto } from "../../model/IPlugin";

interface ILayoutProps {
  open: boolean;
  plugin: PluginOutputDto;
  pluginHelper: any;
  onCancel: () => void;
  isDev?: boolean;
}

const PluginTestComponent: FC<ILayoutProps> = ({
  open,
  plugin,
  pluginHelper,
  onCancel,
  isDev = false,
}) => {
  const loadPlugin = async (pluginData: PluginOutputDto) => {
    var creator = pluginData.jsFileUrl as any;
    if (isDev !== true) {
      eval(plugin.jsFileUrl);
      creator = eval(window[pluginData.name as any] as any);
    }

    if (creator && creator.default) {
      const a = pluginHelper!.createPluginApi(
        document.getElementById(plugin.id.toString() as string) ??
          document.createElement("div"),
        pluginData
      );

      const tempCreator = new creator.default(a);
      tempCreator.init();
      notification.success({
        message: `${pluginData.name} plugin loaded successfully`,
      });
    } else {
      notification.error({
        message: `${pluginData.name} plugin could not be loaded`,
      });
    }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        loadPlugin(plugin);
      }, 1000);
    }
  }, [open]);

  return (
    <DraggableModal
      open={plugin.isBackgroundPlugin ? false : open}
      footer={null}
      title={plugin.name}
      onCancel={onCancel}
      initialWidth={400}
      initialHeight={400}
    >
      <div id={plugin.id.toString()}>{/* Modal content goes here */}</div>
    </DraggableModal>
  );
};

export default PluginTestComponent;
