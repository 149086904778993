import {EventForUI} from "./events/EventForUI";
import {publishEvent, removeEvent, subscribeEvent} from "./PubSubService";
import {FlightEngineEvents} from "../ps-infra/flight-engine/FlightEngineEvents";

export class FlightEngineNotification {

    private dataChangedToUi = new Map<FlightEngineEvents, EventForUI>([
            [FlightEngineEvents.AircraftPilotChanged, EventForUI.AircraftPilotChanged],
            [FlightEngineEvents.ActiveAircraftPilotageStateChanged, EventForUI.ActiveAircraftPilotageStateChanged],
            [FlightEngineEvents.AircraftPilotageStateChanged, EventForUI.AircraftPilotageStateChanged],
            [FlightEngineEvents.ActiveAircraftChanged, EventForUI.ActiveAircraftChanged],
            [FlightEngineEvents.ActiveAircraftChanged2, EventForUI.ActiveAircraftChanged2],
            [FlightEngineEvents.ManageAircraftsEvent, EventForUI.ManageAircraftsEvent],
            [FlightEngineEvents.MissionStarted, EventForUI.MissionStarted],
            [FlightEngineEvents.MissionReceived, EventForUI.MissionReceived],
            [FlightEngineEvents.TelemetryDataChanged, EventForUI.TelemetryDataChanged],
            [FlightEngineEvents.AircraftLocationChanged, EventForUI.AircraftLocationChanged],
            [FlightEngineEvents.AircraftRemoved, EventForUI.AircraftRemoved],
        ]
    )

    constructor() {
        this.dataChangedToUi.forEach((e, s) => {
            subscribeEvent(s, this.getEventCallBack(e))
        })
    }

    getEventCallBack = (eventForUI: EventForUI) => (args: any[]) => {
        publishEvent(eventForUI, ...args)
    };

    finalize() {
        this.dataChangedToUi.forEach((e, s) => {
            removeEvent(s, this.getEventCallBack(e))
        })
    }
}
