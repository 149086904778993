import { AircraftStatusTopicMessage, GCSControllerModel, } from "../broker-models/AircraftStatusTopicMessage";
import { publishServiceMessageLog } from "../helper-models/MessageLoggerHelper";
import { publishEvent } from "../../../../notification-locators/PubSubService";
import { AircraftCommandData, AircraftCommandTopicMessage, } from "../broker-models/AircraftCommandTopicMessage";
import { FlightEngineEvents } from "../../FlightEngineEvents";
import { AircraftConfiguration, AircraftIdentifier, AircraftTelemetry, LogLevel, } from "@qandq/cloud-gcs-core";
import { ProcessingFunctions } from "./ProcessingFunctions";
import { MessageAircraftLog } from "../MessageAircraftLog";
import { IIoTService } from "../../../iot/iot-service/IIoTService";
import { AircraftMissionData } from "../../../iot/iot-service/models/AircraftMissionData";
import { CommandTypeEnum } from "@qandq/cloud-gcs-core";
import { FlightIdentifier } from "@qandq/cloud-gcs-core";
import {
  AircraftHealthEnum,
  AircraftLocation,
  AircraftPlugin,
  IPluginDataAnalysis,
  PilotageStatus,
  User,
} from "@qandq/cloud-gcs-core";

const connectionTimeout = 5000; //msecs

export class AircraftModel {
  private iotService: IIoTService;
  userCredentials: User;
  aircraftIdentifier: AircraftIdentifier;
  private _flightId = "";

  private aircraftPlugins: AircraftPlugin[] = [];

  //TODO MODELİ EKLENECEK
  aircraftMission: AircraftMissionData | undefined;
  aircraftParameters: AircraftConfiguration | null;
  private lastTelemetryMessage: AircraftTelemetry | undefined;
  private lastPluginAnalysisData: IPluginDataAnalysis | undefined;
  private lastAircraftStatusMessage: AircraftStatusTopicMessage | undefined;

  private pilot: GCSControllerModel | null;
  private _aircraftHealth: AircraftHealthEnum | null;
  private pilotageStatus: PilotageStatus;

  oldHealth: AircraftHealthEnum | null;

  private connectionAliveCheck: any | null;

  private subscriptionIdList: string[] = [];

  public userAircraftMessageDefaults = {
    aircraftCertificateName: "",
    aircraftName: "",
    aircraftId: 0,
    flightId: "",
    isSimulator: true,
  };

  get flightId(): string {
    return this._flightId;
  }

  set flightId(value: string) {
    this._flightId = value;
    this.userAircraftMessageDefaults.flightId = value;
  }

  constructor(
    identifier: AircraftIdentifier,
    user: User,
    iotService: IIoTService
  ) {
    this.iotService = iotService;
    this.userCredentials = user;
    this.aircraftIdentifier = identifier;
    this.pilotageStatus = PilotageStatus.None;
    this.setPilotageStatus(PilotageStatus.None);
    this.pilot = null;
    this._aircraftHealth = null;
    this.connectionAliveCheck = null;
    this.oldHealth = null;
    this.aircraftParameters = null;

    this.userAircraftMessageDefaults.aircraftCertificateName =
      identifier.aircraftCertificateName;
    this.userAircraftMessageDefaults.aircraftName = identifier.aircraftName;
    this.userAircraftMessageDefaults.aircraftId = identifier.aircraftId;
  }

  getFlightIdentifier(): FlightIdentifier {
    return {
      ...this.aircraftIdentifier,
      flightId: this.flightId,
    };
  }

  setAircraftHealth = (aircraftHealth: AircraftHealthEnum | null) => {
    if (this._aircraftHealth !== aircraftHealth) {
      this.oldHealth = this._aircraftHealth;
      this._aircraftHealth = aircraftHealth;
      publishEvent(
        FlightEngineEvents.AircraftHealthChanged,
        this.getFlightIdentifier(),
        this.oldHealth,
        this._aircraftHealth,
        this.lastAircraftStatusMessage?.healthStatus
      );
    }
  };

  setPilot(pilot: GCSControllerModel | null) {
    if (this.pilot?.userCode !== pilot?.userCode) {
      let oldPilot = this.pilot;
      this.pilot = pilot;
      publishEvent(
        FlightEngineEvents.AircraftPilotChanged,
        this.getFlightIdentifier(),
        oldPilot,
        this.pilot
      );
    }
  }

  getPilot(): GCSControllerModel | null {
    return this.pilot;
  }

  setPilotageStatus(pilotageStatus: PilotageStatus) {
    if (pilotageStatus !== this.pilotageStatus) {
      const oldStatus = this.pilotageStatus;
      this.pilotageStatus = pilotageStatus;
      publishEvent(
        FlightEngineEvents.AircraftPilotageStateChanged,
        this.getFlightIdentifier(),
        oldStatus,
        this.pilotageStatus
      );
    }
  }

  private receivedMission = (mission: AircraftMissionData) => {
    if (this.pilotageStatus === PilotageStatus.None) {
      if (!this.aircraftMission && this.aircraftParameters) {
        this.setPilotageStatus(PilotageStatus.Observing);
      }
    }
    this.aircraftMission = mission;
    publishEvent(
      FlightEngineEvents.MissionReceived,
      this.aircraftIdentifier,
      this.aircraftMission
    );
  };

  private receivedParameters = (parameters: any) => {
    if (this.pilotageStatus === PilotageStatus.None) {
      if (!this.aircraftParameters && this.aircraftMission) {
        this.setPilotageStatus(PilotageStatus.Observing);
      }
    }
    // console.log("parameters: ", parameters);
    this.aircraftParameters = parameters;
  };

  private receivedStatus = (
    aircraftStatusTopicMessage: AircraftStatusTopicMessage
  ) => {
    if (
      aircraftStatusTopicMessage.gcsController.userCode ===
      this.userCredentials.userCode
    ) {
      this.setPilotageStatus(PilotageStatus.Controlling);
    } else {
      this.setPilotageStatus(PilotageStatus.Observing);
    }

    this.setPilot(aircraftStatusTopicMessage.gcsController);
    this.flightId = aircraftStatusTopicMessage.flightId;

    let pilotUserHealthStatus = aircraftStatusTopicMessage.pilotUserHealth;
    if (pilotUserHealthStatus !== undefined) {
      this.setAircraftHealth(pilotUserHealthStatus.healthStatus);
    } else {
      this.setAircraftHealth(AircraftHealthEnum.Other);
    }
    if (this.connectionAliveCheck) clearTimeout(this.connectionAliveCheck);
    this.connectionAliveCheck = setTimeout(
      this.setHealthToDownLinkBroken,
      connectionTimeout
    );
  };

  isObserving = () => {
    return (
      this.pilotageStatus === PilotageStatus.Observing ||
      this.pilotageStatus === PilotageStatus.Controlling
    );
  };

  isObservingButNotControlling = () => {
    return this.pilotageStatus === PilotageStatus.Observing;
  };

  isControlling = () => {
    return this.pilotageStatus === PilotageStatus.Controlling;
  };

  executeCommand = (commandText: CommandTypeEnum) => {
    this.executeCommandWithData(commandText, {});
  };

  executeCommandWithData = (
    command: CommandTypeEnum,
    data: AircraftCommandData
  ) => {
    this.executeCommandTypeWithData(command, data);
  };

  executeCommandType = (commandType: CommandTypeEnum) => {
    this.executeCommandTypeWithData(commandType, {});
  };

  executeCommandTypeWithData = (
    commandType: CommandTypeEnum,
    data: AircraftCommandData
  ) => {
    this.publishCommand({
      ...this.userAircraftMessageDefaults,
      commandType: commandType,
      data: data,
    });
  };

  publishCommand = (command: AircraftCommandTopicMessage) => {
    if (!this.userCredentials.isPilot) return;

    if (
      this.aircraftIdentifier.aircraftCertificateName ===
      command.aircraftCertificateName
    ) {
      this.iotService.publishAircraftCommand(
        this.aircraftIdentifier.aircraftCertificateName,
        command
      );
    }
  };

  requestClaim = () => {
    if (!this.userCredentials.isPilot) return;
    this.executeCommand(CommandTypeEnum.Claim);
  };

  subscribePluginDataAnalysis = () => {
    this.iotService.subscribePluginDataAnalysis(
      this.aircraftIdentifier.aircraftCertificateName,
      (data) => {
        this.lastPluginAnalysisData = data.value;
      }
    );
  };

  //todo: ask why small letter for type, type is like a adjective, naming should respect that
  startObserving = (processor: ProcessingFunctions) => {
    const certificateName = this.aircraftIdentifier.aircraftCertificateName;

    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftResponse(certificateName, (data) => { })
    );

    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftTelemetry(certificateName, (data) => {
        if (this.isObserving()) {
          this.lastTelemetryMessage = data.value;
          publishEvent(
            FlightEngineEvents.TelemetryDataChanged,
            this.getLastAircraftTelemetry(),
            this.aircraftIdentifier
          );
          publishEvent(
            FlightEngineEvents.AircraftLocationChanged,
            this.aircraftIdentifier,
            this.getLastAircraftLocation()
          );
        }
      })
    );
    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftMission(certificateName, (data) => {
        const log = new MessageAircraftLog(
          "System",
          "Mission received",
          LogLevel.Info,
          `${JSON.stringify(
            data.value.mission.waypoints.map((x) => ({
              command: x.command,
              coordinates: x.coordinates,
            }))
          )}`,
          {},
          this.aircraftIdentifier.aircraftName
        );

        publishServiceMessageLog(log);

        this.receivedMission(data.value);
        setTimeout(() => {
          processor.processMission(data);
        }, 1000);
      })
    );

    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftParameters(certificateName, (data) => {
        const log = new MessageAircraftLog(
          "System",
          "Parameters received",
          LogLevel.Info,
          `For aircraft ${this.aircraftIdentifier.aircraftName
          }, parameters ${JSON.stringify(data.value)} received`,
          {},
          this.aircraftIdentifier.aircraftName
        );

        publishServiceMessageLog(log);

        this.receivedParameters(data.value);
        processor.processParameters(data);
      })
    );

    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftPlugins(certificateName, (data) => {
        this.aircraftPlugins = data.value.plugins;
      })
    );

    this.subscriptionIdList.push(
      this.iotService.subscribeAircraftStatus(certificateName, (data) => {
        let aircraftStatusTopicMessage = new AircraftStatusTopicMessage();
        aircraftStatusTopicMessage.load(data);

        this.lastAircraftStatusMessage = aircraftStatusTopicMessage;
        this.receivedStatus(aircraftStatusTopicMessage);
        processor.processStatus(aircraftStatusTopicMessage);
      })
    );

    this.connectionAliveCheck = setTimeout(
      this.setHealthToDownLinkBroken,
      connectionTimeout
    );
  };

  getLastAircraftLocation = (): AircraftLocation => {
    const data = this.getLastAircraftTelemetry();

    const coordinates: AircraftLocation = {
      latitude: data?.gpsInfo?.coordinates.latitude,
      longitude: data?.gpsInfo?.coordinates.longitude,
      altitude: data?.gpsInfo?.coordinates.altitude,
      yaw: data?.headingInfo.yaw,
      roll: data?.attitudeInfo.roll,
      pitch: data?.attitudeInfo.pitch,
    };

    return coordinates;
  };
  getLastPluginDataAnalysis = (): IPluginDataAnalysis | null => {
    return this.lastPluginAnalysisData ?? null;
  };

  getLastAircraftTelemetry = (): AircraftTelemetry | null => {
    return this.lastTelemetryMessage ?? null;
  };

  getAircraftPlugins = (): AircraftPlugin[] => {
    return this.aircraftPlugins;
  };

  private setHealthToDownLinkBroken = () => {
    this.setAircraftHealth(AircraftHealthEnum.DownLinkBroken);
  };

  get aircraftHealth(): AircraftHealthEnum | null {
    return this._aircraftHealth;
  }

  unregister() {
    if (this.connectionAliveCheck) clearTimeout(this.connectionAliveCheck);

    this.subscriptionIdList.forEach((x) => this.iotService.unsubscribe(x));
    this.subscriptionIdList = [];

    if (this.isControlling()) this.executeCommand(CommandTypeEnum.Logout);
  }

  setFlightId(flightId: string) {
    this.flightId = flightId;
  }
}
