import { subscribeEvent } from "../../notification-locators/PubSubService";
import { EventForUI } from "../../notification-locators/events/EventForUI";
import {
  AircraftIdentifier,
  FlightIdentifier,
  LogLevel,
} from "@qandq/cloud-gcs-core";
import { MessageAircraftLog } from "../flight-engine/models/MessageAircraftLog";
import { IFlightEngineApi } from "../flight-engine/models/manager-models/IFlightEngineApi";
import { UserCredentials } from "../flight-engine/models/user-models/UserCredentials";
import { IIoTService } from "../iot/iot-service/IIoTService";
import { FlightLog } from "./models/FlightLog";
import { IFlightLogService } from "./models/IFlightLogService";

export class FlightLogService implements IFlightLogService {
  private static iotService: IIoTService;
  private static userCredentials: UserCredentials;
  private static flightEngine: IFlightEngineApi;

  constructor() {
    subscribeEvent(EventForUI.InsertSummaryLog, this.onInsertedSummaryLog);
  }

  public static init(
    flightEngine: IFlightEngineApi,
    iotService: IIoTService,
    userCredentials: UserCredentials
  ) {
    FlightLogService.flightEngine = flightEngine;
    FlightLogService.iotService = iotService;
    FlightLogService.userCredentials = userCredentials;
  }

  public static async sendFlightLog({
    data,
    level,
    message,
    details,
    source,
    aircraftIdentifier,
    flightIdentifier,
  }: {
    data: object;
    level: LogLevel;
    message: string;
    details: string;
    source?: string;
    aircraftIdentifier?: AircraftIdentifier;
    flightIdentifier?: FlightIdentifier;
  }): Promise<void> {
    return await new Promise((resolve, reject) => {
      const intervalTimeOut = 30000;
      let intervalTime = 0;
      const interval = setInterval(() => {
        const log = new FlightLog({
          flightIdentifier: flightIdentifier
            ? flightIdentifier
            : this.flightEngine.getActiveAircraftFlightIdentifier()!,
          aircraftIdentifier:
            aircraftIdentifier ??
            this.flightEngine.getActiveAircraftIdentifier()!,
          userCredentials: this.userCredentials,
          message: message,
          details: details,
          logLevel: level,
          data: data,
          source: source,
          topicName: "flightlog",
        });

        if (log.flightId) {
          clearInterval(interval);
          resolve(this.iotService.publishFlightLog(log));
        }
        if (intervalTime >= intervalTimeOut) {
          clearInterval(interval);
          reject(`FlightLogService can't find!`);
        }
        intervalTime += 200;
      }, 200);
    });
  }

  public onInsertedSummaryLog = (data: MessageAircraftLog[]) => {
    const log: MessageAircraftLog = data[0];

    FlightLogService.sendFlightLog({
      data: log.data,
      level: log.level,
      message: log.message,
      details: log.details,
      source: log.source,
    });
  };
}
