import React, { createContext, useContext } from "react";
import { PSInfraProvider } from "./PSInfraContext";
import { LayoutProvider } from "./LayoutContext";
import { PluginProvider } from "./PluginContext";

export type ContainerContextProps = {};

let ContainerContext = createContext<any>({});

function ContainerProvider({ children }: { children: React.ReactNode }) {
  let value: ContainerContextProps = {};

  return (
    <ContainerContext.Provider value={value}>
      <PluginProvider>
        <LayoutProvider>
          <PSInfraProvider>{children}</PSInfraProvider>
        </LayoutProvider>
      </PluginProvider>
    </ContainerContext.Provider>
  );
}

const useContainer = (): ContainerContextProps => {
  return useContext<ContainerContextProps>(ContainerContext);
};

export { useContainer, ContainerProvider };
