import { Space, Tooltip } from "antd";
import React, { FC } from "react";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { HiOutlinePuzzle } from "react-icons/hi";
import { BsShieldLock } from "react-icons/bs";
import { ClientType } from "@qandq/cloud-gcs-core";
interface Props {
  tenantTypes: Array<ClientType>;
  color?: string;
}

const TenantTypes: FC<Props> = ({ tenantTypes, color = "black" }) => {
  const getTenantTypeIcon = (tenantType: ClientType) => {
    switch (tenantType) {
      case ClientType.Host:
        return (
          <Tooltip key={tenantType} title="Host">
            <AiOutlineHome color={color} size={18} />
          </Tooltip>
        );
      case ClientType.Customer:
        return (
          <Tooltip key={tenantType} title="Customer">
            <AiOutlineUser color={color} size={18} />
          </Tooltip>
        );
      case ClientType.ServiceProvider:
        return (
          <Tooltip key={tenantType} title="Services Provider">
            <HiOutlinePuzzle color={color} size={18} />
          </Tooltip>
        );
      case ClientType.Manufacturer:
        return (
          <Tooltip key={tenantType} title="Manufacturer">
            <BsShieldLock color={color} size={18} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip key={tenantType} title="HostTenant">
            <AiOutlineHome color={color} size={18} />
          </Tooltip>
        );
    }
  };

  return (
    <Space
      direction="horizontal"
      style={{
        marginTop: "8px",
        marginBottom: "8px",
        justifyContent: "center",
      }}
    >
      {tenantTypes?.map(getTenantTypeIcon)}
    </Space>
  );
};

export default TenantTypes;
