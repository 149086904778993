
export const isLocalDevelopment = () : boolean => {
  if (!process.env.REACT_APP_NODE_ENV)
    return true;
  
  const appEnv = process.env.REACT_APP_NODE_ENV.toLowerCase();

  if (appEnv === "local")
    return true;

  if (appEnv === "development" || appEnv === "staging" || appEnv === "production")
    return false;

  return true;
}

export const isDevBuild = () : boolean => {
  return process.env.NODE_ENV === "development";
}

export const isAppRunningInBox = () : boolean => {
  return (process.env.REACT_APP_IS_IN_BOX && process.env.REACT_APP_IS_IN_BOX === "true") ? true : false;
}

export const getBugsnagReleaseStage = () : string => {
  if (isLocalDevelopment() || isDevBuild()) {
    return "none";
  }
  const isInBox = isAppRunningInBox();
  if (isInBox) {
    return "box";
  }

  const appEnv = process.env.REACT_APP_NODE_ENV!.toLowerCase();
  if (appEnv === "development" || appEnv === "staging" || appEnv === "production")
    return appEnv;

  
  return "none";
};

export const getBugsnagApiKey = () : string => {
  if (getBugsnagReleaseStage() === 'none'){
    return "localhost";
  }
  return "806cfa0baaeada87a57b363e19803467";
}

export const getAppEnv = () : string => {
  if (!process.env.REACT_APP_NODE_ENV)
    return "Local";
  return process.env.REACT_APP_NODE_ENV;
}

export const getEMQXUrl = () : string => {

  const isInBox = isAppRunningInBox();
  if (isInBox) {
    return "ws://" + window.location.hostname + ":8083/mqtt";
  }

  return process.env.REACT_APP_IOT_URL || "";
}

export const shouldDetectPlugin = () => {
  return (
    (process.env.REACT_APP_DETECT_PLUGIN && process.env.REACT_APP_DETECT_PLUGIN === "true") && isDevBuild()
  );
};