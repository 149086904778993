import { isAppRunningInBox } from "../../utils/environmentHelper";
import http from "../../utils/request";
// import { layoutJson } from "../context/layout";
import { LayoutType } from "../model/LayoutType";
import { getBoxLayout } from "./frappe/frappeService";

export async function getLayout(layoutType: LayoutType): Promise<string> {
  const data = await http.get<null, string>("/layouts", {
    params: {
      layoutType,
    },
  });
  return data;
}

export async function getGrantedLayout(isMobile: boolean): Promise<string> {
  const isInBox = isAppRunningInBox();
  if (isInBox) {
    // mock data
    // return await layoutJson; 
    return await getBoxLayout(isMobile);
  }
  
  const data = await http.get<null, string>("/layouts/getGrantedLayout", {
    params: {
      isMobile,
    },
  });
  return data;
}
