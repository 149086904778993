import React from "react";
import "./index.scss";
import { Alert } from "antd";

const PortraitModeModal = () => {
  return (
    <Alert
      message=" Portrait Mode Detected"
      description="  Please rotate your device."
      type="error"
      className="portraitModeModal"
      style={{zIndex: 9999,top:250}}
    />
    
  );
};

export default PortraitModeModal;
