import {IPluginEventManager} from "@qandq/cloud-gcs-core";
import {PluginEventCallback} from "@qandq/cloud-gcs-core";
import {PluginEventArgs} from "@qandq/cloud-gcs-core";
import {ICallbackService} from "../ps-infra/services/interfaces/ICallbackService";
import {publishEvent, subscribeEvent} from "../notification-locators/PubSubService";
import {EventForUI} from "../notification-locators/events/EventForUI";
import {AircraftCommandResponse} from "../ps-infra/iot/iot-service/models/AircraftCommandResponse";
import {UIPluginCommand} from "@qandq/cloud-gcs-core";

export class PluginEventManager implements IPluginEventManager {
    private events: PluginEventCallback[];

    constructor() {
        this.events = [];
        this.onReceivedPluginCommand()
    }

    private getName(event: PluginEventCallback | PluginEventArgs): string {
        return `${event.pluginName}_${event.eventName}`
    }

    onReceivedPluginCommand(): void {
        subscribeEvent(EventForUI.ReceivedPluginCommand, (data: AircraftCommandResponse<UIPluginCommand>[]) => {
            const val = data[0]
            this.publish({
                pluginName: val.data.pluginName,
                args: [val],
                eventName: val.data.command
            })
        })
    }

    publish(eventPublishArgs: PluginEventArgs): void {
        const _events = this.events.filter(x => this.getName(x) === this.getName(eventPublishArgs))

        _events.forEach(x => {
            if (x && x.callback) {
                x.callback(eventPublishArgs.args);
            }
        })

    }

    remove(eventCallback: PluginEventCallback): void {
        this.events = this.events.filter(x => this.getName(x) !== this.getName(eventCallback));
    }

    subscribe(pluginEvent: PluginEventCallback): void {
        this.events.push(pluginEvent);
    }
}
