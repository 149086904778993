import {EventEmitter} from 'events';
import {EventForUI} from "./events/EventForUI";
import {FlightEngineEvents} from "../ps-infra/flight-engine/FlightEngineEvents";
import {ServiceEvent} from "../ps-infra/services/events/ServiceEvent";


type PubSubEvent = EventForUI | FlightEngineEvents | ServiceEvent

const eventEmitter = new EventEmitter();
eventEmitter.setMaxListeners(32000)

export function removeEvent(type: PubSubEvent, callback: (...args: any[]) => void) {
    eventEmitter.removeListener(type, callback);
}


export function subscribeEvent(type: PubSubEvent, callback: (...args: any[]) => void) {
    eventEmitter.addListener(type, callback);
}

export function publishEvent(type: PubSubEvent, ...args: any[]) {
    eventEmitter.emit(type, args);
}

export function finalizeEvents() {
    eventEmitter.removeAllListeners()
}
