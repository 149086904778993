export const getLogDetailsForCommand = (command: any, data: any): string => {  
  switch (command) {
    case 'UploadMission':
      return `${JSON.stringify(data.data.mission.waypoints.map((x:any) => ({ command: x.command, coordinates: x.coordinates })))}`;
      case 'Jump':
        const {index,mission} = data.data;
        const waypoint = mission[index]
        
        return `${JSON.stringify({ command: waypoint.command, coordinates: waypoint.coordinates})}`;
    default:
      return `Response received for request id# ${data.requestId}, for command ${data.commandType}`;
  }
}

export const getGeofenceDetails = (geofence: any) => {
  const points = geofence.points.map((x:any) => ({ latitude: x.latitude, longitude: x.longitude}))
  const { latitude: retLat, longitude: retLng } = geofence.returnPoint;

  return `${JSON.stringify({
      ...geofence, 
      returnPoint: { latitude: retLat, longitude: retLng}, 
      points}
  )}`;
}