import {IFlightEngineForPluginApi} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {
    IAircraftLocationApi
} from "@qandq/cloud-gcs-core";
import { AircraftLocation } from "@qandq/cloud-gcs-core";

export class AircraftLocationApi implements IAircraftLocationApi {
    private flightEngine: IFlightEngineForPluginApi;

    constructor(flightEngine: IFlightEngineForPluginApi) {
        this.flightEngine = flightEngine;
    }

    getAircraftLocation(aircraftId: number): AircraftLocation | null {
        return this.flightEngine.getLastAircraftLocation(aircraftId);
    }
}
