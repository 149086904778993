export interface PluginOutputDto {
  id: number;
  config: string;
  description: string | null;
  jsFileUrl: string;
  libraryName: string;
  name: string;
  status: PluginStatus;
  version: string;
  isBackgroundPlugin: boolean;
  isSystemPlugin: boolean;
  publisherOrganization: string | undefined;
}

export enum PluginStatus {
  Unpublished = 1,
  Published,
  Hidden,
}
