import {removeEvent, subscribeEvent} from "../../notification-locators/PubSubService";
import {GCSControllerModel,} from "../flight-engine/models/broker-models/AircraftStatusTopicMessage";
import {AckWithFlightData} from "../../utils/PubSubTimeOut";
import {PilotLogMessage, SourceType,} from "../flight-engine/models/broker-models/PilotLogTopicMessage";
import {getUTCDateTextNow} from "../../utils/DateHelper";
import {FlightEngineEvents} from "../flight-engine/FlightEngineEvents";
import {ServiceEvent} from "./events/ServiceEvent";
import { FlightIdentifier} from "@qandq/cloud-gcs-core";
import {LogLevel} from "@qandq/cloud-gcs-core";
import {HandOverInit} from "../iot/iot-service/models/HandOverInit";
import {HandOverResponse} from "../iot/iot-service/models/HandOverResponse";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {IService} from "./interfaces/IService";
import { AircraftHealthEnum, HandOverCommandType, PilotageStatus } from "@qandq/cloud-gcs-core";

export class PilotLogService implements IService {
    private static iotService: IIoTService | null;

    constructor(iotService: IIoTService) {
        PilotLogService.iotService = iotService;
    }

    start(): void {
        subscribeEvent(
            FlightEngineEvents.AircraftPilotageStateChanged,
            this.onAircraftPilotageStateChanged
        );
        subscribeEvent(
            FlightEngineEvents.AircraftPilotChanged,
            this.onAircraftPilotChanged
        );
        subscribeEvent(
            FlightEngineEvents.AircraftHealthChanged,
            this.onAircraftHealthChanged
        );
        subscribeEvent(
            ServiceEvent.ResponseReceivedStatus,
            this.onResponseReceived
        );
        subscribeEvent(
            ServiceEvent.ReceivedHandOverCommand,
            this.onReceiveHandOverRequest
        );
        subscribeEvent(
            ServiceEvent.ReceivedHandOverResponse,
            this.onReceiveHandOverResponse
        );
    }

    stop(): void {
        removeEvent(
            FlightEngineEvents.AircraftPilotageStateChanged,
            this.onAircraftPilotageStateChanged
        );
        removeEvent(
            FlightEngineEvents.AircraftPilotChanged,
            this.onAircraftPilotChanged
        );
        removeEvent(
            FlightEngineEvents.AircraftHealthChanged,
            this.onAircraftHealthChanged
        );
        removeEvent(ServiceEvent.ResponseReceivedStatus, this.onResponseReceived);
        removeEvent(
            ServiceEvent.ReceivedHandOverCommand,
            this.onReceiveHandOverRequest
        );
        removeEvent(
            ServiceEvent.ReceivedHandOverResponse,
            this.onReceiveHandOverResponse
        );
    }

    private static generatePilotLogMessage(
        flightIdentifier: FlightIdentifier,
        level: LogLevel,
        message: string
    ): PilotLogMessage {
        return {
            source: SourceType.PS,
            creationTimeUtc: getUTCDateTextNow(),
            ...flightIdentifier,
            level: level,
            message: message,
        };
    }

    static sendPilotLogMessage(
        flightIdentifier: FlightIdentifier,
        level: LogLevel,
        message: string
    ) {
        const log = PilotLogService.generatePilotLogMessage(
            flightIdentifier,
            level,
            message
        );

        PilotLogService.iotService?.publishPilotLog(log.aircraftName, log)
    }

    private onResponseReceived = (data: any[]) => {
        if (data[0].aircraftId !== undefined) {
            const ackWithFlightData: AckWithFlightData = data[0];
            const flightIdentifier: FlightIdentifier = {
                aircraftCertificateName: ackWithFlightData.aircraftCertificateName,
                aircraftId: ackWithFlightData.aircraftId,
                aircraftName: ackWithFlightData.aircraftName,
                flightId: ackWithFlightData.flightId,
                isSimulator: ackWithFlightData.isSimulator,
            };
            if (ackWithFlightData.ackReceived) {
                PilotLogService.sendPilotLogMessage(
                    flightIdentifier,
                    LogLevel.Info,
                    "Response received for request id#" +
                    ackWithFlightData.requestId +
                    ", for command " +
                    ackWithFlightData.commandType
                );
            } else {
                PilotLogService.sendPilotLogMessage(
                    flightIdentifier,
                    LogLevel.Warning,
                    "Response not received for request id#" +
                    ackWithFlightData.requestId +
                    ", for command " +
                    ackWithFlightData.commandType
                );
            }
        }
    };

    private onAircraftPilotChanged = (data: any[]) => {
        const flightIdentifier: FlightIdentifier = data[0];
        const oldPilot: GCSControllerModel = data[1];
        const newPilot: GCSControllerModel = data[2];

        if (newPilot.userCode !== null) {
            PilotLogService.sendPilotLogMessage(
                flightIdentifier,
                LogLevel.Info,
                "Controlled by " + newPilot.userCode
            );
        }
    };

    private onAircraftHealthChanged = (data: any[]) => {
        const flightIdentifier: FlightIdentifier = data[0];
        const oldHealth: AircraftHealthEnum = data[1];
        const newHealth: AircraftHealthEnum = data[2];

        const problems = [
            AircraftHealthEnum.Unhealthy,
            AircraftHealthEnum.DownLinkBroken,
            AircraftHealthEnum.Unattended,
        ];
        const isNewHealthProblematic = problems.indexOf(newHealth) > -1;
        const isOldHealthProblematic = problems.indexOf(oldHealth) > -1;

        if (isNewHealthProblematic) {
            PilotLogService.sendPilotLogMessage(
                flightIdentifier,
                LogLevel.Warning,
                "Status: " + AircraftHealthEnum[newHealth]
            );
        }
        if (isOldHealthProblematic && !isNewHealthProblematic) {
            PilotLogService.sendPilotLogMessage(
                flightIdentifier,
                LogLevel.Info,
                "Status: " +
                AircraftHealthEnum[oldHealth] +
                " -> " +
                AircraftHealthEnum[newHealth]
            );
        }
    };

    private onAircraftPilotageStateChanged = (data: any[]) => {
        const flightIdentifier: FlightIdentifier = data[0];
        const oldStatus = data[1];
        const newStatus = data[2];
        if (newStatus === PilotageStatus.Observing) {
            PilotLogService.sendPilotLogMessage(
                flightIdentifier,
                LogLevel.Info,
                "Started observing"
            );
        }
    };

    private onReceiveHandOverRequest = (data: any) => {
        const handOverInit: HandOverInit = data[0];
        const flightIdentifier: FlightIdentifier = {
            aircraftCertificateName: handOverInit.aircraftCertificateName,
            aircraftId: handOverInit.aircraftId,
            aircraftName: handOverInit.aircraftName,
            flightId: handOverInit.flightId,
            isSimulator: handOverInit.isSimulator,
        };
        switch (handOverInit.data.handOverCommandType) {
            case HandOverCommandType.Request: {
                PilotLogService.sendPilotLogMessage(
                    flightIdentifier,
                    LogLevel.Warning,
                    "Handover request received from " + handOverInit.userCode
                );
                break;
            }
            case HandOverCommandType.Offer: {
                PilotLogService.sendPilotLogMessage(
                    flightIdentifier,
                    LogLevel.Warning,
                    "Handover offer received from " + handOverInit.userCode
                );
                break;
            }
        }
    };

    private onReceiveHandOverResponse = (data: any) => {
        const response: HandOverResponse = data[0];
        const flightIdentifier: FlightIdentifier = {
            aircraftCertificateName: response.aircraftCertificateName,
            aircraftId: response.aircraftId,
            aircraftName: response.aircraftName,
            flightId: response.flightId,
            isSimulator: response.isSimulator,
        };

        PilotLogService.sendPilotLogMessage(
            flightIdentifier,
            LogLevel.Warning,
            "Handover response " +
            response.data.answer +
            " received from " +
            response.userCode
        );
    };
}
