import {HandOverResponseData, IHandOverApi} from "@qandq/cloud-gcs-core";
import {IInterPilotService} from "../../../ps-infra/services/interfaces/IInterPilotService";

export class HandOverApi implements IHandOverApi {
    private interPilotService: IInterPilotService;

    constructor(interPilotService: IInterPilotService) {
        this.interPilotService = interPilotService;
    }

    public handOver(aircraftId: number, toUserCode: string): void {
        this.interPilotService.handOver(aircraftId, toUserCode);
    }

    sendHandOverResponse(answer: boolean, handOverCommand: HandOverResponseData): void {
        this.interPilotService.sendHandOverResponse(answer, handOverCommand)
    }

    requestHandOver(aircraftId: number | undefined): void {
        this.interPilotService.requestHandOver(aircraftId)
    }

    sendPilotMessage(toUserCode: string, data: any): void {
        this.interPilotService.sendPilotMessage(toUserCode, data)
    }
}
