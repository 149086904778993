import {AircraftModel} from "./manager-models/AircraftModel";
import {IIoTService} from "../../iot/iot-service/IIoTService";
import {AircraftIdentifier} from "@qandq/cloud-gcs-core";
import { User } from "@qandq/cloud-gcs-core";

export default class AircraftFleet {
    private iotService: IIoTService;

    constructor(user: User, iotService: IIoTService) {
        this.iotService = iotService;
        this.userCredentials = user;
        this.aircrafts = [];
    }

    userCredentials: User;
    private aircrafts: AircraftModel[];

    any = (certificateName: string) => {
        const list = this.aircrafts.filter(x => x.aircraftIdentifier.aircraftCertificateName === certificateName);
        return list.length > 0;
    }

    hasAircraft = () => {
        return this.aircrafts.length > 0
    }

    getAllRegisteredAircraft = () => {
        return this.aircrafts.filter(x => x.isObservingButNotControlling() || x.isControlling()).map(x => x.aircraftIdentifier);
    }

    getListOfObservingAircrafts = () => {
        return this.aircrafts.filter(x => x.isObservingButNotControlling()).map(x => x.aircraftIdentifier);
    }

    getListOfControllingAircrafts = () => {
        return this.aircrafts.filter(x => x.isControlling()).map(x => x.aircraftIdentifier);
    }

    insert = (identifier: AircraftIdentifier) => {
        if (this.any(identifier.aircraftCertificateName))
            return false;

        const aircraft = new AircraftModel(identifier, this.userCredentials, this.iotService);
        this.aircrafts.push(aircraft);

        return true
    }

    remove = (certificateName: string) => {
        if (!this.any(certificateName))
            return false;

        this.aircrafts = this.aircrafts.filter(y => y.aircraftIdentifier.aircraftCertificateName !== certificateName);
        return true;
    }

    getAircraftByCertificateName = (certificateName: string) => {
        const aircraft = this.aircrafts.filter(x => x.aircraftIdentifier.aircraftCertificateName === certificateName)[0];
        if (aircraft === undefined)
            return null;

        return aircraft;
    }

    getAircraftById = (id: number) => {
        const aircraft = this.aircrafts.filter(x => x.aircraftIdentifier.aircraftId === id)[0];
        if (aircraft === undefined)
            return null;

        return aircraft;
    }

    getAircraftByIdentifier = (identifier: AircraftIdentifier) => {
        const aircraft = this.aircrafts.filter(x => x.aircraftIdentifier.aircraftId === identifier.aircraftId)[0];
        if (aircraft === undefined)
            return null;

        return aircraft;
    }

    finalize() {
        this.aircrafts.forEach(it => {
            it.unregister()
        })
        this.aircrafts = []
    }
}
