import {AirTextData, AirTextMessage} from "../flight-engine/models/broker-models/AirTextMessage";
import {IIoTService} from "../iot/iot-service/IIoTService";
import {IAirTextService} from "./interfaces/IAirTextService";
import {IService} from "./interfaces/IService";
import {IFlightEngineApi} from "../flight-engine/models/manager-models/IFlightEngineApi";

/**
 * Airtext posts are sent five times every second. (E.g., delay time window is 200msec)
 * Posts during a time window are buffered and sent altogether in the next iteration.
 * Only the last post will be registered in case multiple posts are issued within the same time window.
 */

const DELAY:number = 200 //in msecs, max 5 publish per sec per aircraft.

export class AirTextService implements IService, IAirTextService {

    private readonly aircraftToPlIdToData = new Map<number, Map<string, any>>()
    private readonly flightEngineApi: IFlightEngineApi
    private readonly iotService: IIoTService;
    private intervalId?: NodeJS.Timeout

    constructor(flightEngineApi: IFlightEngineApi, iotService: IIoTService) {
        this.flightEngineApi = flightEngineApi
        this.iotService = iotService;
    }

    start(): void {
        this.intervalId = setInterval(this.publish, DELAY)
    }

    stop(): void {
        if (this.intervalId)
            clearInterval(this.intervalId);
    }

    public postAirText(pluginId: string, aircraftId: number, data: any) {

        if (!this.aircraftToPlIdToData.has(aircraftId)) {
            this.aircraftToPlIdToData.set(aircraftId, new Map<string, any>())
        }

        this.aircraftToPlIdToData.get(aircraftId)!.set(pluginId, data)
    }

    private publish = () => {
        this.aircraftToPlIdToData.forEach((plIdToData, aircraftId) => {
            const dataList: AirTextData[] = []
            plIdToData.forEach((data, plId) => {
                dataList.push({
                    pluginId: plId,
                    data: data,
                })
            })
            const flightIdentifier = this.flightEngineApi.getFlightIdentifier(aircraftId)
            if (flightIdentifier) {
                const ptm: AirTextMessage = {
                    ...flightIdentifier,
                    dataList: dataList
                }
                this.iotService.publishAircraftAirTextData(flightIdentifier.aircraftName, ptm)
            }
        })
        this.aircraftToPlIdToData.clear()
    }
}