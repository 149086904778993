import {SelectedAircraftApi} from "./SelectedAircraftApi";
import {TelemetryApi} from "./TelemetryApi";
import {UnitsApi} from "./UnitsApi";
import {
  IFlightEngineForPluginApi
} from "../../../ps-infra/flight-engine/models/manager-models/IFlightEngineForPluginApi";
import {AircraftLocationApi} from "./AircraftLocationApi";
import {
  IAircraftApi,
  IAircraftLocationApi,
  IDataAnalyzeApi,
  IHandOverApi,
  ISelectedAircraftApi,
  ISystemApi,
  ITelemetryApi,
  IUnitsApi,
  IUserApi,
} from "@qandq/cloud-gcs-core";
import {AircraftApi} from "./AircraftApi";
import {UserApi} from "./UserApi";
import {HandOverApi} from "./HandOverApi";
import {IMessageLoggerApi} from "@qandq/cloud-gcs-core";
import {MessageLoggerApi} from "./MessageLoggerApi";
import {IInterPilotService} from "../../../ps-infra/services/interfaces/IInterPilotService";
import {IAirTextService} from "../../../ps-infra/services/interfaces/IAirTextService";
import {IAircraftLinkHealthService} from "../../../ps-infra/services/interfaces/IAircraftLinkHealthService";
import {IMissionApi} from "@qandq/cloud-gcs-core";
import {MissionApi} from "./MissionApi";
import {getOrganizationName} from "../../../utils/tokenHelper";
import {PluginOutputDto} from "../../../UI/model/IPlugin";
import {IUserStatusService} from "../../../ps-infra/services/interfaces/IUserStatusService";
import {UserCredentials} from "../../../ps-infra/flight-engine/models/user-models/UserCredentials";
import {IFlightLogService} from "../../../ps-infra/flight-log/models/IFlightLogService";
import {DataAnalyzeApi} from "./DataAnalyzeApi";
import {IAircraftPluginCommandService} from "../../../ps-infra/services/interfaces/IAircraftPluginCommandService";

export class SystemApi implements ISystemApi {
    aircraftLocationApi: IAircraftLocationApi;
    selectedAircraftApi: ISelectedAircraftApi;
    telemetryApi: ITelemetryApi;
    unitsApi: IUnitsApi;
    aircraftApi: IAircraftApi;
    userApi: IUserApi;
    handoverApi: IHandOverApi;
    messageLoggerApi: IMessageLoggerApi;
    missionApi: IMissionApi;
    dataAnalyzeApi: IDataAnalyzeApi;

    constructor(
        flightEngine: IFlightEngineForPluginApi,
        plugin: PluginOutputDto,
        interPilotService: IInterPilotService,
        airTextService: IAirTextService,
        aircraftLinkHealthService: IAircraftLinkHealthService,
        userStatusService: IUserStatusService,
        userCredentials: UserCredentials,
        flightLogService: IFlightLogService,
        aircraftPluginCommandService: IAircraftPluginCommandService
    ) {
        this.aircraftLocationApi = new AircraftLocationApi(flightEngine);
        this.selectedAircraftApi = new SelectedAircraftApi(
            flightEngine,
            plugin,
            airTextService
        );
        this.dataAnalyzeApi = new DataAnalyzeApi(flightEngine);
        this.telemetryApi = new TelemetryApi(flightEngine);
        this.unitsApi = new UnitsApi();
        this.aircraftApi = new AircraftApi(
            flightEngine,
            aircraftLinkHealthService,
            plugin,
            airTextService,
            aircraftPluginCommandService
        );
        this.userApi = new UserApi(flightEngine, userStatusService);
        this.handoverApi = new HandOverApi(interPilotService);
        this.messageLoggerApi = new MessageLoggerApi(
            plugin,
            flightEngine,
            flightLogService
        );
        this.missionApi = new MissionApi(flightEngine);
    }

    getOrganizationName = (): string => {
        return getOrganizationName();
    };
}
