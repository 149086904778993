import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Typography,
  Button,
  Alert,
  message,
  notification,
} from "antd";
import { UserOutlined, CopyrightOutlined } from "@ant-design/icons";

import { hideLoading, showLoading } from "../../UI/components/Loading";
import { forgetPassword } from "../../UI/services/tokenAuthServices";
import { IForgetPasswordReq } from "../../UI/model/TokenAuth";
const { Title, Text } = Typography;

const ForgotPassword = () => {
  const [form] = Form.useForm<IForgetPasswordReq>();
  const [isError, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values: IForgetPasswordReq) => {
    showLoading();

    const data: IForgetPasswordReq = {
      emailAddress: values.emailAddress,
    };
    await forgetPassword(data)
      .then(() => {
        setIsSuccess(true);
        hideLoading();
        notification.success({
          message: "Success",
          description: "Please check your email to reset your password.",
        });
        navigate("/login");
      })
      .catch(() => {
        hideLoading();
      });
  };
  return (
    <>
      <div
        className="loginPageContainer"
        onClick={() => {
          setError(null);
        }}
      >
        <div className="loginForm">
          <Form
            name="basic"
            layout="horizontal"
            labelCol={{ span: 22, offset: 2 }}
            wrapperCol={{ span: 20, offset: 2 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
            className={"loginForm"}
            colon={false}
            form={form}
          >
            <Title style={{ textAlign: "center" }} level={2}>
              Ground Control <br />
              Pilot Station
            </Title>
            {!isSuccess && (
              <>
                <Form.Item
                  label="User Email"
                  name="emailAddress"
                  rules={[
                    { required: true, message: "Please enter your email" },
                    { type: "email", message: "Please enter your email" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Type your email"
                  />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "0.5rem",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Send Email
                  </Button>
                </Form.Item>
              </>
            )}
            {isSuccess && (
              <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "0.5rem",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => setIsSuccess(false)}
                >
                  Haven`t received the mail yet? Send Again
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Link to="/login">
                <a style={{ float: "right" }}>Login</a>
              </Link>
            </Form.Item>
            {isError && (
              <Form.Item wrapperCol={{ offset: 2, span: 20 }}>
                <Alert message={isError} type="error" banner />
              </Form.Item>
            )}

            <Text
              className="copyright"
              style={{ color: "#000", textAlign: "center" }}
            >
              <CopyrightOutlined /> {new Date().getFullYear()} CloudGCS
            </Text>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
