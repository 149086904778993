import { AircraftIdentifier, FlightIdentifier } from "@qandq/cloud-gcs-core";
import { LogLevel } from "@qandq/cloud-gcs-core";
import { FlightLogType } from "@qandq/cloud-gcs-core";
import { MessageLog } from "@qandq/cloud-gcs-core";
import { UserCredentials } from "../../flight-engine/models/user-models/UserCredentials";
import { getAppEnv } from "../../../utils/environmentHelper";

export class FlightLog extends MessageLog {
  aircraftName: string;
  aircraftCertificateName: string;
  aircraftId: number;
  flightLogType: FlightLogType;
  flightId: string;
  version: string;
  topicName: string;
  isSimulator: boolean;
  userCode: string;
  tenantCode: string;
  environment: string;

  public constructor({
    flightIdentifier,
    userCredentials,
    aircraftIdentifier,
    message,
    logLevel,
    details,
    version,
    topicName,
    data,
    source,
  }: {
    flightIdentifier: FlightIdentifier;
    userCredentials: UserCredentials;
    message: string;
    logLevel: LogLevel;
    details: string;
    aircraftIdentifier?: AircraftIdentifier;
    version?: string;
    topicName?: string;
    data?: object;
    source?: string;
  }) {
    super(message, logLevel, details, data, source);
    this.aircraftName = aircraftIdentifier
      ? aircraftIdentifier.aircraftName
      : flightIdentifier?.aircraftName;
    this.aircraftCertificateName = aircraftIdentifier
      ? aircraftIdentifier.aircraftCertificateName
      : flightIdentifier?.aircraftCertificateName;
    this.aircraftId = aircraftIdentifier
      ? aircraftIdentifier.aircraftId
      : flightIdentifier?.aircraftId;
    this.flightLogType = FlightLogType.Others;
    this.flightId = flightIdentifier?.flightId;
    this.version = version ?? "";
    this.topicName = topicName ?? "";
    this.isSimulator = !!aircraftIdentifier?.isSimulator;
    this.userCode = userCredentials.userCode;
    this.tenantCode = userCredentials.tenantCode;
    // todo: why is this important check if it has use in DataConsumer or EMQX server...
    this.environment = getAppEnv();
  }
}
