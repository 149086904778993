import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../../../UI/context/authContext";

const AuthLayout = ({ children }: any) => {
  let { isAuth } = useAuth();
  let location = useLocation();

  if (!isAuth) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};

export default AuthLayout;
