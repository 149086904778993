import jwt_decode from "jwt-decode";
import { IAwsToken, IAwsTokenRes } from "../UI/model/ICognitoData";

//Burası pilot station için lazım

export const setStorageAwsData = (res: IAwsTokenRes, userData: any) => {
  const decodedToken: IAwsToken = jwt_decode(res.accessToken);
  Object.keys(res).forEach((key: any) => {
    const localStorageKey = `CognitoIdentityServiceProvider.${decodedToken.client_id}.${decodedToken.username}.${key}`;

    //@ts-ignore
    localStorage.setItem(localStorageKey, res[key]);
  });

  const localStorageKey = `CognitoIdentityServiceProvider.${decodedToken.client_id}.${decodedToken.username}.userData`;

  localStorage.setItem(localStorageKey, JSON.stringify(userData));
};
